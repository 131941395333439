import { Ellipses } from "components/icons/ellipses/ellipses";
import { PageHeader } from "components/organisms/page-header/page-header";
import { PageTemplate } from "components/templates/page-template/page-template";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { Notification } from "components/molecules/loader/loader";
import { BottomAlert } from "components/molecules/bottom-alert/bottom-alert";
import { ShoppingIngredientsList } from "components/organisms/shopping-list/shopping-ingredients-list";
import { GET_SHOPPING_INGREDIENTS_LIST, GET_SHOPPING_LIST, GET_SHOPPING_MEALS } from "graphql/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  setShoppingMeals,
  setIngredientsList,
  setExcludedMeals,
  setBoughtIngredients,
  removeFromBoughtIngredients,
  removeFromExcludedMeals,
  addToBoughtIngredients,
} from "reducers/shoppingListSlice";
import {
  DELETE_SHOPPING_LIST,
  DELETE_SHOPPING_MEALS,
  UPDATE_SHOPPING_LIST,
  UPDATE_SHOPPING_MEALS,
} from "graphql/mutation";
import { setLoader } from "reducers/uiSlice";
import { ShoppingListScroll } from "components/organisms/shopping-list/shopping-list-scroll";
import Loader from "react-loader-spinner";
import { Alert } from "components/organisms/alert/alert";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { getDateOfISOWeek } from "utils/getRangeOfWeeks";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { TextBelowTitle } from "components/atoms/text-below-title/text-below-title";
import { URL_REF } from "utils/constants";
import { removeUrl } from "reducers/userSlice";
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export const ShoppingListIngredients: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [_meals, _setPlannedMeals] = useState<any>([]);
  const [updateShoppingList] = useMutation(UPDATE_SHOPPING_LIST);
  const [loading, setLoading] = useState(false);
  const loader = useAppSelector((state) => state.ui.loader);
  const [isDataLoading, setDataLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [householdID, setHouseholdID] = useState<number>();
  const [allMealIDs, setAllMealIDs] = useState<number[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [mealsIDs, setMealsIDs] = useState<number[]>([]);
  const [message, setMessage] = useState("");
  const [bottomAlert, setBottomAlert] = useState(false);
  const [dishesLength, setDishesLength] = useState<number>(0);
  const [itemsLength, setItemsLength] = useState<number>(0);
  const plannedMealCollection = useAppSelector((state) => state.shoppingList.shoppingMeals);
  const ingredientsList = useAppSelector((state) => state.shoppingList.ingredientsList);

  const [getGeneratedShoppingMeals] = useLazyQuery(GET_SHOPPING_MEALS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.shopping_meals?.length > 0) {
        dispatch(setIngredientsList([]));
        dispatch(setBoughtIngredients([]));
        dispatch(setShoppingMeals([]));
        dispatch(setExcludedMeals([]));
        householdID &&
          endDate &&
          startDate &&
          generateList({
            variables: {
              household_id: householdID,
              endDate: endDate,
              startDate: startDate,
            },
          });
      } else {
        history.push(`/shopping-list/generate`);
      }
    },
  });

  useEffect(() => {
    let date = dayjs(new Date()).format("YYYY-MM-DD");
    let safari_date = date.split("-").join("/");
    const week = dayjs(safari_date).isoWeek();
    const range = getDateOfISOWeek(week, dayjs(safari_date).get("year"));
    const startDate = dayjs(range);
    const endDate = dayjs(startDate).add(6, "days");
    setStartDate(startDate.format("YYYY-MM-DD"));
    setEndDate(endDate.format("YYYY-MM-DD"));
    console.log("startDate", startDate.format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    setDataLoading(true);
    const params = new URLSearchParams(location.search);
    const generate = params.get("generate");
    //Check whether shopping list is generated before or not
    if (generate == null) {
      getGeneratedShoppingMeals({
        variables: {
          where: {
            _and: [
              { created_at: { _gte: startDate } },
              { created_at: { _lte: endDate } },
              { household_id: { _eq: householdID } },
            ],
          },
        },
      });
    } else {
      householdID &&
        endDate &&
        startDate &&
        generateList({
          variables: {
            household_id: householdID,
            endDate: endDate,
            startDate: startDate,
          },
        });
    }
  }, [householdID, startDate, endDate]);

  useEffect(() => {
    console.log("loading", isDataLoading, listLoading);
  }, [isDataLoading, listLoading]);

  const [getShoppingMealIngredients, { error }] = useLazyQuery(GET_SHOPPING_INGREDIENTS_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setItemsLength(data?.getShoppingIngredientsList?.length);
      if (data?.getShoppingIngredientsList?.length > 0) {
        dispatch(setIngredientsList(data?.getShoppingIngredientsList));
        data?.getShoppingIngredientsList?.map((item: any) => {
          if (item?.bought == true) {
            console.log("here in true");
            dispatch(addToBoughtIngredients(item?.id));
          }
        });
      }
      setDataLoading(false);
      setListLoading(false);
    },
  });

  const [updateShppingMeals] = useMutation(UPDATE_SHOPPING_MEALS);
  const [deleteShoppingList] = useMutation(DELETE_SHOPPING_LIST);
  const [deleteShoppingMeals] = useMutation(DELETE_SHOPPING_MEALS);
  const [generateList] = useLazyQuery(GET_SHOPPING_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let arr = data?.ShoppingList?.respones;
      getShoppingMeals({
        variables: {
          where: {
            household_id: { _eq: householdID },
            meal_id: { _in: arr },
            created_at: { _gte: startDate, _lte: endDate },
          },
        },
      });
    },
  });

  const [getShoppingMeals] = useLazyQuery(GET_SHOPPING_MEALS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.shopping_meals?.length > 0) {
        let id_array: any = [];
        let meals_array: any = [];
        data?.shopping_meals?.map((meal: any) => {
          meals_array.push(meal?.id);
          if (meal?.excluded == true) {
            dispatch(setExcludedMeals(meal?.id));
          } else {
            id_array.push(meal?.id);
          }
        });
        setAllMealIDs(meals_array);
        setMealsIDs(id_array);
        _setPlannedMeals(data?.shopping_meals);
        dispatch(setShoppingMeals(data?.shopping_meals));
      } else {
        setDataLoading(false);
        setAlert(true);
      }
    },
  });

  useEffect(() => {
    let _householdId: string | null = "";
    if (location.search) {
      const params = new URLSearchParams(location.search);
      _householdId = params.get("household_id");
    }
    if (loader?.status && !listLoading) {
      setLoading(true);
      setTimeout(
        () => {
          dispatch(setLoader({ status: "", message: "" }));
        },
        _householdId ? 6000 : 2000,
      );
    } else {
      setLoading(false);
    }
  }, [loader, listLoading]);

  useEffect(() => {
    let _householdId: string | null = "";
    if (location.search) {
      const params = new URLSearchParams(location.search);
      _householdId = params.get("household_id");
    }
    let token: any = localStorage.getItem("household_id");
    console.log({ _householdId, token });
    if (_householdId && token) {
      if (_householdId === token) {
        console.log("equal");
      } else {
        console.log("not equal");
        dispatch(setLoader({ status: "error", message: "You are not part of the household!" }));
        setTimeout(() => {
          _householdId && history.push(`/plan-dinner?date=${dayjs(new Date()).format("YYYY-MM-DD")}`);
          dispatch(removeUrl())
        }, 6500);
      }
    }
    setTimeout(() => {
      setHouseholdID(parseInt(token));
    }, 100);
  }, []);

  useEffect(() => {
    setDishesLength(mealsIDs?.length);
    if (mealsIDs?.length > 0) {
      getShoppingMealIngredients({
        variables: {
          ids: mealsIDs,
        },
      });
    } else {
      setDataLoading(false);
      setListLoading(false);
      dispatch(setIngredientsList([]));
    }
  }, [mealsIDs]);

  useEffect(() => {
    error && setDataLoading(false);
    error && setAlert(true);
    error && setMessage(`Unable to fetch shopping list. Try later!`);
  }, [error]);

  useEffect(() => {
    _setPlannedMeals(plannedMealCollection);
  }, [plannedMealCollection]);

  const closeDialog = () => setBottomAlert(false);
  const clearList = () => {
    setDishesLength(0);
    setItemsLength(0);
    setDataLoading(true);
    deleteShoppingList({
      variables: {
        where: {
          shoppingmeal_id: { _in: allMealIDs },
        },
      },
    })
      .then(() => {
        deleteShoppingMeals({
          variables: {
            where: {
              id: { _in: allMealIDs },
            },
          },
        });
      })
      .then(() => {
        setDataLoading(false);
        dispatch(setIngredientsList([]));
        dispatch(setBoughtIngredients([]));
        dispatch(setShoppingMeals([]));
        dispatch(setExcludedMeals([]));
        history.push(`/plan-dinner?date=${dayjs(new Date()).format("YYYY-MM-DD")}`);
      });
  };

  const excludeShoppingMeals = (id: number) => {
    dispatch(setExcludedMeals(id));
    setListLoading(true);
    updateShppingMeals({
      variables: {
        id: id,
        set: {
          excluded: true,
        },
      },
    })
      .then((res) => {
        res?.data?.update_shopping_meals?.affected_rows == 1 &&
          setMealsIDs(mealsIDs.filter((item: any) => item !== id));
        dispatch(setIngredientsList([]));
      })
      .catch((e) => {
        console.log(e);
        dispatch(setLoader({ status: "error", message: "Something went wrong!" }));
      });
  };

  const addToShoppingMeals = (id: number) => {
    dispatch(removeFromExcludedMeals(id));
    setListLoading(true);
    updateShppingMeals({
      variables: {
        id: id,
        set: {
          excluded: false,
        },
      },
    })
      .then((res) => {
        res?.data?.update_shopping_meals?.affected_rows == 1 && setMealsIDs((mealsIDs) => [...mealsIDs, id]);
        dispatch(setIngredientsList([]));
      })
      .catch((e) => {
        console.log(e);
        dispatch(setLoader({ status: "error", message: "Something went wrong!" }));
      });
  };
  const _addToBoughtIngredients = (name: string, id: number) => {
    dispatch(addToBoughtIngredients(id));
    updateShoppingList({
      variables: {
        where: {
          name: { _eq: name },
          shoppingmeal_id: {
            _in: mealsIDs,
          },
        },
        set: {
          bought: true,
        },
      },
    })
      .then(() => {
        setListLoading(true);
        getShoppingMealIngredients({
          variables: {
            ids: mealsIDs,
          },
        });
      })
      .catch((e) => {
        console.log(e);
        dispatch(setLoader({ status: "error", message: "Something went wrong!" }));
      });
  };

  const _removeFromBoughtIngredients = (name: string, id: number) => {
    dispatch(removeFromBoughtIngredients(id));
    updateShoppingList({
      variables: {
        where: {
          name: { _eq: name },
          shoppingmeal_id: {
            _in: mealsIDs,
          },
        },
        set: {
          bought: false,
        },
      },
    })
      .then(() => {
        setListLoading(true);
        getShoppingMealIngredients({
          variables: {
            ids: mealsIDs,
          },
        });
      })
      .catch((e) => {
        console.log(e);
        dispatch(setLoader({ status: "error", message: "Something went wrong!" }));
      });
  };

  const handleShare = async () => {
    try {
      if (navigator.share && householdID) {
        await navigator.share({
          title: "Menully",
          text: "Menully | Shopping List",
          url: `shopping-list?date=${dayjs(new Date()).format("YYYY-MM-DD")}&ref=${URL_REF.ShoppingList}&household_id=${
            householdID && householdID
          }`,
          // url: `plan-dinner?date=${selectedWeekRange.date}&ref=shared&household_id=74`,
        });
      }
      closeDialog();
    } catch (error) {
      console.log(error);
      setLoader({ status: "error", message: "Unable to share!" });
    }
  };

  return (
    <div>
      <PageTemplate>
        <PageHeader pageHeading="Shopping List">
          <div onClick={() => setBottomAlert(true)}>
            <Ellipses />
          </div>
        </PageHeader>
        <TextBelowTitle text={`${dishesLength} Dishes | ${itemsLength} Items`} />
        {isDataLoading ? (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        ) : (
          <>
            {loading && <Notification status={loader.status} message={loader.message} />}

            {_meals?.length > 0 && (
              <ShoppingListScroll
                removeDishFromCollection={excludeShoppingMeals}
                addToShoppingMeals={addToShoppingMeals}
                dishes={_meals}
              />
            )}
            {_meals?.length > 0 && (
              <p className="text-primary font-semibold mt-6 text-[16px] leading-[18px]">Ingredients</p>
            )}
            {listLoading ? (
              <div className="flex justify-center fixed top-[50%] left-[50%] ">
                <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
              </div>
            ) : (
              <>
                {_meals?.length > 0 && ingredientsList?.length > 0 ? (
                  <div>
                    <ShoppingIngredientsList
                      addToBoughtIngredients={_addToBoughtIngredients}
                      removeFromBoughtIngredients={_removeFromBoughtIngredients}
                    />
                  </div>
                ) : (
                  <>{_meals?.length > 0 && <p className="text-primary font-light text-[12px] mt-[16px]">0 items</p>}</>
                )}
              </>
            )}
            {/* {allMealIDs?.length > 0 && (
              <div onClick={() => generateShoppingList()}>
                <RefetchIngredients />
              </div>
            )} */}
            {alert && (
              <Alert
                alertStatus="error"
                message={`You don't have any planned meals for this week.`}
                buttonText="Go Back"
                setAlert={setAlert}
                redirect={`/plan-dinner?date=${dayjs(new Date()).format("YYYY-MM-DD")}`}
              />
            )}
            {bottomAlert && <BottomAlert closeDialog={closeDialog} clearList={clearList} handleShare={handleShare} />}
          </>
        )}
      </PageTemplate>
    </div>
  );
};
