import React from "react";
import Loader from "react-loader-spinner";
import { TagCategory, Type_StaticFilters } from "typescript/types";
import { Button } from "../../atoms/button/button";
import { Divider } from "../../atoms/divider/divider";
import { FilterHeading } from "../../atoms/filter-heading/filter-heading";
import { SingleTag } from "../../molecules/single-filter/single-filter";

type Props = {
  onClickSingleTag: (filterName: string) => void;
  onClickApplyFilter: () => void;
  tags: TagCategory[];
  selectedTags: string[];
  selectedStaticFilters: string[];
  handleOnClickStaticFilter: (filter: string) => void;
  StaticFilters: Type_StaticFilters;
  isOnAddDishes?: boolean;
};

export const Filters: React.FC<Props> = ({
  tags,
  onClickSingleTag,
  selectedTags,
  onClickApplyFilter,
  selectedStaticFilters,
  handleOnClickStaticFilter,
  StaticFilters,
  isOnAddDishes = false,
}) => {
  return (
    <div className="mt-[40px]">
      {tags?.length > 0 ? (
        <div>
          {!isOnAddDishes && (
            <>
              <div className="">
                {StaticFilters.map((filter) => {
                  return (
                    <div onClick={() => handleOnClickStaticFilter(filter.label)}>
                      <SingleTag
                        tagName={filter.label}
                        key={filter.id}
                        isChecked={selectedStaticFilters.includes(filter.label) ? true : false}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="my-[31px]">
                <Divider />
              </div>
            </>
          )}
          {tags.map((tagObject) => {
            return (
              <div key={tagObject.category}>
                <FilterHeading heading={tagObject.category} />
                {tagObject.tags.map((tag, index) => (
                  <div onClick={() => onClickSingleTag(tag)}>
                    <SingleTag
                      key={`${tag}-${index}`}
                      tagName={tag}
                      isChecked={selectedTags.includes(tag) ? true : false}
                    />
                  </div>
                ))}
                <div className="py-[31px]">
                  <Divider />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center fixed top-[50%] left-[50%] ">
          <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
        </div>
      )}
      {(selectedTags.length > 0 || selectedStaticFilters.length > 0) && (
        <Button
          label={`Apply ${selectedTags.length + selectedStaticFilters.length} Filters`}
          className="h-[46px] bottom-[85px] left-[50%] ml-[-79px]"
          onClick={onClickApplyFilter}
        />
      )}
    </div>
  );
};
