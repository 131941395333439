import { gql } from "@apollo/client";

export const INSERT_CUISINE_FAVOURITE = gql`
  mutation insert_cuisine_favourite($objects: [cuisine_favourite_insert_input!]!) {
    insert_cuisine_favourite(objects: $objects) {
      affected_rows
    }
  }
`;

export const INSERT_EMPLOYER_ALLERGIES = gql`
  mutation insert_employer_allergies($objects: [employer_allergies_insert_input!]!) {
    insert_employer_allergies(objects: $objects) {
      affected_rows
    }
  }
`;

export const INSERT_SERVING_SIZE = gql`
  mutation update_employers($_set: employers_set_input, $where: employers_bool_exp!) {
    update_employers(_set: $_set, where: $where) {
      affected_rows
    }
  }
`;

export const ADD_FAVOURITE_DISH_BY_EMPLOYER_ID = gql`
  mutation insert_employer_favourite($objects: [employer_favourite_insert_input!]!) {
    insert_employer_favourite(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

// export const REMOVE_FAVOURITE_DISH_BY_EMPLOYER_ID = gql`
//   mutation delete_employer_favourite($where: employer_favourite_bool_exp!) {
//     delete_employer_favourite(where: $where) {
//       affected_rows
//     }
//   }
// `;

export const INSERT_CUSTOM_MEAL = gql`
  mutation insert_meal($objects: [meal_insert_input!]!) {
    insert_meal(objects: $objects) {
      affected_rows
    }
  }
`;
export const SIGN_UP = gql`
  mutation signupEmployer(
    $email: String!
    $name: String!
    $household_id: Int!
    $password: String!
    $user_type: String!
  ) {
    signupEmployer(
      email: $email
      name: $name
      household_id: $household_id
      password: $password
      user_type: $user_type
    ) {
      affected_rows
    }
  }
`;

export const SIGN_IN = gql`
  mutation customLogin($object: customLoginInput!) {
    customLogin(object: $object) {
      token
    }
  }
`;

export const INSERT_HOUSEHOLD = gql`
  mutation insert_household($uuid: String) {
    insert_household(objects: { uuid: $uuid }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_HOUSEHOLD_EMPLOYER_HELPER = gql`
  mutation insert_household_employer_helper($objects: [household_employer_helper_insert_input!]!) {
    insert_household_employer_helper(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_EMPLOYER = gql`
  mutation updateEmployers($id: Int, $set: employers_set_input) {
    update_employers(where: { id: { _eq: $id } }, _set: $set) {
      affected_rows
    }
  }
`;

export const SIGNUP = gql`
  mutation signupEmployer($email: String!, $name: String!, $password: String!) {
    signupEmployer(email: $email, name: $name, password: $password) {
      affected_rows
    }
  }
`;
export const UPDATE_CUSTOM_MEAL = gql`
  mutation updateMeal($id: Int, $set: meal_set_input) {
    update_meal(where: { id: { _eq: $id } }, _set: $set) {
      affected_rows
    }
  }
`;

export const INSERT_HELPER_CAN_MAKE = gql`
  mutation insertHelperCanMake($objects: [helper_can_make_insert_input!]!) {
    insert_helper_can_make(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_PLANNED_MEAL = gql`
  mutation deletePlannedMeal($where: planned_meals_bool_exp!) {
    delete_planned_meals(where: $where) {
      affected_rows
      returning {
        id
        meal_id
        date
      }
    }
  }
`;
export const UPDATE_PLANNED_MEALS = gql`
  mutation update_planned_meals($_set: planned_meals_set_input, $where: planned_meals_bool_exp!) {
    update_planned_meals(_set: $_set, where: $where) {
      affected_rows
      returning {
        id
        meal_id
        date
      }
    }
  }
`;

export const INSERT_PLANNED_MEALS = gql`
  mutation insert_planned_meals($objects: [planned_meals_insert_input!]!) {
    insert_planned_meals(objects: $objects) {
      affected_rows
      returning {
        id
        meal_id
        date
      }
    }
  }
`;

export const INSERT_INSTRUCTION = gql`
  mutation insert_instructions($objects: [instructions_insert_input!]!) {
    insert_instructions(objects: $objects) {
      affected_rows
    }
  }
`;

export const INSERT_MEAL_FILTERS = gql`
  mutation insert_meal_filters($objects: [meal_filters_insert_input!]!) {
    insert_meal_filters(objects: $objects) {
      affected_rows
    }
  }
`;

export const DETE_INSTRUCTIONS = gql`
  mutation delete_instructions($where: instructions_bool_exp!) {
    delete_instructions(where: $where) {
      affected_rows
    }
  }
`;

export const DELETE_MEAL_FILTERS = gql`
  mutation delete_meal_filters($where: meal_filters_bool_exp!) {
    delete_meal_filters(where: $where) {
      affected_rows
    }
  }
`;

export const UPDATE_SHOPPING_MEALS = gql`
  mutation update_shopping_meals($id: Int, $set: shopping_meals_set_input) {
    update_shopping_meals(where: { id: { _eq: $id } }, _set: $set) {
      affected_rows
    }
  }
`;

export const UPDATE_SHOPPING_LIST = gql`
  mutation update_shopping_list($where: shopping_list_bool_exp! $set: shopping_list_set_input) {
    update_shopping_list(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const DELETE_EMPLOYER_FAVOURITE = gql`
  mutation delete_employer_favourite($where: employer_favourite_bool_exp!) {
    delete_employer_favourite(where: $where) {
      affected_rows
    }
  }
`;

export const DELETE_HELPER_CAN_MAKE = gql`
  mutation delete_helper_can_make($where: helper_can_make_bool_exp!) {
    delete_helper_can_make(where: $where) {
      affected_rows
    }
  }
`;

export const DELETE_SHOPPING_LIST = gql`
  mutation delete_shopping_list($where: shopping_list_bool_exp!) {
    delete_shopping_list(where: $where) {
      affected_rows
    }
  }
`;

export const DELETE_SHOPPING_MEALS = gql`
  mutation delete_shopping_meals($where: shopping_meals_bool_exp!) {
    delete_shopping_meals(where: $where) {
      affected_rows
    }
  }
`;

export const INSERT_LIKED_MEAL = gql`
  mutation insert_liked_meal($objects: [liked_meal_insert_input!]!) {
    insert_liked_meal(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_HELPER = gql `
  mutation update_helpers($id: Int, $set: helpers_set_input) {
    update_helpers(where: { id : { _eq: $id }}, _set: $set) {
      affected_rows
    }
  }`

export const UPDATE_PASSWORD = gql `
  mutation updatePassword($email: String!, $password: String!, $user_type: String!) {
    updatePassword(email: $email, password: $password, user_type: $user_type) {
      result
    }
  }`
