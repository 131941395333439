export enum Enum_Diet_Types {
  diets = "Diets",
}

export enum Enum_Unit {
  US = "us",
  Metric = "metric",
}

export enum Enum_FavouriteControl {
  Add = "add",
  Remove = "remove",
}

export enum Enum_EmailVerification {
  NotVerified = "Not_verified",
}

export enum Enum_Navigation {
  MealIdeas = "meal",
  PlanDinner = "planner",
  ShoppingList = "shopping",
  Profile = "profile",
}

export enum Enum_Paths {
  MealIdeas = "meal-ideas",
  PlanDinner = "plan-dinner",
  ShoppingList = "shopping-list",
  Profile = "profile",
  AddDishes = "add-dishes",
}
