import { useMutation } from "@apollo/client";
import { Button } from "components/atoms/button/button";
import { Input } from "components/atoms/input/input";
import {
  INSERT_HOUSEHOLD,
  INSERT_HOUSEHOLD_EMPLOYER_HELPER,
  SIGN_UP,
} from "graphql/mutation";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setLoader } from "reducers/uiSlice";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { Capitalize } from "utils/capitalize";
import { EmailRegex, nameRegex } from "utils/constants";
// import { uniqueNamesGenerator, Config, adjectives } from 'unique-names-generator';
import Jabber from 'jabber';

export const CreateAccountForm: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    email: "",
    password: "",
  });

  const jabber = new Jabber();

  const characterName: string = jabber.createWord(8); //"eg: cubaci"

  const [signUp] = useMutation(SIGN_UP);
  const location = useLocation();
  const [insertHouseholebyEmp] = useMutation(INSERT_HOUSEHOLD_EMPLOYER_HELPER);
  const [insertHousehold] = useMutation(INSERT_HOUSEHOLD);
  const handleFormValues: (event: React.ChangeEvent<HTMLInputElement>) => void =
    (e) => setFormValues({ ...formValues, [e.target.name]: e.target.value });
  const [userType, setUserType] = useState("EMPLOYER");
  const [byLink, setByLink] = useState(false);
  const [addedBy, setAddedBy] = useState<number>()
  const [householdID, setHouseholdID] = useState<number>()
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");
      if (token) {
        setByLink(true);
        var decodedToken: any = jwt_decode(token);
        token && setFormValues({...formValues, email: decodedToken?.email });
        token && setUserType(decodedToken?.user_type);
        token && setHouseholdID(decodedToken?.household)
        token && localStorage.setItem("household_id", decodedToken?.household);
        token && setAddedBy(decodedToken?.user_id)
      }
    }
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem("user_type", userType);
  }, [userType]);

  const catchHandle = (e: any) => {
    if (e.graphQLErrors) {
      // reduce to get message
      const data: any = _.reduce(
        e.graphQLErrors,
        (res: any, err) => [...res, err.message],
        []
      );
    setLoading(false);
      dispatch(setLoader({status: 'error', message: Capitalize(data[0]?.split(':').pop()) ?? 'Oops! Something went wrong.'}));
    }
  }

  const onSignup = () => {
    setLoading(true);
    const { email, firstName, password } = formValues;
    const emailReg = EmailRegex;

    if (firstName.length < 3) {
      dispatch(
        setLoader({
          status: "error",
          message: "Provide atleast 3 characters for name.",
        })
      );
      setLoading(false);
      return;
    }

    if (email === "" || firstName === "" || password === "") {
      dispatch(setLoader({ status: "error", message: "Incorrect fields!" }));
      setLoading(false);
      return;
    }
    if (!nameRegex.test(firstName)) {
      dispatch(setLoader({ status: "error", message: "Invalid Name!" }));
      setLoading(false);
      return;
    }

    if (!emailReg.test(email)) {
      dispatch(setLoader({ status: "error", message: "Incorrect email!" }));
      setLoading(false);
      return;
    }

    if (password.length < 8) {

      dispatch(
        setLoader({
          status: "error",
          message: "Password is too short!",
        })
      );
      setLoading(false);
      return;
    }

    if (byLink) {
      setTimeout(() => {
        signUp({
          variables: {
            email: formValues.email,
            household_id: householdID,
            name: formValues.firstName,
            password: formValues.password,
            user_type: userType,
          },
        })
          .then((res) => {
            let token = res?.data?.signupEmployer?.affected_rows?.token;
            localStorage.setItem("token", token);
            localStorage.setItem(
              "user_Id",
              res?.data?.signupEmployer?.affected_rows?.insert_helpers
                ?.returning[0]?.id
            );
            insertHouseholebyEmp({
              variables: {
                objects: {
                  user_id:
                    res?.data?.signupEmployer?.affected_rows?.insert_helpers
                      ?.returning[0]?.id,
                  user_type: userType,
                  household_id: householdID,
                  added_by: addedBy,
                },
              },
            })
              .then(() => {
                dispatch(
                  setLoader({ status: "success", message: "Verify you email!" })
                );
                history.push("/meal-ideas");
                setLoading(false);
              })
              .catch((e) => {
                // console.log(e);
                setLoading(false);
                catchHandle(e)
                // dispatch(
                //   setLoader({ status: "error", message: "Incorrect fields." })
                // );
              });
          })

          .catch((e) => {
            // console.log(e);
            setLoading(false);
            catchHandle(e)
          });
      }, 500);
    } else {
      insertHousehold({
        variables: {
          uuid: characterName + "family",
        },
      })
        .then((res) => {
          let household_id = res?.data?.insert_household?.returning[0]?.id;
          localStorage.setItem("household_id", household_id);
          signUp({
            variables: {
              email: formValues.email,
              household_id: res?.data?.insert_household?.returning[0]?.id,
              name: formValues.firstName,
              password: formValues.password,
              user_type: userType,
            },
          })
            .then((res) => {
              let token = res?.data?.signupEmployer?.affected_rows?.token;
              localStorage.setItem("token", token);
              localStorage.setItem(
                "user_Id",
                res?.data?.signupEmployer?.affected_rows?.insert_employers
                  ?.returning[0]?.id
              );
              insertHouseholebyEmp({
                variables: {
                  objects: {
                    user_id:
                      res?.data?.signupEmployer?.affected_rows?.insert_employers
                        ?.returning[0]?.id,
                    user_type: userType,
                    household_id: household_id,
                  },
                },
              })
                .then(() => {
                  dispatch(
                    setLoader({ status: "success", message: "Verify you email!" })
                  );
                  history.push("/diet");
                  setLoading(false);
                })
                .catch((e) => {
                  // console.log(e);
                  setLoading(false);
                  catchHandle(e)
                });
            })

            .catch((e) => {
              setLoading(false);
              catchHandle(e)
            });
        })
        .catch((e) => {
          setLoading(false);
          catchHandle(e)
        });
    }
  };

  return (
    <form className="mt-[35px]">
      <Input
        label="Your First Name"
        onChangeValue={handleFormValues}
        value={formValues.firstName}
        name="firstName"
        labelIsWhite
        autoComplete="off"
      />
      <Input
        label="Your Email Address"
        onChangeValue={handleFormValues}
        value={formValues.email}
        name="email"
        labelIsWhite
        autoComplete="off"
      />
      <Input
        label="Password"
        onChangeValue={handleFormValues}
        value={formValues.password}
        name="password"
        type="password"
        labelIsWhite
        autoComplete="off"
      />
      <div className={`${loading && "pointer-events-none"}`}>
        <Button
          loading={loading}
          onClick={() => {
            onSignup();
          }}
          label="Continue"
          hasPurpleGradient
        />
      </div>
    </form>
  );
};
