import { PlanDinnerDay } from "components/molecules/plan-dinner-day/plan-dinner-day";
import React, { useState } from "react";
import { useAppSelector } from "store";
import { Type_WeekRange } from "typescript/types";
import { ROWS } from "utils/constants";

type Props = {
  removeDishFromCollection: (id: number, isDayOfWeek: boolean) => void;
  onDragging: boolean;
  dayActiveStatus: boolean[];
  handleToggle: (id: number) => void;
  disableDrag: boolean;
  itemIsBeingDragged: (_isDragging: boolean) => void
};

export const PlanDinnerWeek: React.FC<Props> = ({
  removeDishFromCollection,
  onDragging,
  dayActiveStatus,
  handleToggle,
  disableDrag,
  itemIsBeingDragged
}) => {
  const [daysOpened, setDaysOpened] = useState<string[]>([]);
  const DragDropCollection = useAppSelector((state) => state.planDinner.DragDropCollection);
  const handleOnClickDayName = (id: string) => {
    // if the id does not exist add it
    // i.e closed, open day
    if (!daysOpened.includes(id)) setDaysOpened([...daysOpened, id]);
    // else remove the id
    // i.e close day
    else {
      const filteredDaysOpened = daysOpened.filter((dayOpened) => dayOpened !== id);
      setDaysOpened(filteredDaysOpened);
    }
  };

  return (
    <div className="flex flex-col divide-y-2 w-full">
      {DragDropCollection.rows.map((row: any, index) => {
        if (index === 0) return <> </>;
        let _dish: any[] = [];
        row.taskIds.map((id: any) => {
          DragDropCollection.dishes.find((dish) => {
            if (dish.id == id) {
              _dish.push(dish);
            }
          });
        });
        return (
          <div
            onMouseEnter={() => {
              onDragging && handleOnClickDayName(row.id);
            }}
          >
            <PlanDinnerDay
              row={row}
              handleOnClickDayName={handleOnClickDayName}
              isOpened={daysOpened.includes(row.id)}
              dishes={_dish}
              key={row.id}
              isDayOfWeek
              dayActiveStatus={dayActiveStatus}
              {...{ removeDishFromCollection, handleToggle }}
              disableDrag={disableDrag}
              itemIsBeingDragged={itemIsBeingDragged}
            />
          </div>
        );
      })}
    </div>
  );
};
