import { AddDishesCategoryHeading } from "components/atoms/add-dishes-category-heading/add-dishes-category-heading";
import { AddDishesCategoryDish } from "components/molecules/add-dishes-category-dish/add-dishes-category-dish";
import useDraggable from "hooks/useDraggableScroll";
import React from "react";
import { Type_AddDish_Dish } from "typescript/types";
import { shuffleArray } from "utils/shuffleArray";

type AddDishCategoryScrollProps = {
  data: Type_AddDish_Dish[];
  heading: string;
  handleOnAddDish: (dishToAdd: Type_AddDish_Dish) => void;
  handleRemoveDish: (id: number) => void;
  dishCollection: Type_AddDish_Dish[];
  handleDishClick: (mealId: number) => void;
};

export const AddDishCategoryScroll: React.FC<AddDishCategoryScrollProps> = ({
  data,
  heading,
  handleOnAddDish,
  handleRemoveDish,
  dishCollection,
  handleDishClick,
}) => {
  const ref = useDraggable();
  
  return (
    <div className="mt-[35px]">
      <AddDishesCategoryHeading heading={heading} />
      <div ref={ref} className="scrollbar-hidden flex space-x-[16px] overflow-x-auto h-[135px]">
        {data.map((item) => (
          <AddDishesCategoryDish
            key={item?.id}
            name={item?.name}
            id={item?.id}
            image_url={item?.image_url}
            custom_meal={item?.custom_meal}
            handleDishClick={handleDishClick}
            addedToCollection={dishCollection.some((dish) => {
              const _dish = typeof dish.id === "string" ? (dish.id as string).split("-")[1] : dish.id;
              if (_dish == item.id) return true;
              return false;
            })}
            {...{ handleOnAddDish, handleRemoveDish }}
          />
        ))}
      </div>
    </div>
  );
};
