import React from "react";
import EmployerFavouritePNG from "assets/icons/employer-favourite.png";

export const EmployerFavourite: React.FC = () => {
  return (
    <div className="flex space-x-[9px]">
       <img src={EmployerFavouritePNG} alt="" className="w-[22px] h-[22px] cursor-pointer" />
      <p className="text-[11px] leading-[20px] font-medium text-fontGray">Employer Favourite</p>
    </div>
  );
};
