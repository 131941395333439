import React from 'react'
import { useHistory } from 'react-router-dom'
import BackArrowPNG from "../../../assets/icons/arrow-right-circle.png"
export const BackArrow: React.FC = () => {
  const history = useHistory()
  return (
    <div onClick={()=>history.goBack()}>
      <img src={BackArrowPNG} alt="" className="w-[20px] h-[20px] cursor-pointer"/>
    </div>
  )
}
