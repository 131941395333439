import React from 'react'
import { CloseButton } from '../../icons/close-button/close-button'
import { DishDetailHeading } from '../../atoms/dish-detail-heading/dish-detail-heading'

export const DishDetailHeader: React.FC<{heading: string, onClickClose: () => void}> = ({heading="", onClickClose}) => {
  return (
    <div className="flex justify-between items-center">
      <DishDetailHeading heading={heading} />
      <div onClick={onClickClose} className="cursor-pointer p-[7px]">
        <CloseButton width="w-[14px]"/>
      </div>
    </div>
  )
}
