import React from "react";
import { Enum_Unit } from "../../../typescript/enum";

type RecipeUnitSelectorProps = {
  unitSelected: Enum_Unit;
  handleUnitChange: (unit: Enum_Unit) => void;

};

export const RecipeUnitSelector: React.FC<RecipeUnitSelectorProps> = ({
  unitSelected = Enum_Unit.US,
  handleUnitChange,
}) => {
  return (
    <div className="flex divide-x divide-primary text-[12px] leading-[12px] text-primary">
      <p
        className={`cursor-pointer pr-1.5 ${unitSelected === Enum_Unit.US ? "font-semibold" : "font-normal"}`}
        onClick={() => handleUnitChange(Enum_Unit.US)}
      >
        US
      </p>
      <p
        className={`cursor-pointer pl-1.5 ${unitSelected === Enum_Unit.Metric ? "font-semibold" : "font-normal"}`}
        onClick={() => handleUnitChange(Enum_Unit.Metric)}
      >
        Metric
      </p>
    </div>
  );
};
