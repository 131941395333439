import { SideArrow } from "components/icons/side-arrow/side-arrow";
import { PreferencesOptionName } from "components/organisms/preferences/preferences-option";
import React from "react";
import { useHistory } from "react-router-dom";

export const PreferencesList: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <div className="flex flex-col divide-y-2">
        <div className="py-[26px]">
          <div className="flex justify-between items-center">
            <div
              className="flex w-full justify-between"
              onClick={() => history.push('/helpers')}
            >
              <PreferencesOptionName option={"Your Helpers"} />
              <SideArrow redirect={"/helpers"} />
            </div>
          </div>
        </div>
        <div className="py-[26px]">
          <div className="flex justify-between items-center">
            <div
              className="flex w-full justify-between"
              onClick={() => history.push('/my-diet')}
            >
              <PreferencesOptionName option={"Diet"} />
              <SideArrow redirect={"/my-diet"} />
            </div>
          </div>
        </div>
        <div className="py-[26px]">
          <div className="flex justify-between items-center">
            <div
              className="flex w-full justify-between"
              onClick={() => history.push('/my-allergies')}
            >
              <PreferencesOptionName option={"Allergies"} />
              <SideArrow redirect={"/my-allergies"} />
            </div>
          </div>
        </div>
        <div className="py-[26px]">
          <div className="flex justify-between items-center">
            <div
              className="flex w-full justify-between"
              onClick={() =>history.push('/my-servings-per-meal')}
            >
              <PreferencesOptionName option={"Meal Servings"} />
              <SideArrow redirect={"/my-servings-per-meal"} />
            </div>
          </div>
        </div>
        {/* <div className="py-[26px]">
          <div className="flex justify-between items-center">
            <div
              className="flex w-full justify-between"
              onClick={() => history.push('/meal-Ideas')}
            >
              <PreferencesOptionName option={"Favourite Cuisines"} />
              <SideArrow redirect={"/meal-Ideas"} />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
