import React from "react";
import { MinusIcon } from "../../icons/minus-icon/minus-icon";
import { PlusIcon } from "../../icons/plus-icon/plus-icon";

type ServeSelectorProps = {
  numberOfServes: number;
  handleAddServe: () => void;
  handleRemoveServe: () => void;
};

export const ServeSelector: React.FC<ServeSelectorProps> = ({ numberOfServes, handleAddServe, handleRemoveServe }) => {
  return (
    <div className="flex justify-between w-[110px] items-center">
      <div onClick={() => handleRemoveServe()}>
        <MinusIcon  />
      </div>
      <p className="text-[12px] leading-[20px] font-semibold text-primary">Serves {numberOfServes}</p>
      <div onClick={()=>handleAddServe()}>
        <PlusIcon />
      </div>
    </div>
  );
};
