import React from "react";
import { Type_DietryTypes } from "../../../typescript/types";
import { SelectListItem } from "../../molecules/select-list-item/select-list-item";

export const SelectListItems: React.FC<{
  list?: Type_DietryTypes[];
  handleClickItem: (item: Type_DietryTypes) => void;
  selectedItems: number[];
}> = ({ list, handleClickItem, selectedItems }) => {
  return (
    <>
      <div className="mt-[31px] overflow-y-auto h-full pb-[230px]">
        {list &&
          list.map((item) => (
            <div key={item.id} onClick={() => handleClickItem(item)}>
              <SelectListItem label={item.name} selected={selectedItems.includes(item.id)} />
            </div>
          ))}
      </div>
    </>
  );
};
