import { useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "components/atoms/button/button";
import { Input } from "components/atoms/input/input";
import {
  UPDATE_EMPLOYER,
  UPDATE_HELPER,
  UPDATE_PASSWORD,
} from "graphql/mutation";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoader } from "reducers/uiSlice";
import bcrypt from "bcryptjs";
import { GET_EMPLOYER, GET_HELPER } from "graphql/queries";
import { EmailRegex, nameRegex } from "utils/constants";
import Loader from "react-loader-spinner";

export const UpdateAccountForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setDataLoading] = useState(true);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [getHelper] = useLazyQuery(GET_HELPER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFormValues({
        ...formValues,
        name: data?.helpers[0]?.name,
        email: data?.helpers[0]?.email,
      });
      setDataLoading(false);
    },
  });
  const [getEmployer] = useLazyQuery(GET_EMPLOYER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFormValues({
        ...formValues,
        name: data?.employers[0]?.name,
        email: data?.employers[0]?.email,
      });
      setDataLoading(false);
    },
  });

  let userId: any = localStorage.getItem("user_Id");
  let userType = localStorage.getItem("user_type");

  useEffect(() => {
    if (userType === "EMPLOYER") {
      getEmployer({
        variables: {
          where: {
            id: { _eq: parseInt(userId) },
          },
        },
      });
    } else {
      getHelper({
        variables: {
          where: {
            id: { _eq: parseInt(userId) },
          },
        },
      });
    }
  }, [userType, userId]);

  const [updateUser] = useMutation(UPDATE_EMPLOYER);
  const [updatehelper] = useMutation(UPDATE_HELPER);
  const handleFormValues: (event: React.ChangeEvent<HTMLInputElement>) => void =
    (e) => {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

  const update = () => {
    const emailReg = EmailRegex;

    if (formValues.name.length < 3) {
      dispatch(
        setLoader({
          status: "error",
          message: "Provide atleast 3 characters for name.",
        })
      );
      setLoading(false);
      return;
    }

    if (
      formValues.email === "" ||
      formValues.name === ""
    ) {
      dispatch(setLoader({ status: "error", message: "Incorrect fields!" }));
      setLoading(false);
      return;
    }
    if (!nameRegex.test(formValues.name)) {
      dispatch(setLoader({ status: "error", message: "Invalid Name!" }));
      setLoading(false);
      return;
    }

    if (!emailReg.test(formValues.email)) {
      dispatch(setLoader({ status: "error", message: "Incorrect email!" }));
      setLoading(false);
      return;
    }

    if (formValues.password && formValues.password.length < 8) {
      dispatch(
        setLoader({
          status: "error",
          message: "Password is too short!",
        })
      );
      setLoading(false);
      return;
    }
    setLoading(true);
    if (userType == "EMPLOYER") {
      updateUser({
        variables: {
          id: parseInt(userId),
          set: { ...formValues },
        },
      })
        .then(() => {
          if (formValues.password)
           { updatePassword({
            variables: {
              email: formValues.email,
              user_type: userType,
              password: formValues.password,
            },
          })
            .then(() => {
              setLoading(false);
              history.push("/profile");
              dispatch(
                setLoader({
                  status: "success",
                  message: "Successfully updated!",
                })
              );
            })
            .catch((e) => {
              console.log(e);
              setLoading(false);
              dispatch(
                setLoader({ status: "error", message: "Incorrect fields!" })
              );
            });
          } else {
            setLoading(false);
              history.push("/profile");
              dispatch(
                setLoader({
                  status: "success",
                  message: "Successfully updated!",
                })
              );
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          dispatch(
            setLoader({ status: "error", message: "Incorrect fields!" })
          );
        });
    } else {
      updatehelper({
        variables: {
          id: parseInt(userId),
          set: { ...formValues },
        },
      })
        .then(() => {
          if (formValues.password) {
            updatePassword({
            variables: {
              email: formValues.email,
              user_type: userType,
              password: formValues.password,
            },
          })
            .then(() => {
              setLoading(false);
              history.push("/profile");
              dispatch(
                setLoader({
                  status: "success",
                  message: "Successfully updated!",
                })
              );
            })
            .catch((e) => {
              console.log(e);
              setLoading(false);
              dispatch(
                setLoader({ status: "error", message: "Incorrect fields!" })
              );
            });
          } else {
            setLoading(false);
              history.push("/profile");
              dispatch(
                setLoader({
                  status: "success",
                  message: "Successfully updated!",
                })
              );
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          dispatch(
            setLoader({ status: "error", message: "Incorrect fields!" })
          );
        });
    }
  };
  return (
    <>
      {isDataLoading ? (
        <div className="flex justify-center fixed top-[50%] left-[50%] ">
          <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
        </div>
      ) : (
        <form className="mt-[35px]">
          <Input
            label="Your First Name"
            onChangeValue={handleFormValues}
            value={formValues.name}
            name="name"
            labelIsWhite={false}
            autoComplete="on"
          />
          <Input
            label="Your Email Address"
            onChangeValue={handleFormValues}
            value={formValues.email}
            name="email"
            labelIsWhite={false}
            autoComplete="on"
          />
          <Input
            label="Password"
            onChangeValue={handleFormValues}
            value={formValues.password}
            name="password"
            type="password"
            labelIsWhite={false}
            autoComplete="off"
          />
          <div className={` ${loading && "pointer-events-none"}`}>
            <Button
              className="bottom-[88px] h-[46px] left-[50%] ml-[-60.5px]"
              loading={loading}
              onClick={() => update()}
              label="Continue"
            />
          </div>
        </form>
      )}
    </>
  );
};
