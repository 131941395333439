import { useMutation, useQuery } from "@apollo/client";
import DislikeDish from "assets/icons/dislike-dish-tinder.png";
import Frown from "assets/icons/frown-dish-tinder.png";
import LikeDish from "assets/icons/like-dish-tinder.png";
import LoveDish from "assets/icons/love-dish-tinder.png";
import Rectangle from "assets/images/rectangle.png";
import { UserPreferencesHeading } from "components/atoms/user-preferences-heading/user-preferences-heading";
import { CloseButton } from "components/icons/close-button/close-button";
import { Notification } from "components/molecules/loader/loader";
import { PageContainer } from "components/templates/user-preference-template/user-preference-template";
import dayjs from "dayjs";
import { ADD_FAVOURITE_DISH_BY_EMPLOYER_ID, INSERT_LIKED_MEAL } from "graphql/mutation";
import { GET_MOSIAC_MEALS_BY_USERID } from "graphql/queries";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import TinderCard from "react-tinder-card";
import { setAlreadyRemoved, setMeals } from "reducers/dishTinderSlice";
import { setLoader } from "reducers/uiSlice";
import { useAppDispatch, useAppSelector } from "store";
import { Type_TinderSwipeDirection } from "typescript/types";
import { combineClasses } from "utils/combineClasses";
export const DishTinder: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [userId, setUserId] = useState<number>(-1);
  const [iconToShow, setIconToShow] = useState<"dislike" | "like" | "love" | "">("");
  const [dishesDone, setDishesDone] = useState<number>(1);
  const [disableButtons, setDisableButtons] = useState(false);
  const [error, setError] = useState(false);
  const [scaleUp, setScaleUp] = useState(false);

  const meals = useAppSelector((state) => state.tinder.meals);
  const loader = useAppSelector((state) => state.ui.loader);
  const alreadyRemoved = useAppSelector((state) => state.tinder.alreadyRemoved);

  // START -------------> As advised in react-tinder-card documentation
  const childRefs: any = useMemo(
    () =>
      Array(meals?.length)
        .fill(0)
        .map((i) => React.createRef()),
    [meals],
  );
  // End ----------------> As advised in react-tinder-card documentation

  const { loading, data } = useQuery(GET_MOSIAC_MEALS_BY_USERID, {
    variables: {
      where: {
        created_at: {
          _gte: dayjs(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
        },
      },
      // limit: 5,
    },
    onCompleted: (data) => {
      if (!data) return;
      dispatch(setMeals(data.meal));
    },
    onError: (error) => {
      console.log(error);
      dispatch(setLoader({ status: "error", message: "An error occured!" }));
    },
  });

  const [insertLikedMeal] = useMutation(INSERT_LIKED_MEAL);
  const [insertFavouriteMeal] = useMutation(ADD_FAVOURITE_DISH_BY_EMPLOYER_ID);

  useEffect(() => {
    setDisableButtons(false);
    const userId = localStorage.getItem("user_Id") && localStorage.getItem("user_Id");
    if (userId) setUserId(parseInt(userId));
  }, []);

  useEffect(() => {
    if (loader?.status) {
      setError(true);
      setTimeout(() => {
        dispatch(setLoader({ status: "", message: "" }));
      }, 1000);
    } else {
      setError(false);
    }
  }, [loader]);

  const swiped: (direction: Type_TinderSwipeDirection, mealId: number) => void = (direction, mealId) => {
    if (userId === -1) return;
    if (direction === "down") return;

    setDisableButtons(true);

    if (direction === "up") {
      insertFavouriteMeal({
        variables: {
          objects: {
            employer_id: userId,
            meal_id: mealId,
          },
        },
      });
    } else {
      insertLikedMeal({
        variables: {
          objects: {
            liked: direction === "left" ? false : direction === "right" && true,
            meal_id: mealId,
            employer_id: userId,
          },
        },
      });
      setIconToShow(direction === "left" ? "dislike" : "like");
    }

    setDishesDone((prev) => prev + 1);
    dispatch(setAlreadyRemoved(mealId));
    setTimeout(() => {
      setDisableButtons(false);
    }, 200);
    setTimeout(() => {
      setIconToShow("");
      setScaleUp(false)
    }, 1000);
  };

  const swipeWithButtons = (direction: Type_TinderSwipeDirection) => {
    setDisableButtons(true);
    const cardsLeft = meals?.filter((meal: any) => !alreadyRemoved.includes(meal.id));
    if (cardsLeft?.length) {
      const toBeRemoved = cardsLeft[cardsLeft?.length - 1].id; // Find the card object to be removed
      const index = meals.map((meal: any) => meal.id).indexOf(toBeRemoved); // Find the index of which to make the reference to
      dispatch(setAlreadyRemoved(toBeRemoved)); // Make sure the next card gets removed next time if this card do not have time to exit the screen
      childRefs[index].current.swipe(direction); // Swipe the card!
    }
  };

  const outOfFrame = (mealId: any) => {};

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (iconToShow) {
      timeout = setTimeout(() => {
        setScaleUp(true);
      }, 100);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [iconToShow]);
  return (
    <PageContainer>
      <div className="flex justify-end mb-[8px] h-[20px]">
        <div className="flex items-center space-x-[25px]">
          <div onClick={() => history.push(`/meal-ideas`)}>
            <CloseButton />
          </div>
        </div>
      </div>
      {error && <Notification status={loader.status} message={loader.message} />}
      <UserPreferencesHeading heading="This Week's New Dishes" />
      <p className="text-white text-[14px] leading-[18px] mt-[4px] text-center opacity-50 font-normal">
        Love, Like or Dislike?
      </p>

      {data?.meal?.length < dishesDone ? (
        <p className="text-center mt-[150px]">No More Dishes!</p>
      ) : !loading && data?.meal?.length > 0 ? (
        <>
          <div className="mt-[20px]">
            <div className={combineClasses(`relative min-h-[380px] z-20 text-center w-full`)}>
              {data?.meal?.length >= dishesDone && (
                <>
                  <img src={Rectangle} alt="" className="absolute  w-full opacity-60 max-h-[395px] top-[-0.5%]" />
                  <img
                    src={Rectangle}
                    alt=""
                    className="absolute  w-[95%] ml-[2.5%] opacity-25 max-h-[407px] top-[7%]"
                  />
                  {iconToShow === "dislike" && (
                    <img
                      src={Frown}
                      alt=""
                      className="absolute left-[50%] ml-[-70px] top-[10%] mt-[50px] z-50"
                      style={{ transition: "transform 1s", transform: scaleUp ? "scale(1.2)" : "scale(1)" }}
                    />
                  )}
                </>
              )}
              {meals?.map((meal: any, index: number) => {
                return (
                  <div className={`w-full tinderContainer absolute rounded-md`} key={meal.id}>
                    <TinderCard
                      key={meal.id}
                      onSwipe={(dir) => swiped(dir, meal.id)}
                      onCardLeftScreen={() => outOfFrame(meal.id)}
                      preventSwipe={["down"]}
                      ref={childRefs[index]}
                    >
                      <div className="w-full bg-white absolute text-primary  rounded-md p-[7px] cursor-pointer">
                        <img src={meal.image_url} alt="" className="w-full h-[289px] rounded-md" />
                        <p className="px-[15px] pt-[30px] pb-[20px] text-center text-[16px] leading-[18px] font-semibold text-primary truncate ">
                          {meal.name}
                        </p>
                      </div>
                    </TinderCard>
                  </div>
                );
              })}
            </div>
            <p className="text-center mt-[25px] text-[#9566A0] text-[12px] leading-[18px] font-semibold bottom-[11px] z-10">
              {dishesDone >= data?.meal?.length ? data?.meal?.length : dishesDone} of {data?.meal?.length}
            </p>
          </div>
          <div className="flex justify-center mt-[2px] relative">
            <img
              src={DislikeDish}
              alt=""
              className="w-[52px] h-[52px] cursor-pointer absolute left-[20%] z-10"
              onClick={() => {
                !disableButtons && swipeWithButtons("left");
              }}
            />
            <img
              src={LoveDish}
              alt=""
              className="w-[60px] h-[60px] cursor-pointer absolute top-[10px] z-10"
              onClick={() => {
                !disableButtons && swipeWithButtons("up");
              }}
            />
            <img
              src={LikeDish}
              alt=""
              className="w-[52px] h-[52px] cursor-pointer absolute right-[20%] z-10"
              onClick={() => {
                !disableButtons && swipeWithButtons("right");
              }}
            />
            <p
              className="font-semibold text-[13px] leading-[16px] text-white absolute bottom-[-120px] left-[50%] ml-[-29px]"
              onClick={() => history.push(`/meal-ideas`)}
            >
              I'm Done
            </p>
          </div>
        </>
      ) : (
        loading && (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} />
          </div>
        )
      )}
    </PageContainer>
  );
};
