import React from 'react'
import { useHistory } from 'react-router-dom'
import BackArrowPNG from "../../../assets/icons/back-arrow-primary.png"
export const BackArrow: React.FC = () => {
  const history = useHistory()
  return (
    <div onClick={()=>history.goBack()}>
      <img src={BackArrowPNG} alt="" className="w-[20px] h-[20px] my-1 cursor-pointer"/>
    </div>
  )
}
