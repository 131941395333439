import React, { useContext, useEffect } from "react";
import { MealIdeasContext } from "../../../pages/meal-ideas/meal-context";
import { Enum_FavouriteControl } from "../../../typescript/enum";
import { DishImage } from "../../atoms/dish-image/dish-image";
import { FavouriteButton } from "../../atoms/favourite-button/favourite-button";
import { MosaicDishName } from "../../atoms/mosaic-dish-name/mosaic-dish-name";

type DishImageWithButtonProps = {
  image: string;
  favourite?: boolean;
  halfWidth?: boolean;
  id?: any;
  customDish?: boolean;
  onClickMeal?: (mealId: number, isCustom: boolean) => void;
  onClickFavourite: (mealId: number, favourite: Enum_FavouriteControl) => void;
  idDishDetail?: boolean
} & (
  | {
      mosaic?: boolean;
      dishName?: string;
    }
  | {
      mosaic: never;
      dishName: never;
    }
);

export const DishImageWithButton: React.FC<DishImageWithButtonProps> = ({
  image,
  favourite,
  mosaic,
  dishName,
  halfWidth=false,
  onClickMeal,
  onClickFavourite,
  id,
  customDish,
  idDishDetail=false
}) => {
  return (
    <div className={`relative ${!halfWidth ? "w-full h-[230px]" : "w-1/2 h-[185px] "} overflow-hidden rounded-md`}>
      <div onClick={() => onClickMeal && onClickMeal(id, customDish ?? false)}>
        <DishImage image={image} customDish={customDish} halfWidth={halfWidth}/>
      </div>
      <div
        className="absolute right-[8px] top-[8.5px]"
        onClick={() => onClickFavourite(id, favourite ? Enum_FavouriteControl.Remove : Enum_FavouriteControl.Add)}
      >
        <FavouriteButton filled={favourite} idDishDetail={idDishDetail} />
      </div>
      {mosaic && dishName && <MosaicDishName dishName={dishName} />}
    </div>
  );
};
