import React from "react";
import { combineClasses } from "../../../utils/combineClasses";
import { WhitePlusIcon } from "../../icons/white-plus-icon/white-plus-icon";

type ButtonProps = {
  label: string;
  onClick?: () => void;
  className?: string;
  renderPlusIcon?: boolean;
};

export const ShoppingListMainButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  className = "h-[40px] bottom-[27px]",
  renderPlusIcon = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={combineClasses(
        `bg-accent fixed left-[50%] ml-[-160px] font-semibold text-sm flex justify-center items-center cursor-pointer text-white px-[30px]  traking-[1%] capitalize mx-auto rounded-full
        mt-[65%] z-100 `,
        className,
      )}
      style={{ width: "max-content" }}
    >
      {renderPlusIcon && <div className="mr-[11px]"> <WhitePlusIcon /> </div> }
      {label}
    </div>
  );
};
