import { Divider } from "components/atoms/divider/divider";
import { UserPreferencesHeading } from "components/atoms/update-account/user-preferences-heading";
import { BackArrow } from "components/icons/back-arrow-primary/back-arrow-primary";
import { PreferencesList } from "components/molecules/preferences/prefernces-list";
import { PageTemplate } from "components/templates/page-template/page-template";
import { ProfilePageContainer } from "components/templates/page-template/page-template-for-profile";
export const Preferences: React.FC = () => {
  return (
    <PageTemplate>
      <div className="flex">
        <BackArrow />
        <UserPreferencesHeading heading="Prefernces" />
      </div>
      <div>
        <Divider className="mt-[21px]" />
        <PreferencesList />
        <Divider className="" />
      </div>
    </PageTemplate>
  );
};
