import React, { useEffect, useState } from "react";
import { BackArrow } from "../../components/icons/back-arrow/back-arrow";
import { Button } from "../../components/atoms/button/button";
import { UserPreferencesHeading } from "../../components/atoms/user-preferences-heading/user-preferences-heading";
import { SelectListItems } from "../../components/organisms/select-list-items.tsx/select-list-items";
import { PageContainer } from "../../components/templates/user-preference-template/user-preference-template";
import { useQuery, useMutation } from "@apollo/client";
import { GET_DIETS } from "../../graphql/queries";
import { Type_DietryTypes } from "../../typescript/types";
import { INSERT_CUISINE_FAVOURITE } from "../../graphql/mutation";
import { useHistory } from "react-router-dom";
import { Enum_Diet_Types } from "../../typescript/enum";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setLoader } from "reducers/uiSlice";
import { Notification } from "../../components/molecules/loader/loader";
import Loader from "react-loader-spinner";

export const Diet: React.FC = () => {
  const history = useHistory();
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [diet, setDiet] = useState<Type_DietryTypes[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    if (loader?.status) {
      setLoading(true);
      setTimeout(() => {
        dispatch(setLoader({ status: "", message: "" }));
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [loader]);

  const { loading } = useQuery(GET_DIETS, {
    variables: {
      where: { category_tag: { tag: { _eq: Enum_Diet_Types.diets } } },
    },
    onCompleted: (data) => {
      data && setDiet(data.filter_tags);
      data && setIsDataLoading(false);
    },
  });

  const [addFavouriteDiets, { error }] = useMutation(INSERT_CUISINE_FAVOURITE);

  const handleClickItem = (item: Type_DietryTypes) => {
    if (!selectedItems.includes(item.id)) setSelectedItems([...selectedItems, item.id]);
    else {
      const _selectedItems = selectedItems;
      const filteredSelectedItems = _selectedItems.filter((selectedItem) => selectedItem !== item.id);
      setSelectedItems(filteredSelectedItems);
    }
  };

  const handleContinueClick = () => {
    //TODO: Add employer id or helper id
    setTimeout(() => {
      let userId: any;
      userId = localStorage.getItem("user_Id");
      userId &&
        selectedItems.forEach((item) => {
          addFavouriteDiets({
            variables: {
              objects: {
                employer_id: parseInt(userId),
                filter_tag_id: item,
              },
            },
          });
        });
      history.push(`/allergies`);
    }, 500);
  };

  useEffect(() => {
    error && dispatch(setLoader({ status: "error", message: "Something went wrong!" }));
    error && setIsDataLoading(false);
  }, [error]);

  return (
    <>
      <PageContainer>
        {isDataLoading && (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        )}
        <BackArrow />
        {isLoading && <Notification status={loader.status} message={loader.message} />}
        <UserPreferencesHeading heading="Select the diet types that match your household" />
        {loading ? (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} />
          </div>
        ) : (
          <>
            {" "}
            <SelectListItems list={diet} handleClickItem={handleClickItem} selectedItems={selectedItems} />
            <Button label="continue" onClick={handleContinueClick} hasPurpleGradient />{" "}
          </>
        )}
      </PageContainer>
      {/* <StyledBoxedShadow /> */}
    </>
  );
};
