import { PlanDinnerDayName } from "components/atoms/plan-dinner-day-name/plan-dinner-day-name";
import { Toggle } from "components/atoms/toggle/toggle";
import { PlanDinnerDishesScroll } from "components/organisms/plan-dinner-dishes-scroll/plan-dinner-dishes-scroll";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Type_AddDish_Dish, Type_Row } from "typescript/types";

type PlanDinnerDayProps = {
  row: Type_Row;
  handleOnClickDayName: (id: string) => void;
  isOpened: boolean;
  dishes: Type_AddDish_Dish[];
  removeDishFromCollection: (id: number, isDayOfWeek: boolean) => void;
  isDayOfWeek?: boolean;
  dayActiveStatus: boolean[];
  handleToggle: (id: number) => void;
  disableDrag: boolean;
  itemIsBeingDragged: (_isDragging: boolean) => void;
};

export const PlanDinnerDay: React.FC<PlanDinnerDayProps> = ({
  row,
  handleOnClickDayName,
  isOpened,
  dishes,
  removeDishFromCollection,
  isDayOfWeek,
  dayActiveStatus,
  handleToggle,
  disableDrag,
  itemIsBeingDragged,
}) => {
  return (
    <Droppable droppableId={row.id ? row.id : "0"} direction="horizontal" isDropDisabled={disableDrag}  >
      {(provided) => (
        <div className="py-[18px] w-full " {...provided.droppableProps} ref={provided.innerRef}>
          <div className="flex justify-between items-center ">
            <div className="flex items-center" onClick={() => handleOnClickDayName(row.id)}>
              <PlanDinnerDayName day={row.title} isActive={dayActiveStatus[parseInt(row.id) - 1]} />
            </div>
            {(!isOpened || dishes.length > 0) && (
              <Toggle
                isActive={dayActiveStatus[parseInt(row.id) - 1]}
                id={parseInt(row.id) - 1}
                handleToggle={handleToggle}
                disabled={disableDrag}
              />
            )}
          </div>
          {(isOpened || dishes.length > 0) && (
            <div className={` ${dayActiveStatus[parseInt(row.id) - 1] ? 'block' : 'hidden'}`}>
            <PlanDinnerDishesScroll
              id={row.id}
              removeDishFromCollection={removeDishFromCollection}
              dishes={dishes}
              isDayOfWeek={isDayOfWeek}
              disableDrag={disableDrag}
              itemIsBeingDragged={itemIsBeingDragged}
            />
            </div>
          )}
          {(!isOpened|| dishes.length <= 0) && <div>{provided.placeholder}</div>}
        </div>
      )}
    </Droppable>
  );
};
