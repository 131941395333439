import React from 'react'
import { Meal, Type_AddDish_Dish } from 'typescript/types'
import { AddToDinnerPlanButton } from '../../molecules/add-to-dinner-plan-button/add-to-dinner-plan-button'
import { DishDetailHeader } from '../../organisms/dish-detail-header/dish-detail-header'
import {Navigation} from "../../organisms/navigation/navigation"
export const DishDetailTemplate: React.FC<{heading: string, onClickClose: () => void, handleOnAddDish: (dishToAdd: Type_AddDish_Dish) => void}> = ({children, heading="", onClickClose, handleOnAddDish}) => {
  return (
    <div className="mt-[26px] mx-[18px] pb-[150px]" >
      <DishDetailHeader heading={heading} onClickClose={onClickClose} />
      {children}
      <AddToDinnerPlanButton {...{ handleOnAddDish } }/>
      <Navigation />
    </div>
  )
}
