import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { setUrl } from "reducers/userSlice";
import { useAppDispatch, useAppSelector } from "store";

const AppRouter = ({ routes }: any) => {
  return (
    <Router>
      <Switch>
        {routes.map((route: any, index: any) =>
          route.protected ? <ProtectedRoute key={index} {...route} /> : <RouteWithSubRoutes key={index} {...route} />,
        )}
      </Switch>
    </Router>
  );
};
const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useAppDispatch();
  const  shareObj = useAppSelector(state => state.user.shareObj)
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      setLoading(true)
      const params = new URLSearchParams(location.search);
      const _ref = params.get("ref");
      const _date = params.get("date");
      if (_ref && _date) {
        dispatch(
          setUrl({
            isRef: true,
            url: location.search,
            ref: _ref,
            date: dayjs(_date).format("YYYY-MM-DD")
          }),
        );
        
      }
      setLoading(false)
    } 
  }, [location.search]);

  if (loading) {
    return (
      <div className="flex justify-center fixed top-[50%] left-[50%] ">
        <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} />
      </div>
    );
  }

  return localStorage.getItem("user_Id") ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : shareObj.isRef ? (
    <Redirect to={`/${shareObj.url}`} />
  ) : (
    <Redirect to="/" />
  );
};

function RouteWithSubRoutes(route: any) {
  return (
    <>
      <Route
        exact={route.exact}
        path={route.path}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    </>
  );
}

export default AppRouter;
