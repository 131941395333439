import { useLazyQuery, useMutation } from "@apollo/client/react";
import { Button } from "components/atoms/button/button";
import { Input } from "components/atoms/input/input";
import { SIGN_IN } from "graphql/mutation";
import { GET_HOUSEHOLD } from "graphql/queries";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedWeekRange } from "reducers/planDinnerSlice";
import { setLoader } from "reducers/uiSlice";
import { useAppSelector } from "store";
import { URL_REF } from "utils/constants";

export const LoginForm: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const shareObj = useAppSelector((state) => state.user.shareObj);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const [customSignIn] = useMutation(SIGN_IN);
  const [getHousehold] = useLazyQuery(GET_HOUSEHOLD, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      localStorage.setItem("household_id", data?.household_employer_helper[0]?.household_id);
      // START - code for when a link is shared for planned meals or shopping list, 
      // user is not logged in, 
      // is directed to login screen  
      // and then logs in
      if (shareObj.isRef) {
        shareObj.ref === URL_REF.PlanDinner
          ? history.push(`/${shareObj.ref}${shareObj.url}`)
          : history.push(`/${shareObj.ref}${shareObj.url}`);
        dispatch(setSelectedWeekRange({ date: shareObj.date }));
      // END - code for when a link is shared for planned meals or shopping list ...,
      } else {
        history.push("/meal-ideas");
      }
      setLoading(false);
      dispatch(setLoader({ status: "success", message: "Successfully Signed In!" }));
    },
  });

  const handleFormValues: (event: React.ChangeEvent<HTMLInputElement>) => void = (e) =>
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

  const catchHandle = (e: any) => {
    if (e.graphQLErrors) {
      // reduce to get message
      const data: any = _.reduce(e.graphQLErrors, (res: any, err) => [...res, err.message], []);
      setLoading(false);
      dispatch(setLoader({ status: "error", message: data[0]?.split(":").pop() ?? "Invalid email or password" }));
    }
  };

  const signIn = () => {
    localStorage.clear();
    setLoading(true);
    if (formValues.email == "" && formValues.password == "") {
      history.push("/meal-ideas");
      dispatch(setLoader({ status: "success", message: "Successfully Signed In!" }));
    }
    customSignIn({
      variables: {
        object: {
          email: formValues.email,
          password: formValues.password
        }
      }
    }).then((res) => {
      const token = res?.data?.customLogin?.token
      if (token) {
        localStorage.clear();
        var decodedToken: any = jwt_decode(token);
        decodedToken && localStorage.setItem('user_Id', decodedToken?.user_id)
        decodedToken && localStorage.setItem('user_type', decodedToken?.user_type)
        decodedToken && localStorage.setItem('household_name', decodedToken?.household_name)
        token && localStorage.setItem('token', token)
      }
        getHousehold({
          variables: {
            where: {
              user_id: { _eq: parseInt(decodedToken?.user_id) },
            },
          },
        });
      })
    .catch((e) => {
      console.log(e);
      catchHandle(e)
    })
  }

  const onPressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void = (e) => {
    if(e.key === "Enter") {
      signIn()
    }
  }

  return (
    <form className="mt-[32px]">
      <Input label="Email" name="email" value={formValues.email} onChangeValue={handleFormValues} labelIsWhite />
      <Input
        label="Password"
        type="password"
        name="password"
        value={formValues.password}
        onChangeValue={handleFormValues}
        labelIsWhite
        onPressEnter={onPressEnter}
      />
      <div className={`flex justify-center mt-[50px] ${loading && "pointer-events-none"}`}>
        <Button
          loading={loading}
          label="Sign In"
          onClick={signIn}
          className="h-[40px]"
          isSignInButton
          hasPurpleGradient
        />
      </div>
    </form>
  );
};
