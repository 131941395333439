import { useLazyQuery } from "@apollo/client";
import { Divider } from "components/atoms/divider/divider";
import { ListSideText } from "components/atoms/list-side-text";
import { SideArrow } from "components/icons/side-arrow/side-arrow";
import { PreferencesOptionName } from "components/organisms/preferences/preferences-option";
import { GET_HELPER, GET_HELPERS } from "graphql/queries";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { helpers } from "utils/constants";

export const HelperList: React.FC = () => {
  const [helpers, setHelpers] = useState([]);
  const [helpersData, setHelpersData] = useState<any>([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [notFound, setNotFound] = useState('');
  const [getHelper] = useLazyQuery(GET_HELPER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setHelpersData(data?.helpers);
      setDataLoading(false);
    },
  });

  const [getHelpers, { error }] = useLazyQuery(GET_HELPERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let helpersArr: any = [];
      if (data?.household_employer_helper?.length > 0) {
        data?.household_employer_helper?.map((helper: any) => {
          helper?.user_id !== null && helpersArr.push(helper?.user_id);
        });
        if(helpersArr?.length > 0) {
          setHelpers(helpersArr);
        } else {
          setDataLoading(false);
          setNotFound('No results Found!')
        }
      } else {
        setDataLoading(false);
        setNotFound('No results Found!')
      }
    },
  });

  useEffect(() => {
    error && setDataLoading(false);
  }, [error]);

  useEffect(() => {
    if (helpers?.length > 0) {
      getHelper({
        variables: {
          where: {
            id: { _in: helpers },
          },
        },
      });
    }
  }, [helpers]);

  useEffect(() => {
    let userId: any;
    let userType: any;
    let householdId: any;
    setTimeout(function () {
      userId = localStorage.getItem("user_Id");
      userType = localStorage.getItem("user_type");
      householdId = localStorage.getItem("household_id");
      getHelpers({
        variables: {
          where: {
            user_type: { _eq: "HELPER" },
            // added_by: { _eq: parseInt(userId)},
            household_id: { _eq: parseInt(householdId) },
          },
        },
      });
    }, 200);
  }, []);

  return (
    <>
      {helpersData?.length > 0 && <Divider className="mt-[21px]" />}
      <div className="flex flex-col divide-y-2">
        {helpersData?.length > 0 ? (
          helpersData.map((helper: any) => (
            <div className="py-[26px]">
              <div className="flex justify-between items-center">
                <div
                  className="flex w-full justify-between"
                  // onClick={() => handleOnClickDayName(id)}
                >
                  {" "}
                  <PreferencesOptionName option={helper?.name} />
                  <ListSideText text={helper?.email} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            {isDataLoading && (
              <div className="flex justify-center fixed top-[50%] left-[50%] ">
                <Loader
                  type="TailSpin"
                  color="#2F0842"
                  height={20}
                  width={20}
                />
              </div>
            )}
          </>
        )}
      </div>
      {helpersData?.length > 0 && <Divider className="" />}
      {notFound && <p className="text-primary mt-[14px] font-light text-[16px]">No helpers found!</p>}
    </>
  );
};

