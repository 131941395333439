import React from "react";
import { Type_Tabs } from "../../../typescript/types";

type SingleTabProps = {
  selected?: boolean;
  handleTabChange: (tab: Type_Tabs) => void;
  tab: Type_Tabs
};

export const SingleTab: React.FC<SingleTabProps > = ({  selected = false, handleTabChange, tab }) => {
  return (
    <div
      className={`cursor-pointer flex-1 pb-[9px] text-center text-primary text-[14px] leading-[20px]  ${
        selected ? "font-semibold border-b-[3px] border-primary" : "font-normal border-b border-[#D8D8D8]"
      }`}
      onClick={() => handleTabChange(tab)}
    >
      {tab.label}
    </div>
  );
};
