import { Divider } from 'components/atoms/divider/divider';
import React, { useEffect, useState} from 'react'
import { useAppSelector } from "store";
import { ShoppingIngredientsRow } from "./shopping-ingredients-row";

export const ShoppingIngredientsList: React.FC<{addToBoughtIngredients: (name: string, id: number) => void, removeFromBoughtIngredients: (name: string, id: number) => void}> = ({addToBoughtIngredients, removeFromBoughtIngredients}) => {
  const ingredientsList = useAppSelector((state) => state.shoppingList.ingredientsList);
  const [_sortedIngredients, setSortedIngredients] = useState([]);

  useEffect(() => {
    let _ingredientsList = Array.from(ingredientsList)
    if (_ingredientsList?.length > 0) {
      let sortedIngredients: any = _ingredientsList.sort(SortByName)
      setSortedIngredients(sortedIngredients)
      console.log("sorted", sortedIngredients)
    }
  }, [ingredientsList])

  function SortByName(x: any,y: any) {
    return ((x.name.toLowerCase() == y.name.toLowerCase()) ? 0 : ((x.name.toLowerCase() > y.name.toLowerCase()) ? 1 : -1 ));
  }

  return (
    <>
    {_sortedIngredients?.length > 0 &&  <Divider className="mt-[21px]" />}
      <div className="flex flex-col divide-y-2">
        {_sortedIngredients?.length > 0 && _sortedIngredients?.map((item: any) => (
          <div className="py-[18px]">
            <ShoppingIngredientsRow content={item} addToBoughtIngredients={addToBoughtIngredients} removeFromBoughtIngredients={removeFromBoughtIngredients}/>
          </div>
        ))}
      </div>
      {_sortedIngredients?.length > 0 &&   <Divider className="" />}
    </>
  );
};
