import { BackArrow } from "components/icons/back-arrow/back-arrow";
import { Button } from "components/atoms/button/button";
import { UserPreferencesHeading } from "components/atoms/user-preferences-heading/user-preferences-heading";
import { SelectListItems } from "components/organisms/select-list-items.tsx/select-list-items";
import { PageContainer } from "components/templates/user-preference-template/user-preference-template";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Type_DietryTypes } from "typescript/types";
import { ACCOUNT_TYPES } from "utils/constants";

export const ChooseAccountType: React.FC = () => {
  const history = useHistory()
  const [selectedAccountType, setSelectedAccountType] = useState<number[]>([]);

  const handleClickItem = (item: Type_DietryTypes) => {
    if (!selectedAccountType.includes(item.id)) setSelectedAccountType([item.id]);
    else {
      const _selectedAccountType = selectedAccountType;
      const filteredSelectedAccountType = _selectedAccountType.filter(
        (selectedAccountType) => selectedAccountType !== item.id,
      );
      setSelectedAccountType(filteredSelectedAccountType);
    }
  };
  return (
    <PageContainer>
      <BackArrow />
      <UserPreferencesHeading heading="Choose an Account type" />
      <div className="mt-[24px]">
        <SelectListItems handleClickItem={handleClickItem} selectedItems={selectedAccountType} list={ACCOUNT_TYPES} />
      </div>
      <Button label="Continue" hasPurpleGradient onClick={() => {
        if(selectedAccountType.length > 0) {
          history.push(`/createAccount`)
        }
      }} />
    </PageContainer>
  );
};
