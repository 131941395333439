import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/atoms/button/button";
import { DownChevronIcon } from "components/atoms/down-chevron-icon/down-chevron-icon";
import { InputLabel } from "components/atoms/input-label/input-label";
import { Input } from "components/atoms/input/input";
import { TextArea } from "components/atoms/text-area/text-area";
import { UploadButton } from "components/atoms/upload-button/upload-button";
import { CloseButtonFilled } from "components/icons/close-button-filled/close-button-filled";
import { SingleTag } from "components/molecules/single-tag/single-tag";
import { UploadUrl } from "config/config";
import {
  DELETE_MEAL_FILTERS,
  DETE_INSTRUCTIONS,
  INSERT_CUSTOM_MEAL,
  INSERT_INSTRUCTION,
  INSERT_MEAL_FILTERS,
  UPDATE_CUSTOM_MEAL,
} from "graphql/mutation";
import { GET_INSTRUCTIONS, GET_MEAL_FILTERS, GET_TAGS } from "graphql/queries";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { setLoader } from "reducers/uiSlice";
import { TODO, Type_CustomDishFormValues, Type_ReactSelect } from "typescript/types";
import { STYLES, THEME, VALID_IMAGE_TYPES } from "utils/constants";
import { replaceSpacesWithPlus } from "utils/replaceSpacesWithPlus";

const DropdownIndicator: TODO = ({ ...props }: TODO) => (
  <components.DropdownIndicator {...props}>
    <DownChevronIcon />
  </components.DropdownIndicator>
);

export const CustomDishForm: React.FC<{ meal?: any }> = ({ meal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editMeal, setEditMeal] = useState<any>();
  const [insertInstructions] = useMutation(INSERT_INSTRUCTION);
  const [insertMealFilters] = useMutation(INSERT_MEAL_FILTERS);
  const [dltInstruction, setDltInstruction] = useState();
  const [loading, setLoading] = useState(false);
  const [dltMealfilters, setDltMealFilters] = useState([]);
  const [getInstructions] = useLazyQuery(GET_INSTRUCTIONS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setDltInstruction(data?.instructions[0]?.id);
      setFormValues({
        ...formValues,
        cookingInstructions: data?.instructions[0]?.description,
      });
    },
  });
  let meal_filters: any = [];
  const [deleteInstructions] = useMutation(DETE_INSTRUCTIONS);
  const [deleteMealFilters] = useMutation(DELETE_MEAL_FILTERS);
  const [getMealFilters] = useLazyQuery(GET_MEAL_FILTERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let prev_arr: any = [];
      const _data: Type_ReactSelect[] = data?.meal_filters?.map((tag: any) => {
        prev_arr.push(tag?.filter_tag?.id);
        console.log(meal_filters, "meal filters");
        return {
          label: tag.filter_tag.tags,
          value: tag.filter_tag.id,
        };
      });
      console.log("prev arr", prev_arr);
      setDltMealFilters(prev_arr);
      setFormValues({ ...formValues, tags: _data });
      getInstructions({
        variables: {
          where: {
            meal_id: { _eq: meal?.id },
          },
        },
      });
    },
  });
  const [categoriesForSelect, setCategoriesForSelect] = useState<Type_ReactSelect[]>([]);

  const [imageLabel, setImageLabel] = useState("");
  const [formValues, setFormValues] = useState<Type_CustomDishFormValues>({
    dishTitle: "",
    description: "",
    cookingInstructions: "",
    videoLink: "",
    websiteLink: "",
    tags: [],
    imageURL: "",
  });

  useEffect(() => {
    if (meal?.name) {
      setEditMeal(meal);
      getMealFilters({
        variables: {
          where: {
            meal_id: { _eq: meal?.id },
          },
        },
      });
      setFormValues({
        ...formValues,
        dishTitle: meal?.name,
        description: meal?.description,
        imageURL: meal?.image_url,
        videoLink: meal?.video_url,
        websiteLink: meal?.website_url,
      });
    }
  }, [meal]);

  useQuery(GET_TAGS, {
    onCompleted: (data) => {
      const _data: Type_ReactSelect[] = data?.filter_tags.map((tag: TODO) => {
        return {
          label: tag.tags,
          value: tag.id,
        };
      });
      setCategoriesForSelect(_data);
    },
  });

  const [addMeal] = useMutation(INSERT_CUSTOM_MEAL);
  const [updateMeal] = useMutation(UPDATE_CUSTOM_MEAL);

  const validations: () => boolean = () => {
    if (!formValues.dishTitle) {
      dispatch(setLoader({ status: "error", message: "Dish Title is required!" }));
      return false;
    } else if (!formValues.dishTitle.match(/^[a-zA-Z()\s]*$/)) {
      dispatch(setLoader({ status: "error", message: "Dish Title cannot contain numbers or special characters!" }));
      return false;
    } else if (formValues.tags.length <= 0) {
      dispatch(setLoader({ status: "error", message: "Category is required!" }));
      return false;
    } else return true;
  };

  const handleSubmit = () => {
    setLoading(true);
    let userId: any;
    let userType: any;
    let householdId: any;
    setTimeout(() => {
      userId = localStorage.getItem("user_Id");
      userType = localStorage.getItem("user_type");
      householdId = localStorage.getItem("household_id");
      let tagsArr = formValues?.tags?.map((tags) => {
        return {
          filter_tag_id: tags?.value,
          meal_id: meal?.id,
        };
      });
      if (!validations()) {
        setLoading(false);
        return;
      }
      if (editMeal) {
        updateMeal({
          variables: {
            id: meal?.id,
            set: {
              name: formValues.dishTitle,
              description: formValues.description,
              video_url: formValues.videoLink,
              employer_id: userType == "EMPLOYER" ? parseInt(userId) : null,
              // helper_id: userType == "HELPER" ? parseInt(userId) : null,
              household_id: parseInt(householdId),
              website_url: formValues.websiteLink,
              custom_meal: true,
              image_url: formValues.imageURL,
            },
          },
        })
          .then(() => {
            deleteInstructions({
              variables: {
                where: {
                  id: { _eq: dltInstruction },
                  meal_id: { _eq: meal?.id },
                },
              },
            })
              .then(() => {
                deleteMealFilters({
                  variables: {
                    where: {
                      filter_tag_id: { _in: dltMealfilters },
                      meal_id: { _eq: meal?.id },
                    },
                  },
                })
                  .then(() => {
                    insertInstructions({
                      variables: {
                        objects: {
                          name: formValues.cookingInstructions,
                          description: formValues.cookingInstructions,
                          meal_id: meal?.id,
                        },
                      },
                    })
                      .then(() => {
                        insertMealFilters({
                          variables: {
                            objects: [...tagsArr],
                          },
                        })
                          .then(() => {
                            setLoading(false);
                            history.push(`/meal-ideas`);
                            dispatch(
                              setLoader({
                                status: "success",
                                message: "Successfully added!",
                              }),
                            );
                          })
                          .catch((e) => {
                            setLoading(false);
                            dispatch(
                              setLoader({
                                status: "error",
                                message: "Incorrect fields!",
                              }),
                            );
                            console.log(e);
                          });
                      })
                      .catch((e) => {
                        setLoading(false);
                        dispatch(
                          setLoader({
                            status: "error",
                            message: "Incorrect fields!",
                          }),
                        );
                        console.log(e);
                      });
                  })
                  .catch((e) => {
                    setLoading(false);
                    dispatch(
                      setLoader({
                        status: "error",
                        message: "Incorrect fields!",
                      }),
                    );
                    console.log(e);
                  });
              })
              .catch((e) => {
                setLoading(false);
                dispatch(setLoader({ status: "error", message: "Incorrect fields!" }));
                console.log(e);
              });
          })
          .catch((e) => {
            setLoading(false);
            dispatch(setLoader({ status: "error", message: "Incorrect fields!" }));
            console.log(e);
          });
      } else {
        addMeal({
          variables: {
            objects: {
              name: formValues.dishTitle,
              description: formValues.description,
              video_url: formValues.videoLink,
              employer_id: userType == "EMPLOYER" ? parseInt(userId) : null,
              // helper_id: userType == "HELPER" ? userId : null,
              household_id: parseInt(householdId),
              website_url: formValues.websiteLink,
              custom_meal: true,
              image_url: formValues.imageURL,
              instructions: {
                data: {
                  description: formValues.cookingInstructions,
                  name: formValues.cookingInstructions,
                },
              },
              meal_filters: {
                data: formValues?.tags?.map((tags) => {
                  return {
                    filter_tag_id: tags?.value,
                  };
                }),
              },
            },
          },
        })
          .then(() => {
            history.push(`/meal-ideas`);
            dispatch(setLoader({ status: "success", message: "Successfully added!" }));
          })
          .catch((e) => {
            setLoading(false);
            dispatch(setLoader({ status: "error", message: "Incorrect fields!" }));
            console.log(e);
          });
      }
    }, 500);
  };

  const handleOnChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void =
    (e) => {
      if (e.target.name === "dishTitle") {
        e.target.value.length <= 40 && setFormValues({ ...formValues, dishTitle: e.target.value });
      } else {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      }
    };

  const fileValidation: (file: File) => boolean = (file) => {
    const fileType = file.type.split("/")[1];
    if (file.size > 2000000) {
      dispatch(setLoader({ status: "error", message: "Max image size is 2Mb." }));
      return false;
    } else if (!VALID_IMAGE_TYPES.some((type) => type === fileType)) {
      dispatch(setLoader({ status: "error", message: "Only PNG, JPG and JPEG are allowed." }));
      return false;
    } else return true;
  };

  const handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void = async (e) => {
    dispatch(setLoader({ status: "success", message: "Uploading..." }));

    try {
      if (e.target.files) {
        const file = e.target.files[0];

        if (!fileValidation(file)) return;

        let data = new FormData();
        data.append("files", file, `${Date.now()}_${file.name}`);

        const response = await fetch(UploadUrl, {
          method: "POST",
          body: data,
        });
        const _response = await response.json();
        setImageLabel(_response.filesUrl[0].originalname);
        const imageURL = `${process.env.REACT_APP_BASE_URL_S3_BUCKET_RESPONSE}/${replaceSpacesWithPlus(
          _response.filesUrl[0].originalname,
        )}`;
        setFormValues({ ...formValues, imageURL });
        dispatch(setLoader({ status: "success", message: "Image uploaded successfully!" }));
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoader({ status: "error", message: "Image was not uploaded!" }));
    }
  };

  const handleClickRemoveTag = (tagName: string) => {
    let dlt_id: any;
    const filteredTags = formValues.tags.filter((tag) => tag?.label !== tagName);
    formValues?.tags?.map((item: any) => {
      if (item?.label == tagName) {
        dlt_id = item.value;
        return;
      }
    });
    // setDltMealFilters(dltMealfilters.filter((item) => item !== dlt_id));
    setFormValues({ ...formValues, tags: filteredTags });
  };

  const removeImageThumbnail = () => setFormValues({ ...formValues, imageURL: "" });

  return (
    <form>
      <Input onChangeValue={handleOnChange} value={formValues.dishTitle} name="dishTitle" label="Dish Title*" />
      <div>
        <InputLabel label="Category*" />
        <div className="flex flex-wrap gap-2 mb-[15px]">
          {formValues?.tags?.map(
            (tag) =>
              tag && <SingleTag key={tag.value} tagName={tag.label} handleClickRemoveTag={handleClickRemoveTag} />,
          )}
        </div>
        <Select
          options={categoriesForSelect}
          styles={STYLES}
          placeholder="Please Select..."
          value={formValues.tags}
          components={{ DropdownIndicator }}
          isMulti
          name="tags"
          theme={THEME}
          onChange={(value: TODO) => setFormValues({ ...formValues, tags: value })}
        />
      </div>
      <TextArea onChangeValue={handleOnChange} value={formValues.description} name="description" label="Description" />

      <InputLabel label="Upload an Image" />
      <div className="flex mb-[20px]  items-center">
        {formValues.imageURL ? (
          <div className="relative min-w-[90px] w-[70px] h-[70px]">
            <img src={formValues?.imageURL} alt="" className="w-[90px] h-[70px] rounded-md" />
            <div className="absolute top-[5px] right-[5px]" onClick={removeImageThumbnail}>
              <CloseButtonFilled />
            </div>
          </div>
        ) : (
          <div className="mr-[20px] mt-[15px]">
            <UploadButton buttonLabel={`Select Image`} handleOnChange={handleImageUpload} />
          </div>
        )}
      </div>
      <TextArea
        onChangeValue={handleOnChange}
        value={formValues.cookingInstructions}
        name="cookingInstructions"
        label="Cooking Instructions"
      />
      <Input onChangeValue={handleOnChange} value={formValues.videoLink} name="videoLink" label="Recipe Video Link" />
      <Input
        onChangeValue={handleOnChange}
        value={formValues.websiteLink}
        name="websiteLink"
        label="Recipe Website Link"
      />
      <Button
        label={editMeal ? "Update My Dish" : "Add My Dish"}
        onClick={handleSubmit}
        className={`bottom-[88px] h-[46px] left-[50%] ${editMeal ? "ml-[-85px]" : "ml-[-76.5px]"} ${
          loading && "pointer-events-none"
        }`}
        loading={loading}
      />
    </form>
  );
};
