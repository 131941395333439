import React from "react";
import { Type_Tabs } from "../../../typescript/types";
import { SingleTab } from "../../atoms/single-tab/single-tab";

export const Tabs: React.FC<{ tabs: Type_Tabs[]; handleTabChange: (tab: Type_Tabs) => void; selectedTab: number }> = ({
  tabs,
  handleTabChange,
  selectedTab,
}) => {
  return (
    <div className="flex">
      {tabs.map((tab, index) => (
          <SingleTab
            tab={tab}
            selected={selectedTab === tab.id && true}
            handleTabChange={handleTabChange}
            key={tab.id}
          />
      ))}
    </div>
  );
};
