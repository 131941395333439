import React from "react";
import HeartFilledSVG from "../../../assets/icons/heart-filled.svg";
import HeartOutlineSVG from "../../../assets/icons/heart-outline.svg";
export const FavouriteButton: React.FC<{ filled?: boolean; idDishDetail?: boolean }> = ({
  filled = false,
  idDishDetail,
}) => {
  return (
    <img
      src={filled ? HeartFilledSVG : HeartOutlineSVG}
      alt=""
      className={`w-[24px]  ${idDishDetail ? "h-[31px] w-[31px]" : "h-[24px] w-[24px]"} cursor-pointer`}
    />
  );
};
