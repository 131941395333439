
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type TProps = {
  paramToGet: string;
};

export const useParamFromRoute = (paramToGet: string) => {
  const location = useLocation();
  const [param, setParam] = useState('');

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const param = params.get(paramToGet);
      param && setParam(param);
    }
  }, [location.search]);

  return param
};