/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { Switch } from "@headlessui/react";
import { combineClasses } from "utils/combineClasses";

type Props = {
  isActive: boolean;
  id: number;
  handleToggle: (id: number) => void;
  disabled: boolean;
};

export const Toggle: React.FC<Props> = ({ isActive, handleToggle, id, disabled }) => {
  return (
    <Switch
      checked={isActive}
      onChange={() => handleToggle(id)}
      className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none "
      disabled={disabled}
    >
      <span className="sr-only">Toggle Day</span>
      <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
      <span
        aria-hidden="true"
        className={combineClasses(
          "bg-[#e4e4e4] pointer-events-none absolute h-[10px] w-[24px] mx-auto rounded-full transition-colors ease-in-out duration-200",
        )}
      />
      <span
        aria-hidden="true"
        className={combineClasses(
          isActive ? "translate-x-[23px] bg-[#35C680]" : "translate-x-[7px] bg-[#EE4E4E]",
          "pointer-events-none absolute left-0 inline-block h-[10px] w-[10px] border border-gray-200 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200",
        )}
      />
    </Switch>
  );
};
