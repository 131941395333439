import React from "react";
import { Type_Servings } from "../../../typescript/types";
import { SelectListItem } from "../../molecules/select-list-item/select-list-item";

export const ServingsPerMealItem: React.FC<{
  list?: Type_Servings[];
  handleClickItem: (item: Type_Servings) => void;
  selectedItems: number;
}> = ({ handleClickItem, selectedItems, list }) => {
  return (
    <div className="mt-[31px] overflow-y-auto h-full pb-[230px]">
      {list &&
        list.map((item) => {
          return (
            <div key={item.id} onClick={() => handleClickItem(item)}>
              <SelectListItem label={item.name} selected={selectedItems === item.id} subHeading={item.description} isServing />
            </div>
          );
        })}
    </div>
  );
};
