import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Button } from "components/atoms/button/button";
import { ArrowLeftCircle } from "components/icons/arrow-left-circle/arrow-left-circle";
import { CloseButton } from "components/icons/close-button/close-button";
import { AddSearchFilterControl } from "components/molecules/add-search-filter-control/add-search-filter-control";
import { SearchInput } from "components/molecules/search-input/search-input";
import { AddDishCategoryScroll } from "components/organisms/add-dish-category-scroll/add-dish-category-scroll";
import { Alert } from "components/organisms/alert/alert";
import { Filters } from "components/organisms/filters/filters";
import { PageHeader } from "components/organisms/page-header/page-header";
import { Tags } from "components/organisms/tags/tags";
import { PageTemplate } from "components/templates/page-template/page-template";
import { GET_FILTERS, GET_MOSIAC_MEALS_THROUGH_FILTERS, GET_SEARCH_MEALS } from "graphql/queries";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { dropDish, pickDish } from "reducers/planDinnerSlice";
import { setLoading } from "reducers/uiSlice";
import { useAppDispatch, useAppSelector } from "store";
import { TagCategory, TODO, Type_AddDish_Dish, Type_StaticFilters, Type_UserDetails } from "typescript/types";
import { shuffleArray } from "utils/shuffleArray";

export const GET_DISHES = gql`
  query meal($where: meal_bool_exp) {
    meal(where: $where) {
      image_url
      name
      id
    }
  }
`;

export const AddDishes: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const searchInputRef = useRef<any>();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedStaticFilters, setSelectedStaticFilters] = useState<string[]>([]);
  const [tags, setTags] = useState<TagCategory[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchAndFilterObject, setSearchAndFilterObject] = useState({
    isSearchVisible: false,
    areFiltersVisible: false,
    areTagsVisible: false,
    searchValue: "",
    filterResult: [],
    searchResult: [],
  });
  const [userDetails, setUserDetails] = useState<Type_UserDetails>({
    userId: -1,
    householdId: -1,
    userType: "",
  });
  const [StaticFilters, setStaticFilters] = useState<Type_StaticFilters>([]);

  const selectedWeekRange = useAppSelector((state) => state.planDinner.selectedWeekRange);
  const DragDropCollection = useAppSelector((state) => state.planDinner.DragDropCollection);

  const [favouriteMeals, setFavouriteMeals] = useState<Type_AddDish_Dish[]>([]);
  const [helperCanMake, setHelperCanMake] = useState<Type_AddDish_Dish[]>([]);
  const [italianMeals, setItalianMeals] = useState<Type_AddDish_Dish[]>([]);
  const [asianDishes, setAsianDishes] = useState<Type_AddDish_Dish[]>([]);

  const [searchMeals] = useLazyQuery(GET_SEARCH_MEALS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setSearchAndFilterObject({
        ...searchAndFilterObject,
        searchValue: "",
      });
      if (data?.meal?.length > 0) {
        setIsDataLoading(false);
        let mealsArr: any = [];
        let myMeals: any = [];
        let _favouriteMeals: number[] = [];
        let userId: any;
        let userType: any;
        let householdId: any;
        setTimeout(function () {
          userId = localStorage.getItem("user_Id");
          userType = localStorage.getItem("user_type");
          householdId = localStorage.getItem("household_id");
          if (userType == "EMPLOYER") {
            _favouriteMeals = [];
            data?.meal?.forEach((meal: TODO) => {
              if (meal?.employer_favourites?.length > 0) {
                meal?.employer_favourites?.map((item: { employer?: any; id: number; meal_id: number }) => {
                  if (item?.employer?.id == parseInt(userId)) {
                    _favouriteMeals.push(meal.id);
                  }
                });
              }
            });
          } else if (userType == "HELPER") {
            _favouriteMeals = [];
            data?.meal?.forEach((meal: TODO) => {
              if (meal?.helper_can_makes?.length > 0) {
                meal?.helper_can_makes?.map((item: { helper?: any; id: number; meal_id: number }) => {
                  if (item?.helper?.id == parseInt(userId)) {
                    _favouriteMeals.push(meal.id);
                  }
                });
              }
            });
          }
          data?.meal?.length > 0 &&
            data?.meal?.map((dish: any) => {
              if (dish?.custom_meal == true) {
                if (dish?.household_id == householdId) {
                  mealsArr.push(dish);
                  myMeals.push(dish?.id);
                }
              } else {
                if (dish?.custom_meal == null) {
                  mealsArr.push(dish);
                }
              }
            });
          setSearchAndFilterObject({
            ...searchAndFilterObject,
            searchResult: mealsArr,
          });
          if (mealsArr.length == 0) {
            setAlert(true);
            setMessage("No results found!");
          }
        }, 200);
      } else {
        setIsDataLoading(false);
        setSearchAndFilterObject({
          ...searchAndFilterObject,
          searchResult: [],
        });
        setAlert(true);
        setMessage("No results found!");
      }
    },
  });

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("user_Id") && localStorage.getItem("user_Id");
    const householdId = localStorage.getItem("household_id") && localStorage.getItem("household_id");
    const userType: any = localStorage.getItem("user_type") && localStorage.getItem("user_type");

    if (userId && householdId && userType) {
      setUserDetails({
        userId: parseInt(userId),
        householdId: parseInt(householdId),
        userType,
      });

      const _StaticFilters = [
        {
          id: "Favourites",
          label: userDetails.userType === "EMPLOYER" ? "My Favourites" : "Employer Favourites",
        },
        {
          id: "Helper",
          label: userDetails.userType === "EMPLOYER" ? "Helper Can Make" : "I Can Make",
        },
        {
          id: "Custom",
          label: "My Custom Dishes",
        },
      ];
      setStaticFilters(_StaticFilters);
    }
  }, []);

  useEffect(() => {
    runMealCategoryQueries();  
  }, [userDetails])

  const [getFavourites] = useLazyQuery(GET_DISHES, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      const shuffled = shuffleArray(data?.meal);
      setFavouriteMeals(shuffled);
      setIsDataLoading(false);
    },
  });

  const [getItalian] = useLazyQuery(GET_DISHES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const shuffled = shuffleArray(data?.meal);
      setItalianMeals(shuffled);
      setIsDataLoading(false);
    },
  });

  const [getHelperCanMake] = useLazyQuery(GET_DISHES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const shuffled = shuffleArray(data?.meal);
      setHelperCanMake(shuffled);
      setIsDataLoading(false);
    },
  });

  const [getAsian] = useLazyQuery(GET_DISHES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const shuffled = shuffleArray(data?.meal);
      setAsianDishes(shuffled);
      setIsDataLoading(false);
    },
  });

  const [getFilters] = useLazyQuery(GET_FILTERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let _tags: TagCategory[] = [];
      _tags = data.filter_tags.map((tagObject: any, index: number) => {
        return {
          category: tagObject?.category_tag?.tag,
          tags: tagObject?.category_tag?.filter_tags.map((tag: any) => tag.tags),
        };
      });
      setTags(_tags);
      setIsDataLoading(false);
    },
  });

  const [getFilteredMeals] = useLazyQuery(GET_MOSIAC_MEALS_THROUGH_FILTERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const filtererResult = data.meal_filters.map((meals: any) => {
        return { ...meals.meal };
      });
      setSearchAndFilterObject({
        ...searchAndFilterObject,
        filterResult: filtererResult,
        areFiltersVisible: false,
        areTagsVisible: true,
        isSearchVisible: searchAndFilterObject.searchResult.length > 0 ? true : false,
      });
      setIsDataLoading(false);
      filtererResult?.length == 0 && setAlert(true);
      filtererResult?.length == 0 && setMessage("No results found!");
    },
  });

  const runMealCategoryQueries = () => {
    setIsDataLoading(true);
    userDetails.userId !== -1 &&
      userDetails.userType === "EMPLOYER" &&
      getFavourites({
        variables: {
          where: {
            "show": {"_eq": true },
            employer_favourites: {
              employer_id: {
                _eq: userDetails.userId,
              },
            },
          },
        },
      });
    getItalian({
      variables: {
        where: {
          "show": {"_eq": true },
          meal_filters: {
            filter_tag: {
              tags: {
                _eq: "Italian",
              },
            },
          },
        },
      },
    });
    getAsian({
      variables: {
        where: {
          "show": {"_eq": true },
          // meal_filters: {
          //   filter_tag: {
          //     tags: {
          //       _eq: "Asian",
          //     },
          //   },
          // },
        },
      },
    });
    userDetails.userId !== -1 &&
      userDetails.userType === "HELPER" &&
      getHelperCanMake({
        variables: {
          where: {
            "show": {"_eq": true },
            helper_can_makes: {
              helper_id: {
                _eq: userDetails.userId,
              },
            },
          },
        },
      });
  };

  const handleOnAddDish: (dishToAdd: Type_AddDish_Dish) => void = (valueToAdd) => {
    dispatch(pickDish(valueToAdd));
  };
  const handleRemoveDish = (id: number) => {
    dispatch(dropDish(id));
  };
  const goToDinnerPlan = () => {
    dispatch(setLoading(true));
    history.push(`/plan-Dinner?date=${selectedWeekRange.date}`);
  };

  const handleDishClick = (mealId: number) => history.push(`/dishDetail?mealId=${mealId}`);

  const onChangeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void = (e) =>
    setSearchAndFilterObject({ ...searchAndFilterObject, searchValue: e.target.value });

  const onClickSearchIcon: () => void = () => {
    search();
  };

  const onPressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void = (e) => {
    if (e.key === "Enter") {
      search();
    }
  };

  const search = () => {
    setIsDataLoading(true);
    searchMeals({
      variables: {
        where: {
          name: { _ilike: `%${searchAndFilterObject.searchValue}%` },
        },
      },
    });
  };

  const onClickSingleTag: (tagName: string) => void = (tagName) => {
    if (!selectedTags.includes(tagName)) {
      const _selectedTags = [...selectedTags, tagName];
      setSelectedTags(_selectedTags);
    } else {
      const filteredSelectedTags = selectedTags.filter((tag) => tag !== tagName);
      setSelectedTags(filteredSelectedTags);
    }
  };

  const handleOnClickStaticFilter: (filter: string) => void = (filter) => {
    if (!selectedStaticFilters.includes(filter)) {
      const _selectedStaticFilters = [...selectedStaticFilters, filter];
      setSelectedStaticFilters(_selectedStaticFilters);
    } else {
      const filteredSelectedStaticFilters = selectedStaticFilters.filter((fil) => fil !== filter);
      setSelectedStaticFilters(filteredSelectedStaticFilters);
    }
  };

  const onClickApplyFilter: () => void = () => {
    setSearchAndFilterObject({
      ...searchAndFilterObject,
      filterResult: [],
    });
    setIsDataLoading(true);
    getFilteredMeals({
      variables: {
        where: {
          _or: selectedTags.map((tag) => {
            return { _or: [{ filter_tag: { tags: { _ilike: `%${tag}%` } } }] };
          }),
        },
      },
    });
  };

  const onClickTag: (tagName: string) => void = (tagName) => {
    setIsDataLoading(true);
    const filteredSelectedTags = selectedTags.filter((tag) => tag !== tagName);
    setSelectedTags(filteredSelectedTags);
    if (selectedTags.includes(tagName) && selectedTags.length >= 2) {
      const filteredSelectedTags = selectedTags.filter((tag) => tag !== tagName);
      setSelectedTags(filteredSelectedTags);
      getFilteredMeals({
        variables: {
          where: {
            _or: filteredSelectedTags.map((tag) => {
              return {
                _or: [{ filter_tag: { tags: { _ilike: `%${tag}%` } } }],
              };
            }),
          },
        },
      });
    } else {
      setSearchAndFilterObject({
        ...searchAndFilterObject,
        areTagsVisible: false,
        filterResult: [],
      });
      setIsDataLoading(false);
      // runMealCategoryQueries();
    }
  };

  useEffect(() => {
    searchInputRef?.current?.focus();
  }, [searchAndFilterObject.isSearchVisible]);

  return (
    <PageTemplate>
      <div className="flex w-full items-center">
        {!searchAndFilterObject.areFiltersVisible && (
          <div className="mr-[13px]" onClick={goToDinnerPlan}>
            <ArrowLeftCircle />
          </div>
        )}
        {isDataLoading && (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        )}
        <PageHeader pageHeading={`${searchAndFilterObject.areFiltersVisible ? "Filters" : "Add Dishes"}`}>
          {!searchAndFilterObject.areFiltersVisible ? (
            <AddSearchFilterControl
              onClickCustomDish={() => history.push("/addCustomDish")}
              onClickFilterButton={() => {
                setIsDataLoading(true);
                getFilters();
                setSearchAndFilterObject({
                  ...searchAndFilterObject,
                  filterResult: [],
                  areFiltersVisible: !searchAndFilterObject.areFiltersVisible,
                  isSearchVisible: false,
                  areTagsVisible: false,
                });
              }}
              onClickSearchButton={() =>
                setSearchAndFilterObject({
                  ...searchAndFilterObject,
                  searchValue: "",
                  searchResult: [],
                  isSearchVisible: !searchAndFilterObject.isSearchVisible,
                })
              }
            />
          ) : (
            <div
              onClick={() => {
                setSearchAndFilterObject({
                  ...searchAndFilterObject,
                  areFiltersVisible: false,
                  isSearchVisible: searchAndFilterObject.searchResult.length > 0 ? true : false,
                  areTagsVisible: true,
                });
              }}
            >
              <CloseButton />
            </div>
          )}
        </PageHeader>
      </div>

      {searchAndFilterObject.isSearchVisible && (
        <div className="mt-[21px]">
          <SearchInput
            {...{ onChangeSearchValue, onClickSearchIcon, onPressEnter }}
            searchValue={searchAndFilterObject.searchValue}
            searchInputRef={searchInputRef}
          />
        </div>
      )}

      {selectedTags.length > 0 && searchAndFilterObject.areTagsVisible && (
        <div className="mt-[15px]">
          <Tags {...{ selectedTags, onClickTag }} />
        </div>
      )}

      {searchAndFilterObject.isSearchVisible && !isDataLoading && searchAndFilterObject.searchResult.length > 0 && (
        <AddDishCategoryScroll
          data={searchAndFilterObject.searchResult}
          heading="Search Result"
          {...{ handleOnAddDish, handleRemoveDish, handleDishClick }}
          dishCollection={DragDropCollection.dishes}
        />
      )}

      {searchAndFilterObject.areFiltersVisible && (
        <Filters
          {...{
            tags,
            onClickSingleTag,
            onClickApplyFilter,
            selectedTags,
            handleOnClickStaticFilter,
            selectedStaticFilters,
            userDetails,
            StaticFilters,
          }}
          isOnAddDishes
        />
      )}

      {searchAndFilterObject.areTagsVisible && !isDataLoading && searchAndFilterObject.filterResult.length > 0 && (
        <AddDishCategoryScroll
          data={searchAndFilterObject.filterResult}
          heading="Matches"
          {...{ handleOnAddDish, handleRemoveDish, handleDishClick }}
          dishCollection={DragDropCollection.dishes}
        />
      )}

      {favouriteMeals.length > 0 && !isDataLoading && (
        <AddDishCategoryScroll
          data={favouriteMeals}
          heading="Favourites"
          {...{ handleOnAddDish, handleRemoveDish, handleDishClick }}
          dishCollection={DragDropCollection.dishes}
        />
      )}
      {helperCanMake.length > 0 && !isDataLoading && (
        <AddDishCategoryScroll
          data={helperCanMake}
          heading="Helper Can Make"
          {...{ handleOnAddDish, handleRemoveDish, handleDishClick }}
          dishCollection={DragDropCollection.dishes}
        />
      )}
      {italianMeals.length > 0 && !isDataLoading && (
        <AddDishCategoryScroll
          data={italianMeals}
          heading="Italian"
          {...{ handleOnAddDish, handleRemoveDish, handleDishClick }}
          dishCollection={DragDropCollection.dishes}
        />
      )}
      {asianDishes.length > 0 && !isDataLoading && (
        <AddDishCategoryScroll
          data={asianDishes}
          heading="Asian"
          {...{ handleOnAddDish, handleRemoveDish, handleDishClick }}
          dishCollection={DragDropCollection.dishes}
        />
      )}
      {/* <AddDishCategoryScroll {...favouriteMeals} />
      <AddDishCategoryScroll {...favouriteMeals} />
      <AddDishCategoryScroll {...favouriteMeals} /> */}
      {DragDropCollection.dishes.length > 0 && !searchAndFilterObject.areFiltersVisible && (
        <Button
          label={`Add ${
            DragDropCollection.rows[0].taskIds.length <= 1
              ? "Dish"
              : `${DragDropCollection.rows[0].taskIds.length} Dishes`
          }`}
          onClick={goToDinnerPlan}
          className={`h-[46px] bottom-[88px] left-[50%]  ${
            DragDropCollection.rows[0].taskIds.length > 1 ? "ml-[-76px]" : "ml-[-60px]"
          }`}
        />
      )}
      {alert && <Alert message={message} setAlert={setAlert} buttonText="Ok" />}
    </PageTemplate>
  );
};
