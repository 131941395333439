import { AddDishes } from "pages/add-dishes/add-dishes";
import { ChooseAccountType } from "pages/choose-account-type/choose-account-type";
import { CreateAccount } from "pages/create-account/create-account";
import { DishTinder } from "pages/dish-tinder/dish-tinder";
import { Helper } from "pages/helper/add-helper.tsx";
import { HelperListing } from "pages/helper/helper-list";
import { Login } from "pages/login/login";
import { MyDiet } from "pages/my-diet/my-diet";
import { MyFoodAllergies } from "pages/my-food-allergies/my-food-allergies";
import { MyServingsPerMeal } from "pages/my-servings-per-meal/my-servings-per-meal";
import { PlanDinner } from "pages/plan-dinner/plan-dinner";
import { Preferences } from "pages/preferences/preferences";
import { Profile } from "pages/profile/profile";
import { ShoppingListIngredients } from "pages/shopping-list/shopping-list-ingredients";
import { ShoppingList } from "pages/shopping-list/shopping-list-main-page";
import { AccountUpdate } from "pages/update-account/update-account";
import { AddCustomDish } from "../pages/add-custom-dish/add-custom-dish";
import { CustomDishDetail } from "../pages/custom-dish-detail/custom-dish-detail";
import { Diet } from "../pages/diet/diet";
import { DishDetail } from "../pages/dish-detail/dish-detail";
import { FoodAllergies } from "../pages/food-allergies/food-allergies";
import { MealIdeas } from "../pages/meal-ideas/meal-ideas";
import { ServingsPerMeal } from "../pages/servings-per-meal/servings-per-meal";
import { RouteConfigProps } from "../typescript/types";


export const routes: RouteConfigProps[] = [
  {
    path: '/',
    exact: true,
    component: Login,
    protected: false
  },
  {
    path: '/accountType',
    exact: true,
    component: ChooseAccountType,
    protected: false
  },
  {
    path: '/createAccount',
    exact: true,
    component: CreateAccount,
    protected: false
  },
  {
    path: '/diet',
    exact: true,
    component: Diet,
    protected: true
  },
  {
    path: '/my-diet',
    exact: true,
    component: MyDiet,
    protected: true
  },
  {
    path: '/allergies',
    exact: true,
    component: FoodAllergies,
    protected: true
  },
  {
    path: '/my-allergies',
    exact: true,
    component: MyFoodAllergies,
    protected: true
  },
  {
    path: '/servingsPerMeal',
    exact: true,
    component: ServingsPerMeal,
    protected: true
  },
  {
    path: '/my-servings-per-meal',
    exact: true,
    component: MyServingsPerMeal,
    protected: true
  },
  {
    path: '/dishDetail',
    exact: true,
    component: DishDetail,
    protected: true
  },
  {
    path: '/customDishDetail',
    exact: true,
    component: CustomDishDetail,
    protected: true
  },
  {
    path: '/meal-ideas',
    exact: true,
    component: MealIdeas,
    protected: true
  },
  {
    path: '/addCustomDish',
    exact: true,
    component: AddCustomDish,
    protected: true
  },
  {
    path: '/plan-dinner',
    exact: true,
    component: PlanDinner,
    protected: true
  },
  {
    path: '/add-dishes',
    exact: true,
    component: AddDishes,
    protected: true
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
    protected: true
  },
  {
    path: '/account',
    exact: true,
    component: AccountUpdate,
    protected: true
  },
  {
    path: '/preferences',
    exact: true,
    component: Preferences,
    protected: true
  },
  {
    path: '/addHelper',
    exact: true,
    component: Helper,
    protected: true
  },
  {
    path: '/helpers',
    exact: true,
    component: HelperListing,
    protected: true
  },

  {
    path: '/shopping-list/generate',
    exact: true,
    component: ShoppingList,
    protected: true
  },
  {
    path: '/shopping-list',
    exact: true,
    component: ShoppingListIngredients,
    protected: true
  },
  {
    path: '/dish-tinder',
    exact: true,
    component: DishTinder,
    protected: true
  },
]
