import { useLazyQuery } from "@apollo/client/react";
import { Button } from "components/atoms/button/button";
import { Input } from "components/atoms/input/input";
import { SEND_USER_INVITATION } from "graphql/queries";
import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "reducers/uiSlice";
import { useHistory } from "react-router-dom";
import { EmailRegex, nameRegex } from "utils/constants";

export const HelperForm: React.FC = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
  });
  const [householdID, setHouseholdID] = useState(0);
  const [userId, setUserId] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sendInvitation, { data, error }] = useLazyQuery(SEND_USER_INVITATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch(setLoader({status: 'success', message: "Invitation Sent!"}));
      history.push("/helpers");
      setLoading(false);
    }
  });
  const handleFormValues: (event: React.ChangeEvent<HTMLInputElement>) => void = (e) =>
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

    useEffect(() => {
      const token: any = localStorage.getItem('token');
      const user_id: any = localStorage.getItem('user_Id');
      user_id && setUserId(parseInt(user_id))
      const householdID: any = localStorage.getItem('household_id')
      householdID && setHouseholdID(parseInt(householdID));
    },[])

  const addHelper = () => {
    setLoading(true);
    if (!nameRegex.test(formValues.name)) {
      dispatch(setLoader({ status: "error", message: "Invalid Name!" }));
      setLoading(false);
      return;
    }

    if (!EmailRegex.test(formValues.email)) {
      dispatch(setLoader({ status: "error", message: "Incorrect email!" }));
      setLoading(false);
      return;
    }
    sendInvitation({
      variables: {
        Object: {
          household_id: householdID,
          user_email: formValues.email,
          user_id: userId,
          user_type: 'HELPER',
          url: 'https://app.menulyapp.com'
        }
      }
    })
  }

  useEffect(() => {
    error && dispatch(setLoader({status: 'error', message: "Incorrect fields."}));
    error && setLoading(false);
  }, [error])

  return (
    <form className="mt-[35px]">
      <Input
        label="Name"
        onChangeValue={handleFormValues}
        value={formValues.name}
        name="name"
        labelIsWhite={false}
      />
      <Input
        label="Email"
        onChangeValue={handleFormValues}
        value={formValues.email}
        name="email"
        labelIsWhite={false}
      />
      <div className={`${loading && 'pointer-events-none'}`}>
        <Button
          loading={loading}
          onClick={() => addHelper()} label="Add Helper"
          className="bottom-[88px] h-[46px] left-[50%] ml-[-76.5px]" />
      </div>
    </form>
  );
};
