import { Divider } from "components/atoms/divider/divider";
import { LogoMurray } from "components/atoms/logo/logo_murray";
import { LogoHeading } from "components/atoms/logo_heading";
import { ProfileOptionsList } from "components/molecules/profile/profile-options-list";
import { PageTemplate } from "components/templates/page-template/page-template";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uniqueNamesGenerator, Config, adjectives, colors, animals } from 'unique-names-generator';
import { setLoader } from "reducers/uiSlice";
import { useAppSelector } from "store";
import { Notification } from "components/molecules/loader/loader";

export const Profile: React.FC = () => {
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const config: Config = {
    dictionaries: [adjectives]
  }

  const characterName: string = uniqueNamesGenerator(config); // red

  useEffect(() => {
    if(loader?.status){
      setLoading(true);
      setTimeout(() =>{
        dispatch(setLoader({status: '', message: ""}))
      }, 2000)
    } else {
      setLoading(false);
    }
  }, [loader]);

  return (
    <div>
      <PageTemplate>
      {isLoading && <Notification status={loader.status} message={loader.message}/>}
        <div className="flex justify-center mb-[31px] mt-[17px]">
          <LogoMurray />
        </div>
        <div className="flex justify-center mb-[31px] mt-[17px]">
          <LogoHeading heading={localStorage.getItem('household_name') ?? ''} />
        </div>
        <div>
          <Divider className="mt-[21px]" />
          <ProfileOptionsList />
          <Divider className="" />
        </div>
      </PageTemplate>
    </div>
  );
};
