import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MosiacMeal, TagCategory, Type_FilterResultBooleans } from "typescript/types";

type Type_InitialState = {
  favouriteMeals: any;
  mosaicMeals: MosiacMeal[];
  showSearch: boolean;
  showFilters: boolean;
  myMeal: number[];
  tags: TagCategory[];
  selectedTags: string[];
  showTags: boolean;
  offset: number;
  totalMosiacMeals: number;
  searchValue: string;
  selectedStaticFilters: string[];
  filterResultBooleans: Type_FilterResultBooleans;
};

const initialState: Type_InitialState = {
  favouriteMeals: [],
  mosaicMeals: [],
  showSearch: false,
  showFilters: false,
  myMeal: [],
  tags: [],
  selectedTags: [],
  showTags: false,
  offset: 0,
  totalMosiacMeals: 0,
  searchValue: "",
  selectedStaticFilters: [],
  filterResultBooleans: {
    favourites: false,
    helper: false,
    custom: false,
    dynamicFilters: false,
  },
};

const MealsSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    addToFavourites(state, { payload }) {
      state.favouriteMeals = [...state.favouriteMeals, payload];
    },
    removeFromFavourites(state, { payload }) {
      state.favouriteMeals = state.favouriteMeals.filter((item: number) => {
        return item !== payload;
      });
    },
    setFavourites(state, { payload }) {
      state.favouriteMeals = payload;
    },
    setSearchValue(state, { payload }: PayloadAction<string>) {
      state.searchValue = payload;
    },
    setMyMeal(state, { payload }: PayloadAction<any>) {
      state.myMeal = payload;
    },
    setMosaicMeals(state, { payload }: PayloadAction<Array<any>>) {
      state.mosaicMeals = payload;
    },
    setTotalMosiacMeals(state, { payload }: PayloadAction<number>) {
      state.totalMosiacMeals = payload;
    },
    setTags(state, { payload }: PayloadAction<TagCategory[]>) {
      state.tags = payload;
    },
    setShowFilters(state, { payload }: PayloadAction<boolean>) {
      state.showFilters = payload;
    },
    setShowSearch(state, { payload }: PayloadAction<boolean>) {
      state.showSearch = payload;
    },
    setShowTags(state, { payload }: PayloadAction<boolean>) {
      state.showTags = payload;
    },
    setFilterResultBooleans(state, { payload }: PayloadAction<Type_FilterResultBooleans>) {
      state.filterResultBooleans = payload;
    },
    setSelectedStaticFilters(state, { payload }: PayloadAction<string[]>) {
      state.selectedStaticFilters = payload;
    },
    setOffset(state, { payload }: PayloadAction<number>) {
      state.offset = payload;
    },
    setSelectedTags(state, { payload }: PayloadAction<string[]>) {
      state.selectedTags = payload;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  addToFavourites,
  removeFromFavourites,
  setFavourites,
  setSearchValue,
  setMyMeal,
  setMosaicMeals,
  setTotalMosiacMeals,
  setTags,
  setShowFilters,
  setShowSearch,
  setShowTags,
  setFilterResultBooleans,
  setSelectedStaticFilters,
  setOffset,
  setSelectedTags,
  resetState
} = MealsSlice.actions;
export default MealsSlice.reducer;
