import React from "react";

export const AddDishesCategoryHeading: React.FC<{heading: string}> = ({heading}) => {
  return (
    <div className="flex justify-between items-center mb-[18px]">
      <p className="text-primary text-[16px] leading-[18px] font-semibold">{heading}</p>
      <p className="text-[#EE4E4E] text-[12px] leading-[18px] font-semibold cursor-pointer">See All</p>
    </div>
  );
};
