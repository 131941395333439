import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Notification } from "components/molecules/loader/loader";
import { Alert } from "components/organisms/alert/alert";
import React, { useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addToFavourites,
  removeFromFavourites,
  setFavourites,
  setSearchValue,
  setMyMeal,
  setMosaicMeals,
  setTotalMosiacMeals,
  setTags,
  setShowFilters,
  setShowSearch,
  setShowTags,
  setFilterResultBooleans,
  setSelectedStaticFilters,
  setOffset,
  setSelectedTags,
} from "reducers/mealsSlice";
import { setLoader } from "reducers/uiSlice";
import { useAppSelector } from "store";
import { CloseButton } from "../../components/icons/close-button/close-button";
import { AddSearchFilterControl } from "../../components/molecules/add-search-filter-control/add-search-filter-control";
import { SearchInput } from "../../components/molecules/search-input/search-input";
import { Filters } from "../../components/organisms/filters/filters";
import { Mosaic } from "../../components/organisms/mosaic/mosaic";
import { PageHeader } from "../../components/organisms/page-header/page-header";
import { Tags } from "../../components/organisms/tags/tags";
import { PageTemplate } from "../../components/templates/page-template/page-template";
import {
  ADD_FAVOURITE_DISH_BY_EMPLOYER_ID,
  DELETE_EMPLOYER_FAVOURITE,
  DELETE_HELPER_CAN_MAKE,
  INSERT_HELPER_CAN_MAKE,
} from "../../graphql/mutation";
import {
  GET_EMPLOYERS_FAVOURITES,
  GET_FILTERS,
  GET_HELPERS,
  GET_HELPER_CAN_MAKE,
  GET_MOSIAC_MEALS_BY_USERID,
  GET_MOSIAC_MEALS_THROUGH_FILTERS,
  GET_SEARCH_MEALS,
  GET_TOTAL_MOSIAC_MEALS_BY_USER_ID,
} from "../../graphql/queries";
import { Enum_FavouriteControl } from "../../typescript/enum";
import {
  MosiacMeal,
  TagCategory,
  TODO,
  Type_StaticFilters,
  Type_UserDetails,
} from "../../typescript/types";
import { MealIdeasContextProvider } from "./meal-context";

const LIMIT = 10;
const OFFSET = 10;

export const MealIdeas: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchInputRef = useRef<any>();
  const [slide, setSlide] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [favouriteMeals, setFavouriteMeals] = useState<number[]>([]);
  const isMounted = useRef(false);
  const loader = useAppSelector((state) => state.ui.loader);
  const {
    mosaicMeals,
    showSearch,
    showFilters,
    myMeal,
    tags,
    selectedTags,
    showTags,
    offset,
    totalMosiacMeals,
    searchValue,
    selectedStaticFilters,
    filterResultBooleans,
  } = useAppSelector((state) => ({
    mosaicMeals: state.meals.mosaicMeals,
    showSearch: state.meals.showSearch,
    showFilters: state.meals.showFilters,
    myMeal: state.meals.myMeal,
    tags: state.meals.tags,
    selectedTags: state.meals.selectedTags,
    showTags: state.meals.showTags,
    offset: state.meals.offset,
    totalMosiacMeals: state.meals.totalMosiacMeals,
    searchValue: state.meals.searchValue,
    selectedStaticFilters: state.meals.selectedStaticFilters,
    filterResultBooleans: state.meals.filterResultBooleans,
  }));
  const [insertHelperCanMake] = useMutation(INSERT_HELPER_CAN_MAKE);
  const [deleteEmployerFavourite] = useMutation(DELETE_EMPLOYER_FAVOURITE);
  const [deleteHelperCanMake] = useMutation(DELETE_HELPER_CAN_MAKE);
  const [message, setMessage] = useState("");
  const favMeals = useAppSelector((state) => state.meals.favouriteMeals);
  const [userDetails, setUserDetails] = useState<Type_UserDetails>({
    userId: -1,
    householdId: -1,
    userType: "",
  });
  const [StaticFilters, setStaticFilters] = useState<Type_StaticFilters>([]);
  // const [filterResultBooleans, setFilterResultBooleans] = useState({
  //   favourites: false,
  //   helper: false,
  //   custom: false,
  //   dynamicFilters: false,
  // });

  useEffect(() => {
    const userId =
      localStorage.getItem("user_Id") && localStorage.getItem("user_Id");
    const householdId =
      localStorage.getItem("household_id") &&
      localStorage.getItem("household_id");
    const userType: any =
      localStorage.getItem("user_type") && localStorage.getItem("user_type");

    if (userId && householdId && userType) {
      setUserDetails({
        userId: parseInt(userId),
        householdId: parseInt(householdId),
        userType,
      });

      const StaticFiltersEmployer = [
        {
          id: "Favourites",
          label: "My Favourites",
        },
        {
          id: "Helper",
          label: "Helper Can Make",
        },
        {
          id: "Custom",
          label: "My Custom Dishes",
        },
      ];
      const StaticFiltersHelper = [
        {
          id: "Helper",
          label: "I Can Make",
        },
        {
          id: "Favourites",
          label: "Employer Favourites",
        },
      ];
      setStaticFilters(
        userType === "EMPLOYER" ? StaticFiltersEmployer : StaticFiltersHelper
      );
    }
  }, []);

  const [searchMeals] = useLazyQuery(GET_SEARCH_MEALS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      // dispatch(setSearchValue(""));
      if (data?.meal?.length > 0) {
        setIsDataLoading(false);
        let mealsArr: any = [];
        let myMeals: any = [];
        let _favouriteMeals: number[] = favMeals;
        let userId: any;
        let userType: any;
        let householdId: any;
        setTimeout(function () {
          userId = localStorage.getItem("user_Id");
          userType = localStorage.getItem("user_type");
          householdId = localStorage.getItem("household_id");
          if (userType == "EMPLOYER") {
            _favouriteMeals = [];
            data?.meal?.forEach((meal: TODO) => {
              if (meal?.employer_favourites?.length > 0) {
                meal?.employer_favourites?.map(
                  (item: { employer?: any; id: number; meal_id: number }) => {
                    if (item?.employer?.id == parseInt(userId)) {
                      _favouriteMeals.push(meal.id);
                    }
                  }
                );
              }
            });
          } else if (userType == "HELPER") {
            _favouriteMeals = [];
            data?.meal?.forEach((meal: TODO) => {
              if (meal?.helper_can_makes?.length > 0) {
                meal?.helper_can_makes?.map(
                  (item: { helper?: any; id: number; meal_id: number }) => {
                    if (item?.helper?.id == parseInt(userId)) {
                      _favouriteMeals.push(meal.id);
                    }
                  }
                );
              }
            });
          }
          data?.meal?.length > 0 &&
            data?.meal?.map((dish: any) => {
              if (dish?.custom_meal == true) {
                if (dish?.household_id == householdId) {
                  mealsArr.push(dish);
                  myMeals.push(dish?.id);
                }
              } else {
                if (dish?.custom_meal == null) {
                  mealsArr.push(dish);
                }
              }
            });
          dispatch(setMyMeal(myMeals));
          dispatch(setMosaicMeals(mealsArr));
          if (mealsArr.length == 0) {
            setAlert(true);
            setMessage("No results found!");
          }
        }, 200);
      } else {
        setIsDataLoading(false);
        dispatch(setMosaicMeals([]));
        setAlert(true);
        setMessage("No results found!");
      }
    },
  });
  const [getMosaicMealsByUserID] = useLazyQuery(GET_MOSIAC_MEALS_BY_USERID, {
    fetchPolicy: "no-cache",
    variables: {
      limit: LIMIT,
      offset: offset,
      where: {
        show: { _eq: true },
      },
    },
    onCompleted: (data) => {
      let mealsArr: any = [];
      let myMeals: any = [];
      let _favouriteMeals: number[] = favMeals;
      let userId: any;
      let userType: any;
      let householdId: any;
      if (data?.meal?.length > 0) {
        setIsDataLoading(false);
        setTimeout(function () {
          userId = localStorage.getItem("user_Id");
          userType = localStorage.getItem("user_type");
          householdId = localStorage.getItem("household_id");

          if (userType == "EMPLOYER") {
            _favouriteMeals = [];
            data?.meal?.forEach((meal: TODO) => {
              if (meal?.employer_favourites?.length > 0) {
                meal?.employer_favourites?.map(
                  (item: { employer?: any; id: number; meal_id: number }) => {
                    if (item?.employer?.id == parseInt(userId)) {
                      _favouriteMeals.push(meal.id);
                      offset !== 0 && dispatch(addToFavourites(meal.id));
                    }
                  }
                );
              }
            });
          } else if (userType == "HELPER") {
            _favouriteMeals = [];
            data?.meal?.forEach((meal: TODO) => {
              if (meal?.helper_can_makes?.length > 0) {
                meal?.helper_can_makes?.map(
                  (item: { helper?: any; id: number; meal_id: number }) => {
                    if (item?.helper?.id == parseInt(userId)) {
                      _favouriteMeals.push(meal.id);
                      offset !== 0 && dispatch(addToFavourites(meal.id));
                    }
                  }
                );
              }
            });
          }
          data?.meal?.length > 0 &&
            data?.meal?.map((dish: any) => {
              if (dish?.custom_meal == true) {
                if (dish?.household_id == householdId) {
                  mealsArr.push(dish);
                  myMeals.push(dish?.id);
                }
              } else {
                if (dish?.custom_meal == null) {
                  mealsArr.push(dish);
                }
              }
            });
          if (offset == 0) {
            dispatch(setFavourites(_favouriteMeals));
          }
          setMyMeal(myMeals);
          setFavouriteMeals(_favouriteMeals);
          const _mosaicMeals = mosaicMeals;
          const concatMosaicMeals = _mosaicMeals.concat(mealsArr);
          dispatch(setMosaicMeals(concatMosaicMeals));
        }, 200);
      } else {
        setIsDataLoading(false);
        // dispatch(setMosaicMeals([]));
        if (offset == 0) {
          setAlert(true);
          setMessage("No results found!");
        }
      }
    },
  });

  useEffect(() => {
    if (loader?.status) {
      setLoading(true);
      setTimeout(() => {
        dispatch(setLoader({ status: "", message: "" }));
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [loader]);

  useEffect(() => {
    if (searchValue === "" && showSearch) {
      dispatch(setMosaicMeals([]));
      setIsDataLoading(true);
      getMosaicMealsByUserID({
        variables: {
          where: {
             "show": {"_eq": true }
          },
          limit: LIMIT,
          offset: 0,
        },
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (!showSearch && mosaicMeals.length === 0) {
      getMosaicMealsByUserID({
        variables: {
          where: {
             "show": {"_eq": true }
          },
          limit: LIMIT,
          offset: 0,
        },
      });
    } else setIsDataLoading(false);
  }, []);

  console.log({ selectedStaticFilters });

  useEffect(() => {
    if (isMounted.current) {
      // I know the logic is stupid. Don't @ me!
      const booleans: any = selectedStaticFilters.map((staticFilter) => {
        if (
          (staticFilter === "My Favourites" ||
            staticFilter === "Employer Favourites") &&
          filterResultBooleans.favourites
        )
          return true;
        if (
          (staticFilter === "Helper Can Make" ||
            staticFilter === "I Can Make") &&
          filterResultBooleans.helper
        )
          return true;
        if (staticFilter === "My Custom Dishes" && filterResultBooleans.custom)
          return true;
      });
      if (selectedTags.length > 0 && filterResultBooleans.dynamicFilters) {
        booleans?.push(true);
      }
      if (
        booleans.every((bools: boolean) => bools === true) &&
        mosaicMeals.length <= 0
      ) {
        setAlert(true);
        dispatch(setSelectedTags([]));
        dispatch(setSelectedStaticFilters([]));
      }
    } else {
      isMounted.current = true;
    }
  }, [filterResultBooleans]);

  useEffect(() => {
    if (alert == true) {
      getMosaicMealsByUserID({
        variables: {
          where: {
             "show": {"_eq": true }
          },
          limit: LIMIT,
          offset: 0,
        },
      });
    }
  }, [alert]);

  useQuery(GET_TOTAL_MOSIAC_MEALS_BY_USER_ID, {
    onCompleted: (data) => {
      dispatch(setTotalMosiacMeals(data.meal_aggregate.aggregate.count));
    },
  });

  const [getFilters] = useLazyQuery(GET_FILTERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let _tags: TagCategory[] = [];
      _tags = data.filter_tags.map((tagObject: any, index: number) => {
        return {
          category: tagObject?.category_tag?.tag,
          tags: tagObject?.category_tag?.filter_tags.map(
            (tag: any) => tag.tags
          ),
        };
      });
      dispatch(setTags(_tags));
    },
  });

  const [getFilteredMeals] = useLazyQuery(GET_MOSIAC_MEALS_THROUGH_FILTERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _mosaicMeals = data.meal_filters.map((meals: any) => {
        return { ...meals.meal };
      });
      dispatch(setMosaicMeals([..._mosaicMeals, ...mosaicMeals]));
      dispatch(setShowFilters(false));
      dispatch(setShowSearch(false));
      dispatch(setShowTags(true));
      setIsDataLoading(false);
      _mosaicMeals?.length == 0 &&
        dispatch(
          setFilterResultBooleans({
            ...filterResultBooleans,
            dynamicFilters: true,
          })
        );
      _mosaicMeals?.length == 0 && setMessage("No results found!");
      // _mosaicMeals?.length == 0 && dispatch(setShowTags(false));

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onError: () => {
      setIsDataLoading(false);
    },
  });

  const [getHelpers] = useLazyQuery(GET_HELPERS, {
    fetchPolicy: "no-cache",
    onError: () => {
      setIsDataLoading(false);
    },
    onCompleted: (data) => {
      if (!data) return;

      const _householdUsers = data?.household_employer_helper?.map(
        (household: any) => household?.user_id
      );

      // For Favourites
      (selectedStaticFilters.includes("Employer Favourites") ||
        selectedStaticFilters.includes("My Favourites")) &&
        getEmployerFavourites({
          variables: {
            where:
              userDetails.userType === "EMPLOYER"
                ? {
                    employer_id: {
                      _eq: userDetails.userId,
                    },
                  }
                : {
                    employer_id: {
                      _in: _householdUsers,
                    },
                  },
          },
        });

      // For Helpers
      (selectedStaticFilters.includes("Helper Can Make") ||
        selectedStaticFilters.includes("I Can Make")) &&
        getHelperCanMake({
          variables: {
            where:
              userDetails.userType === "EMPLOYER"
                ? {
                    helper_id: {
                      _in: _householdUsers,
                    },
                  }
                : {
                    helper_id: {
                      _eq: userDetails.userId,
                    },
                  },
          },
        });

      // Custom Dishes for Employer
      selectedStaticFilters.includes(
        StaticFilters[2] && StaticFilters[2].label
      ) &&
        userDetails.userType === "EMPLOYER" &&
        getCustomMeals({
          variables: {
            where: {
              custom_meal: {
                _eq: true,
              },
              household_id: {
                _eq: userDetails.householdId,
              },
            },
          },
        });
    },
  });

  const [getCustomMeals] = useLazyQuery(GET_SEARCH_MEALS, {
    fetchPolicy: "no-cache",
    onError: () => {
      setIsDataLoading(false);
    },
    onCompleted: (data) => {
      if (!data) return;
      const _mosaicMeals = data?.meal?.map((meal: any) => {
        return { ...meal };
      });
      dispatch(setMosaicMeals([..._mosaicMeals, ...mosaicMeals]));
      dispatch(setShowFilters(false));
      dispatch(setShowSearch(false));
      dispatch(setShowTags(true));
      setIsDataLoading(false);
      if (_mosaicMeals?.length == 0) {
        // const filteredSelectedStaticFilters = selectedStaticFilters.filter(
        //   (filter) => filter !== StaticFilters[StaticFilters.findIndex((filter) => filter.id === "Custom")].label,
        // );
        // dispatch(setSelectedStaticFilters(filteredSelectedStaticFilters));
        // selectedStaticFilters.includes("My Custom Dishes") &&
        dispatch(
          setFilterResultBooleans({
            ...filterResultBooleans,
            custom: true,
          })
        );
        setMessage("No results found!");
        // dispatch(setShowTags(false));
      }
    },
  });

  const [getHelperCanMake] = useLazyQuery(GET_HELPER_CAN_MAKE, {
    fetchPolicy: "no-cache",
    onError: () => {
      setIsDataLoading(false);
    },
    onCompleted: (data) => {
      if (!data) return;
      const _mosaicMeals = data?.helper_can_make?.map((meals: any) => {
        return { ...meals.meal };
      });
      dispatch(setMosaicMeals([..._mosaicMeals, ...mosaicMeals]));
      dispatch(setShowFilters(false));
      dispatch(setShowSearch(false));
      dispatch(setShowTags(true));
      setIsDataLoading(false);
      if (_mosaicMeals?.length == 0) {
        // const filteredSelectedStaticFilters = selectedStaticFilters.filter(
        //   (filter) => filter !== StaticFilters[StaticFilters.findIndex((filter) => filter.id === "Helper")].label,
        // );

        // dispatch(setSelectedStaticFilters(filteredSelectedStaticFilters));
        dispatch(
          setFilterResultBooleans({
            ...filterResultBooleans,
            helper: true,
          })
        );
        setMessage("No results found!");
        // dispatch(setShowTags(false));
      }
    },
  });

  const [getEmployerFavourites] = useLazyQuery(GET_EMPLOYERS_FAVOURITES, {
    fetchPolicy: "no-cache",
    onError: () => {
      setIsDataLoading(false);
    },
    onCompleted: (data) => {
      if (!data) return;
      const _mosaicMeals = data?.employer_favourite?.map((meals: any) => {
        return { ...meals.meal };
      });
      dispatch(setMosaicMeals([...mosaicMeals, ..._mosaicMeals]));
      dispatch(setShowFilters(false));
      dispatch(setShowSearch(false));
      dispatch(setShowTags(true));
      setIsDataLoading(false);
      if (_mosaicMeals?.length == 0) {
        // const filteredSelectedStaticFilters = selectedStaticFilters.filter(
        //   (filter) => filter !== StaticFilters[StaticFilters.findIndex((filter) => filter.id === "Favourites")].label,
        // );
        // dispatch(setSelectedStaticFilters(filteredSelectedStaticFilters));
        dispatch(
          setFilterResultBooleans({
            ...filterResultBooleans,
            favourites: true,
          })
        );
        setMessage("No results found!");
        // dispatch(setShowTags(false));
      }
    },
  });

  // console.log({ filterResultBooleans });

  const [addToFavouriteDish] = useMutation(ADD_FAVOURITE_DISH_BY_EMPLOYER_ID);
  // const [removeFromFavouriteDish] = useMutation(REMOVE_FAVOURITE_DISH_BY_EMPLOYER_ID);

  const pagination = () => {
    const _offset = offset + OFFSET;
    dispatch(setOffset(_offset));
    !(
      selectedStaticFilters.length > 0 ||
      selectedTags.length > 0 ||
      showSearch === true
    ) &&
      getMosaicMealsByUserID({
        variables: {
          where: {
             "show": {"_eq": true }
          },
          limit: LIMIT,
          offset: _offset,
        },
      });
  };

  const onClickCustomDish = () => history.push(`/addCustomDish`);

  const onClickFilterButton = () => {
    getFilters();
    // dispatch(setSelectedStaticFilters([]))
    // dispatch(setSelectedTags([]));
    // dispatch(setMosaicMeals([]))
    setSlide(false);
    dispatch(setShowFilters(!showFilters));
    dispatch(setShowSearch(false));
    dispatch(setShowTags(false));
  };

  const onClickSearchButton = () => {
    dispatch(setShowSearch(!showSearch));
    dispatch(setShowFilters(false));
    setSlide(!slide);
  };
  useEffect(() => {
    searchInputRef?.current?.focus();
  }, [showSearch]);

  const onClickCloseButton = () => {
    dispatch(setShowFilters(false));
    dispatch(setShowSearch(false));
    dispatch(setShowTags(true));
  };

  const onClickSingleTag: (tagName: string) => void = (tagName) => {
    if (!selectedTags.includes(tagName)) {
      const _selectedTags = [...selectedTags, tagName];
      dispatch(setSelectedTags(_selectedTags));
    } else {
      const filteredSelectedTags = selectedTags.filter(
        (tag) => tag !== tagName
      );
      dispatch(setSelectedTags(filteredSelectedTags));
    }
  };

  const handleOnClickStaticFilter: (filter: string) => void = (filter) => {
    if (!selectedStaticFilters.includes(filter)) {
      const _selectedStaticFilters = [...selectedStaticFilters, filter];
      dispatch(setSelectedStaticFilters(_selectedStaticFilters));
    } else {
      const filteredSelectedStaticFilters = selectedStaticFilters.filter(
        (fil) => fil !== filter
      );
      dispatch(setSelectedStaticFilters(filteredSelectedStaticFilters));
    }
  };

  const onClickApplyFilter: () => void = () => {
    dispatch(setMosaicMeals([]));
    setIsDataLoading(true);
    selectedTags.length > 0 &&
      getFilteredMeals({
        variables: {
          where: {
            _or: selectedTags.map((tag) => {
              return {
                _or: [{ filter_tag: { tags: { _ilike: `%${tag}%` } } }],
              };
            }),
          },
        },
      });

    selectedStaticFilters.length > 0 &&
      getHelpers({
        variables: {
          where: {
            household_id: { _eq: userDetails.householdId },
            user_type: {
              _eq: userDetails.userType === "EMPLOYER" ? "HELPER" : "EMPLOYER",
            },
          },
        },
      });
  };

  //TODO: Optimize
  // No need to filter both static and dynamic filters
  // deleted tag can be only of one type
  const onClickTag: (tagName: string) => void = (tagName) => {
    setIsDataLoading(true);
    if (
      (selectedTags.includes(tagName) ||
        selectedStaticFilters.includes(tagName)) &&
      selectedStaticFilters.length + selectedTags.length >= 2
    ) {
      const filteredSelectedTags = selectedTags.filter(
        (tag) => tag !== tagName
      );
      const filteredSelectedStaticFilters = selectedStaticFilters.filter(
        (filter) => filter !== tagName
      );
      dispatch(setSelectedTags(filteredSelectedTags));
      dispatch(setSelectedStaticFilters(filteredSelectedStaticFilters));
      dispatch(setMosaicMeals([]));

      getFilteredMeals({
        variables: {
          where: {
            _or: filteredSelectedTags.map((tag) => {
              return {
                _or: [{ filter_tag: { tags: { _ilike: `%${tag}%` } } }],
              };
            }),
          },
        },
      });
      getHelpers({
        variables: {
          where: {
            household_id: { _eq: userDetails.householdId },
            user_type: {
              _eq: userDetails.userType === "EMPLOYER" ? "HELPER" : "EMPLOYER",
            },
          },
        },
      });
    } else {
      dispatch(setMosaicMeals([]));
      dispatch(setSelectedTags([]));
      dispatch(setSelectedStaticFilters([]));
      getMosaicMealsByUserID({
        variables: {
          where: {
             "show": {"_eq": true }
          },
          limit: LIMIT,
          offset: 0,
        },
      });
    }
  };

  const onChangeSearchValue: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (e) => {
    dispatch(setSearchValue(e.target.value));
  };

  const search = () => {
    setIsDataLoading(true);
    searchMeals({
      variables: {
        where: {
          show: { _eq: true },
          name: { _ilike: `%${searchValue}%` },
        },
      },
    });
  };

  //search icon IN search input
  //NOT the header one
  const onClickSearchIcon = () => {
    dispatch(setMosaicMeals([]));
    setMyMeal([]);
    search();
  };

  const onPressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void = (
    e
  ) => {
    if (e.key === "Enter") {
      dispatch(setMosaicMeals([]));
      setMyMeal([]);
      search();
    }
  };

  const onClickMeal: (mealId: number, isCustom: boolean) => void = (
    mealId,
    isCustom = false
  ) => {
    if (isCustom) {
      history.push(`/customDishDetail?mealId=${mealId}`);
    } else {
      history.push(`/dishDetail?mealId=${mealId}`);
    }
  };

  const _addToFavouriteDish = (mealId: number) => {
    dispatch(addToFavourites(mealId));
    const _favouriteMeals = Array.from(favouriteMeals);
    const withFavouriteDishAdded = [..._favouriteMeals, mealId];
    setFavouriteMeals(withFavouriteDishAdded);
    let userId: any = localStorage.getItem("user_Id");
    if (localStorage.getItem("user_type") == "EMPLOYER") {
      addToFavouriteDish({
        variables: {
          objects: {
            employer_id: parseInt(userId),
            meal_id: mealId,
          },
        },
      })
        .then(() => {})
        .catch(() => {
          setAlert(true);
          setMessage("Oops! Something went wrong.");
        });
    } else if (localStorage.getItem("user_type") == "HELPER") {
      insertHelperCanMake({
        variables: {
          objects: {
            helper_id: parseInt(userId),
            meal_id: mealId,
          },
        },
      }).catch(() => {
        setAlert(true);
        setMessage("Oops! Something went wrong.");
      });
    }
  };

  const _removeFromFavouriteDish = (mealId: number) => {
    const _favouriteMeals = Array.from(favouriteMeals);
    const filteredMeals = _favouriteMeals.filter((dishId) => dishId !== mealId);
    setFavouriteMeals(filteredMeals);
    dispatch(removeFromFavourites(mealId));
    if (localStorage.getItem("user_type") == "EMPLOYER") {
      deleteEmployerFavourite({
        variables: {
          where: {
            meal_id: { _eq: mealId },
          },
        },
      })
        .then(() => {})
        .catch(() => {
          setAlert(true);
          setMessage("Oops! Something went wrong.");
        });
    } else if (localStorage.getItem("user_type") == "HELPER") {
      deleteHelperCanMake({
        variables: {
          where: {
            meal_id: { _eq: mealId },
          },
        },
      })
        .then(() => {})
        .catch(() => {
          setAlert(true);
          setMessage("Oops! Something went wrong.");
        });
    }
  };

  const onClickFavourite: (
    mealId: number,
    favourite: Enum_FavouriteControl
  ) => void = (mealId, control) => {
    // maintain local state with ids of favourite meals
    // to remove lag when user favourites or unfavourites meal
    // TODO: Add variable employer id
    if (control === Enum_FavouriteControl.Add) {
      _addToFavouriteDish(mealId);
    } else if (control === Enum_FavouriteControl.Remove) {
      _removeFromFavouriteDish(mealId);
    }
  };

  const ContextValue = {
    onClickFilterButton,
    onClickSingleTag,
    onClickApplyFilter,
    onClickTag,
    onClickCustomDish,
  };
  useEffect(() => {
    if (offset !== 0) {
      setIsDataLoading(true);
    }
  }, [offset]);

  return (
    <PageTemplate>
      {isLoading && (
        <Notification status={loader.status} message={loader.message} />
      )}
      <MealIdeasContextProvider value={ContextValue}>
        <div className="mb-[10px]">
          {isDataLoading && (
            <>
              {" "}
              {offset == 0 ? (
                <div className="flex justify-center fixed top-[50%] left-[50%] ">
                  <Loader
                    type="TailSpin"
                    color="#2F0842"
                    height={20}
                    width={20}
                  />
                </div>
              ) : (
                <div className="flex justify-center fixed bottom-[15%] left-[50%] ">
                  <Loader
                    type="TailSpin"
                    color="#2F0842"
                    height={20}
                    width={20}
                  />
                </div>
              )}{" "}
            </>
          )}
          <PageHeader pageHeading={showFilters ? "Filters" : "Meal Ideas"}>
            {showFilters ? (
              <div onClick={onClickCloseButton} className="cursor-pointer">
                <CloseButton />
              </div>
            ) : (
              <AddSearchFilterControl
                {...{
                  onClickCustomDish,
                  onClickFilterButton,
                  onClickSearchButton,
                }}
              />
            )}
          </PageHeader>
        </div>
        {showSearch && (
          <div className="mt-[21px]">
            <SearchInput
              {...{
                onClickSearchButton,
                onChangeSearchValue,
                onClickSearchIcon,
                searchValue,
                onPressEnter,
                searchInputRef,
              }}
            />
          </div>
        )}
        {selectedTags.length + selectedStaticFilters.length > 0 && showTags && (
          <div className="mt-[15px]">
            <Tags
              {...{ onClickTag }}
              selectedTags={[...selectedTags, ...selectedStaticFilters]}
            />
          </div>
        )}
        {showFilters && (
          <Filters
            {...{
              tags,
              onClickSingleTag,
              selectedTags,
              onClickApplyFilter,
              selectedStaticFilters,
              handleOnClickStaticFilter,
              StaticFilters,
            }}
          />
        )}
        {!showFilters && (
          <div className="mt-[17px]">
            <Mosaic
              totalMosiacMeals={totalMosiacMeals}
              pagination={pagination}
              mosaic={mosaicMeals}
              onClickMeal={onClickMeal}
              onClickFavourite={onClickFavourite}
              favouriteMeals={favMeals}
            />
          </div>
        )}

        {alert && (
          <Alert message={message} buttonText="OK" setAlert={setAlert} />
        )}
      </MealIdeasContextProvider>
    </PageTemplate>
  );
};
