import { Logo } from "components/atoms/logo/logo";
import { UserPreferencesHeading } from "components/atoms/user-preferences-heading/user-preferences-heading";
import { Notification } from "components/molecules/loader/loader";
import { LoginForm } from "components/organisms/login-form/login-form";
import { PageContainer } from "components/templates/user-preference-template/user-preference-template";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoader } from "reducers/uiSlice";
import { useAppSelector } from "store";

export const Login: React.FC = () => {
  const loader = useAppSelector((state) => state.ui.loader);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(loader?.status){
      setLoading(true);
      setTimeout(() =>{
        dispatch(setLoader({status: '', message: ""}))
      }, 2000)
    } else {
      setLoading(false);
    }
  }, [loader]);

  return (
    <PageContainer>
        {isLoading && <Notification status={loader.status} message={loader.message}/>}
        <div className="flex justify-center mb-[31px] mt-[17px]">
          <Logo />
        </div>
        <UserPreferencesHeading heading="Sign in to your Account" />
        <LoginForm />
        <p className="text-[12px] leading-[22px] font-medium text-white text-center mt-[36px]">
          Don't have an account?{" "}
          <span className="text-[#F39443] cursor-pointer" onClick={() => history.push(`/createAccount`)}>
            Sign Up
          </span>
        </p>
    </PageContainer>
  );
};
