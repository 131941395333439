import React from "react";
import { Navigation } from "../../organisms/navigation/navigation";
import menullyLogoTransparent from '../../../assets/images/menuly-dark-logo.svg';

export const ShoppingListPageTemplate: React.FC = ({ children }) => {
  return (
    <div className="mx-[18px] mt-[23px] pb-[120px]">
      <div className={`flex -z-100 justify-center items-center fixed top-[30%] left-[13%] mx-auto rounded-md h-[230px]`}>
      <img src={menullyLogoTransparent} alt="" className="w-full h-[285px]" />
    </div>
      {children}
      <Navigation />
    </div>
  );
};
