import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Router from "./router/router";
import { routes } from "./router/routeConfig";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import store from "./store";
import { Headers, Uri } from "config/config";
import 'normalize.css';

const client = new ApolloClient({
  uri: Uri,
  cache: new InMemoryCache(),
  headers: Headers
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router routes={routes} />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
