import React from 'react'
import { Recipe } from '../../../typescript/types'
import { RecipeItem } from '../../atoms/recipe-item/recipe-item'
export const RecipeList:React.FC<{recipes: Recipe[]}> = ({recipes}) => {
  return (
    <ul>
      {recipes.map(recipe => <RecipeItem recipe={recipe} key={recipe.id} />)}
    </ul>
  )
}
