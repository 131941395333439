import { CloseIconWhiteFilled } from "components/icons/close-icon-white-filled/close-icon-white-filled";
import React from "react";
import { Type_AddDish_Dish } from "typescript/types";
import { Draggable } from "react-beautiful-dnd";
import { AddWhiteFilled } from "components/icons/add-white-filled/add-white-filled";
import { useAppSelector } from "store";

type PlanDinnerDishProps = {
  dish: any;
  removeDishFromCollection: (id: number) => void;
  addToShoppingMeals: (id: number) => void;
  index: number;
};

export const PlannedMeal: React.FC<PlanDinnerDishProps> = ({
  dish,
  removeDishFromCollection,
  addToShoppingMeals,
  index,
}) => {
  const excludedMeals = useAppSelector(
    (state) => state.shoppingList.excludedMeals
  );

  return (
    <div className="w-[92px] h-[72px]">
      <div
        className={`w-[92px] h-[72px] relative overflow-hidden rounded-[6px]`}
      >
        <img
          src={dish?.meal?.image_url}
          alt=""
          style={{ objectFit: "cover" }}
          className={`${
            excludedMeals?.includes(dish?.id) && "opacity-50"
          } rounded-[6px] w-[92px] h-[72px]`}
        />
        <div
          className="absolute top-[4px] right-[4px] "
          onClick={() => {
            if (excludedMeals?.includes(dish?.id)) {
              addToShoppingMeals(dish?.id);
            } else {
              removeDishFromCollection(dish?.id);
            }
          }}
        >
          {excludedMeals?.includes(dish?.id) ? (
            <AddWhiteFilled />
          ) : (
            <CloseIconWhiteFilled />
          )}
        </div>
      </div>
      <p
        className={`text-[10px] leading-[13px] font-medium mt-[6px] truncate ${
          excludedMeals?.includes(dish?.id) ? "text-gray-300" : "text-fontGray"
        }`}
      >
        {dish?.meal?.name}
      </p>
    </div>
  );
};
