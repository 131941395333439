import { CheckActiveFilled } from "components/icons/check-active-filled/check-active-filled";
import React from "react";
import checkInactive from "../../../assets/icons/check-inactive.svg";
import CheckInactiveFilter from "assets/icons/check-inactive-filter.svg";
interface LoaderProps {
  status: string;
  message: string;
}
export const Notification: React.FC<LoaderProps> = ({ status, message }) => {
  return (
    <div
      className={`flex items-center fixed  top-0 h-[55px] pt-[16px] w-full left-0 right-0 px-[30px] pt-[11px] pb-[6px] text-white text-[9px] leading-[18px]`}
      style={{ backgroundColor: status == "success" ? "rgb(62,	197,	130)" : status == "error" ? "#EE4E4E" :"", zIndex: 100000 }}
    >
      <div className="py-2">
        {status == "success" ? (
          <img src={CheckInactiveFilter} alt="" className="w-[25px] h-[25px] cursor-pointer" />
        ) : (
          <> </>
        )}
      </div>
      <p className="font-light text-sm px-[16px] text-left py-2 ">{message}</p>
    </div>
  );
};
