import React from 'react';
import { useHistory } from 'react-router-dom';

interface AlertProps {
  alertStatus?: string;
  message: string;
  buttonText: string;
  setAlert?: any;
  redirect?: string;
}

export const Alert: React.FC<AlertProps> = ({ alertStatus, message, buttonText, setAlert, redirect }) => {
  const history = useHistory()
  return (
    <div className="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white w-[77%] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div>
        {alertStatus == "success" && <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>}
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-text-[#2F0842]" id="modal-title">
            Alert
          </h3>
          <div className="mt-2">
            <p className="text-sm text-[#2F0842]">
              {message}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          onClick={() => {
            if (redirect) {
              history.push(`${redirect}`)
            }
            setAlert(false)
          }}
          type="button" className={`inline-flex justify-center w-full rounded-full border border-transparent shadow-sm bg-white px-4 py-2  text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm ${alertStatus == 'success' ? 'border-[#35C680] text-[#35C680]': 'border-[#EE4E4E] text-[#EE4E4E]'}`}>
          {buttonText}
        </button>
      </div>
    </div>
  </div>
</div>
  )
}
