import { SideText } from "components/atoms/shopping-list/ingredients-side-text";
import { CheckActiveFilled } from "components/icons/check-active-filled/check-active-filled";
import React, { useEffect } from "react";
import { useAppSelector } from "store";
import { CheckInactive } from "../../icons/check-inactive/check-inactive";
import { IngredientName } from "./ingredient-name";

export const ShoppingIngredientsRow: React.FC<{ content: any, addToBoughtIngredients: (name: string, id: number) => void, removeFromBoughtIngredients: (name: string, id: number) => void }> = ({
  content,
  addToBoughtIngredients,
  removeFromBoughtIngredients
}) => {
  const boughtIngredients = useAppSelector((state) => state.shoppingList.boughtIngredients);
  useEffect(() => {
    console.log("bougght", boughtIngredients)
  },[boughtIngredients])
  return (
    <>
      <div className="flex justify-between items-center">
        <div
          className="flex w-full justify-between"
          // onClick={() => handleOnClickDayName(id)}
        >
          {" "}
          <div className="flex space-x-4 justify-center">
            {" "}
            {boughtIngredients?.includes(content?.id) ? (
              <div onClick={() => removeFromBoughtIngredients(content?.name, content?.id)}>
                <CheckActiveFilled />
              </div>
            ) : (
              <div onClick={() => addToBoughtIngredients(content?.name, content?.id)}>
                <CheckInactive />
              </div>
            )}
            <IngredientName item={content?.name} bought={boughtIngredients?.includes(content?.id) ?? false}/>
          </div>
          <SideText text={content?.quantity+ ' '+content?.unit} bought={boughtIngredients?.includes(content?.id) ?? false} />
        </div>
      </div>
    </>
  );
};
