import { CloseButton } from "components/icons/close-button/close-button";
import React from "react";
import { CrossIcon } from "react-select/src/components/indicators";
import clearImg from "../../../assets/icons/clear.png";
import shareImg from "../../../assets/icons/share.png";
import groceryImg from "../../../assets/icons/grocery-primary.svg";
import { useHistory } from "react-router-dom";

type Props = {
  closeDialog: () => void
  clearList:() => void
  handleShare: () => Promise<void>
};

export const PlanDinnerBottomAlert: React.FC<Props> = ({closeDialog, clearList, handleShare}) => {
  const history = useHistory()
  return (
    <div className="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className={`flex fixed bottom-[62px] h-[200px] py-[20px] w-full left-0 rounded-t-lg z-100 right-0 px-[30px] text-white text-[9px] leading-[18px]`}
          style={{ backgroundColor: "#FFF" }}
        >
          <div className="absolute right-[17px] cursor-pointer" onClick={closeDialog}>
            <CloseButton />
          </div>
          <div className="mt-[37px]  text-[14px] leading-[18px]">
            <div className="flex align-left space-x-6 items-center cursor-pointer" onClick={handleShare}>
              <img src={shareImg} />
              <p className="font-semibold text-primary ">Share Dinner Plan</p>
            </div>
            <div
              className="flex align-left  space-x-6 items-center my-[24px] cursor-pointer"
              onClick={() => history.push(`/shopping-list`)}
            >
              <img src={groceryImg} />
              <p className="font-semibold text-primary ">Add to Shopping List</p>
            </div>
            <div className="flex align-left  space-x-6  items-center cursor-pointer" onClick={clearList}>
              <img src={clearImg} />
              <p className="font-semibold text-primary ">Clear List</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
