import React from "react";
import { useAppSelector } from "store";
import { Type_AddDish_Dish } from "typescript/types";
import { WhitePlusIcon } from "../../icons/white-plus-icon/white-plus-icon";

export const AddToDinnerPlanButton: React.FC<{handleOnAddDish: (dishToAdd: Type_AddDish_Dish) => void}> = ({handleOnAddDish}) => {
  const singleDish = useAppSelector((state) => state.planDinner.singleDish);
  const DragDropCollection = useAppSelector((state) => state.planDinner.DragDropCollection);
  return (
    <div
      onClick={() => handleOnAddDish(singleDish)}
      className="bg-accent flex w-[218px] h-[46px] justify-center items-center  rounded-[30px] fixed left-[50%] ml-[-109px] bottom-[96px] cursor-pointer"
      style={{ boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)" }}
    >
      <WhitePlusIcon />
      <p className="ml-[9.8px] text-[14px] leading-[16px] tracking-[0.01rem] text-white">{`${DragDropCollection.dishes.includes(singleDish) ? 'Added' : 'Add to Dinner Plan'}`}</p>
    </div>
  );
};
