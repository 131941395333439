import { BackArrow } from "components/icons/back-arrow-primary/back-arrow-primary";
import { UserPreferencesHeading } from "components/atoms/update-account/user-preferences-heading";
import React, { useEffect, useState } from "react";
import { HelperForm } from "components/molecules/helper/helper-form";
import { TextBelowTitle } from "components/atoms/text-below-title/text-below-title";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { setLoader } from "reducers/uiSlice";
import { Notification } from '../../components/molecules/loader/loader';
import { PageTemplate } from "components/templates/page-template/page-template";

export const Helper: React.FC = () => {
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if(loader?.status){
      setLoading(true);
      setTimeout(() =>{
        dispatch(setLoader({status: '', message: ""}))
      }, 1000)
    } else {
      setLoading(false);
    }
  }, [loader]);
  return (
    <PageTemplate>
      <div className="flex">
        <BackArrow />
        <UserPreferencesHeading heading="Your Helper" />
        {isLoading && <Notification status={loader.status} message={loader.message}/>}
      </div>
      <TextBelowTitle text={'You have not yet added a Helper. Simple add them below and we’ll send an invite.'}/>
      <div>
        <HelperForm />
      </div>
      </PageTemplate>
  );
};
