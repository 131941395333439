import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Enum_Navigation } from "typescript/enum";

type Type_InitialState = {
  navigation: Enum_Navigation,
  loader: any,
  loading: boolean
}

const initialState: Type_InitialState = {
  navigation: Enum_Navigation.MealIdeas,
  loader: {},
  loading: false
}

const UISlice = createSlice({
  name : "ui",
  initialState,
  reducers: {
    setNavigation(state, {payload}) {
      state.navigation = payload
    },
    setLoader(state, {payload}) {
      state.loader = payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    }
  }
});

export const {setNavigation, setLoader, setLoading} = UISlice.actions
export default UISlice.reducer
