import { InstructionRow } from "components/molecules/instruction-row/instruction-row";
import React from "react";
import { Type_Instruction } from "typescript/types";

type InstructionsListProps = {
  instructions: Type_Instruction[];
};

export const InstructionsList: React.FC<InstructionsListProps> = ({ instructions }) => {
  return (
    <ul>
      {instructions.map((instruction) => (
        <InstructionRow key={instruction.id} instruction={instruction.name} />
      ))}
    </ul>
  );
};
