import { AddGreenFilled } from "components/icons/add-green-filled/add-green-filled";
import { AddWhiteFilled } from "components/icons/add-white-filled/add-white-filled";
import { CloseIconWhiteFilled } from "components/icons/close-icon-white-filled/close-icon-white-filled";
import React from "react";
import { Type_AddDish_Dish } from "typescript/types";

type AddDishesCategoryDishProps = {
  handleOnAddDish: (dishToAdd: Type_AddDish_Dish) => void;
  handleRemoveDish: (id: number) => void;
  addedToCollection: boolean;
  handleDishClick: (mealId: number) => void;
};

export const AddDishesCategoryDish: React.FC<Type_AddDish_Dish & AddDishesCategoryDishProps> = ({
  id,
  image_url,
  name,
  custom_meal = false,
  addedToCollection,
  handleOnAddDish,
  handleRemoveDish,
  handleDishClick,
}) => {
  return (
    <div className="w-[145px] h-[110px] ">
      <div
        className="w-[145px] h-[110px] cursor-pointer rounded-[6px] relative"
        style={{ background: `url(${image_url})` }}
      >
        {addedToCollection ? (
          <div className="overflow-hidden rounded-[6px]">
            <div className="absolute top-[4px] right-[4px]" onClick={() => handleRemoveDish(id)}>
              {" "}
              <AddGreenFilled />{" "}
            </div>
            <img src={image_url} alt="" className=" w-full  rounded-[6px] overflow-hidden h-[110px]" style={{objectFit: "contain"}} onClick={() => handleDishClick(id)} />
          </div>
        ) : (
          <div className="overflow-hidden rounded-[6px] ">
            <div
              className="absolute top-[4px] right-[4px]"
              onClick={() =>
                handleOnAddDish({
                  name,
                  id,
                  image_url,
                  custom_meal,
                })
              }
            >
              <AddWhiteFilled />
            </div>
            <img src={image_url} alt="" className="w-full  rounded-[6px] overflow-hidden h-[110px]" style={{objectFit: "contain"}} onClick={() => handleDishClick(id)} />
          </div>
        )}
      </div>
      <p className="text-fontGray text-[10px] leading-[13px] font-medium mt-[6px] truncate">{name}</p>
    </div>
  );
};
