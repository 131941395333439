import React, { useContext } from "react";
import { MealIdeasContext } from "../../../pages/meal-ideas/meal-context";
import { FiltersIcon } from "../../icons/filters-icon/filters-icon";
import { PlusIcon } from "../../icons/plus-icon/plus-icon";
import { SearchIcon } from "../../icons/search-icon/search-icon";

type Props = {
  onClickFilterButton: () => void;
  onClickSearchButton: () => void;
  onClickCustomDish: () => void;
};

export const AddSearchFilterControl: React.FC<Props> = ({onClickCustomDish,onClickFilterButton,onClickSearchButton}) => {
  return (
    <div className="flex ">
      <div onClick={onClickCustomDish}>
        <PlusIcon className="w-[20px] h-[20px]" />
      </div>
      <div onClick={onClickSearchButton} style={{ marginLeft: 23, marginRight: 23 }}>
        <SearchIcon />
      </div>

      <div onClick={onClickFilterButton}>
        <FiltersIcon />
      </div>
    </div>
  );
};
