import { ArrowLeftCircle } from 'components/icons/arrow-left-circle/arrow-left-circle'
import { ArrowRightCircle } from 'components/icons/arrow-right-circle/arrow-right-circle'
import { WeekSelected } from 'components/atoms/week-selected/week-selected'
import React from 'react'
import { Type_WeekRange } from 'typescript/types'

type Props = {
  selectedWeekRange: Type_WeekRange & {weekNumber: number, date: string}
  goBackAWeek: () => void;
  goAWeekForward: () => void
  disableButtons: boolean
}

export const WeekSelector: React.FC<Props> = ({selectedWeekRange, goAWeekForward,goBackAWeek, disableButtons}) => {
  return (
    <div className="flex justify-center space-x-[29px] items-center">
      <div onClick={() => !disableButtons && goBackAWeek()}>
        <ArrowLeftCircle />
      </div>
      <WeekSelected {...{selectedWeekRange}} />
      <div onClick={() => !disableButtons && goAWeekForward()}>
        <ArrowRightCircle />
      </div>
    </div>
  )
}
