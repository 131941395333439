import React, { useContext } from "react";
import { MealIdeasContext } from "../../../pages/meal-ideas/meal-context";
import { Input } from "../../atoms/input/input";
import { SearchIcon } from "../../icons/search-icon/search-icon";
import MagnifierForSearch from "assets/icons/magnifier-search-filter.svg";

type Props = {
  onChangeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  onClickSearchIcon: () => void;
  onPressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  searchInputRef?: React.MutableRefObject<any>
};

export const SearchInput: React.FC<Props> = ({ onChangeSearchValue, onClickSearchIcon, onPressEnter, searchValue,searchInputRef }) => {
  return (
    <div className="relative w-full">
      <div onClick={() => onClickSearchIcon()}>
        <img
          src={MagnifierForSearch}
          className="text-[#d8d8d8] absolute top-[50%] mt-[-10px] left-[15.42px] h-[20px] w-[20px]"
        />
      </div>
      <Input
        {...{ onChangeValue: onChangeSearchValue, value: searchValue, onPressEnter  }}
        isSearch
        placeholder="Search Meal Ideas..."
        inputRef={searchInputRef}
      />
    </div>
  );
};
