import { SideArrow } from "components/icons/side-arrow/side-arrow";
import React from "react";
import { useHistory } from "react-router-dom";
import { ProfileOptionName } from "../../organisms/profile/profile-option-name";

export const ProfileOptionsList: React.FC = () => {
  const userType = localStorage.getItem('user_type');
  const history = useHistory();
  return (
    <>
      <div className="flex flex-col divide-y-2">
        <div className="py-[26px]">
          <div className="flex justify-between items-center">
          <div
              className="flex w-full justify-between"
              onClick={() => history.push('/account')}
            >
              <ProfileOptionName option={'Account'} />
              <SideArrow redirect={'/account'}/>
            </div>
          </div>
        </div>
        {userType == 'EMPLOYER' && <div className="py-[26px]">
        <div className="flex justify-between items-center">
        <div
            className="flex w-full justify-between"
            onClick={() => history.push('/preferences')}
          >
            <ProfileOptionName option={'Preferences'} />
            <SideArrow redirect={'/preferences'}/>
          </div>
        </div>
      </div>}
      <div className="py-[26px]">
      <div className="flex justify-between items-center">
      <div
          className="flex w-full justify-between"
          // onClick={() => handleOnClickDayName(id)}
        >
          <ProfileOptionName option={'Notification'} />
          <SideArrow redirect={'/meal-Ideas'}/>
        </div>
      </div>
    </div>
    <div className="py-[26px]">
      <div className="flex justify-between items-center">
        <div
            className="flex w-full justify-between"
            // onClick={() => handleOnClickDayName(id)}
          >
            <ProfileOptionName option={'About Menuly'} />
            <SideArrow redirect={'/meal-Ideas'} />
        </div>
      </div>
    </div>
    <div className="py-[26px]">
      <div className="flex justify-between items-center">
        <div
            className="flex w-full justify-between"
            // onClick={() => handleOnClickDayName(id)}
          >
            <ProfileOptionName option={'Feedback & Support'} />
            <SideArrow redirect={'/meal-Ideas'} />
          </div>
        </div>
      </div>
    </div>
  </>
  );
};
