import React from "react";
import { Recipe } from "../../../typescript/types";
import "./styles.css";
import BulletSVG from "assets/icons/bullet.svg";

export const RecipeItem: React.FC<{ recipe: Recipe }> = ({ recipe }) => {
  return (
    <>
      {recipe?.ingredient?.name && (
        <div className="flex ">
          <img src={BulletSVG} alt="" className="self-start mt-[6px] mr-[6px]" />
          <div className="flex justify-between space-x-[16px] text-[12px] leading-[17px] mb-[10px] w-full">
            <li className="text-fontGray   capitalize">{recipe?.ingredient?.name}</li>
            <p className="text-[#6f6f6f] text-right">
              {recipe?.quantity}
              <span className="ml-1">{recipe?.unit?.name}</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
