import React from "react";
import { combineClasses } from "utils/combineClasses";
import { InputLabel } from "../input-label/input-label";
import Styles from "./input.module.css";
type InputProps = {
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  placeholder?: string;
  name?: string;
  label?: string;
  isSearch?: boolean;
  labelIsWhite?: boolean;
  type?: string;
  autoComplete?: string;
  inputRef?: React.MutableRefObject<any>;
};

export const Input: React.FC<InputProps> = ({
  onChangeValue,
  onPressEnter,
  value,
  placeholder,
  name = "",
  label,
  isSearch = false,
  labelIsWhite = false,
  autoComplete = "off",
  type,
  inputRef,
}) => {
  return (
    <div className={`${isSearch ? "" : "mb-[30px]"}`}>
      {label && <InputLabel label={label} labelIsWhite={labelIsWhite} />}
      <input
        className={combineClasses(
          `text-primary font-medium text-[13px] leading-[15px]  border rounded-[30px] h-[50px] w-full ${
            isSearch ? "pl-[51px]" : "pl-[21px]"
          }  placeholder-[#6f6f6f] outline-none focus:border-primary`,
          Styles.removeInnerShadow,
        )}
        ref={inputRef}
        placeholder={placeholder}
        onChange={(e) => onChangeValue(e)}
        value={value}
        onKeyPress={(e) => onPressEnter && onPressEnter(e)}
        name={name}
        type={type}
        autoComplete={autoComplete}
      />
    </div>
  );
};
