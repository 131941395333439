import { createSlice } from "@reduxjs/toolkit";
import { TODO } from "typescript/types";

type DishTinderState = {
  meals: TODO[];
  alreadyRemoved: number[];
};

const initialState: DishTinderState = {
  meals: [],
  alreadyRemoved: [],
};

const dishTinderSlice = createSlice({
  name: "Dish Tinder",
  initialState,
  reducers: {
    setMeals(state, {payload}) {
      state.meals = payload
    },
    setAlreadyRemoved(state, {payload}) {
      state.alreadyRemoved = [...state.alreadyRemoved, payload]
    }
  }
})

export const {setAlreadyRemoved,setMeals} = dishTinderSlice.actions;
export default dishTinderSlice.reducer