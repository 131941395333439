import React, { useEffect, useState } from "react";
import { BackArrow } from "../../components/icons/back-arrow/back-arrow";
import { Button } from "../../components/atoms/button/button";
import { UserPreferencesHeading } from "../../components/atoms/user-preferences-heading/user-preferences-heading";
import { SelectListItems } from "../../components/organisms/select-list-items.tsx/select-list-items";
import { PageContainer } from "../../components/templates/user-preference-template/user-preference-template";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALLERGY_NAMES } from "../../graphql/queries";
import { Type_DietryTypes } from "../../typescript/types";
import { INSERT_EMPLOYER_ALLERGIES } from "../../graphql/mutation";
import { useHistory } from "react-router-dom";
import { Notification } from '../../components/molecules/loader/loader';
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setLoader } from "reducers/uiSlice";
import Loader from "react-loader-spinner";

export const FoodAllergies: React.FC = () => {
  const history = useHistory();
  const [allergies, setAllergies] = useState<Type_DietryTypes[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [insertAllergies, { error }] = useMutation(INSERT_EMPLOYER_ALLERGIES);
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    if(loader?.status){
      setLoading(true);
      setTimeout(() =>{
        dispatch(setLoader({status: '', message: ""}))
      }, 1000)
    } else {
      setLoading(false);
    }
  }, [loader]);

  useQuery<{ allergies: Type_DietryTypes[] }>(GET_ALLERGY_NAMES, {
    onCompleted: (data) => {
      data && console.log({ data: data.allergies });
      data && setAllergies(data.allergies);
      setIsDataLoading(false);
    },
  });



  const handleClickItem = (item: Type_DietryTypes) => {
    if (!selectedItems.includes(item.id)) setSelectedItems([...selectedItems, item.id]);
    else {
      const _selectedItems = selectedItems;
      const filteredSelectedItems = _selectedItems.filter((selectedItem) => selectedItem !== item.id);
      setSelectedItems(filteredSelectedItems);
    }
  };

  const handleContinueClick = () => {
    setTimeout(() => {
      let userId: any;
      userId = localStorage.getItem('user_Id')
      selectedItems.forEach((selectedItem) => {
        userId && insertAllergies({
          variables: {
            objects: {
              allergies_id: selectedItem,
              employer_id: parseInt(userId),
            },
          },
        });
      });
      history.push(`/servingsPerMeal`);
    }, 500)

  };

  useEffect(() => {
    error && dispatch(setLoader({status: 'error', message: "Something went wrong!"}))
    error && setIsDataLoading(false);
  }, [error])

  return (
    <>
      <PageContainer>
       {isDataLoading && (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} />
          </div>
        )}
        {isLoading && <Notification status={loader.status} message={loader.message}/>}
        <BackArrow />
        <UserPreferencesHeading heading="Does your household have any food allergies?" />
        <SelectListItems list={allergies} handleClickItem={handleClickItem} selectedItems={selectedItems} />
        <Button label="continue" onClick={handleContinueClick} hasPurpleGradient />
      </PageContainer>
      {/* <StyledBoxedShadow /> */}
    </>
  );
};
