import React from 'react'
import { useHistory } from 'react-router-dom';

export const CustomDishLabelWithEdit: React.FC<{mealId: number}> = ({ mealId }) => {
  const history = useHistory();
  return (
    <div className="flex text-primary text-[12px] leading-[18px] mt-[9px] mb-[17px]">
      <p className="font-semibold mr-3.5">Custom Dish</p>
      <p className="font-normal cursor-pointer" onClick={() => history.push(`/addCustomDish?mealId=${mealId}`)}>Edit</p>
    </div>
  )
}
