import { BackArrow } from 'components/icons/back-arrow/back-arrow'
import { UserPreferencesHeading } from 'components/atoms/user-preferences-heading/user-preferences-heading'
import { CreateAccountForm } from 'components/organisms/create-account-form/create-account-form'
import { PageContainer } from 'components/templates/user-preference-template/user-preference-template'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Notification } from '../../components/molecules/loader/loader';
import { useAppSelector } from 'store'
import { setLoader } from 'reducers/uiSlice'

export const CreateAccount: React.FC = () => {
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if(loader?.status){
      setLoading(true);
      setTimeout(() =>{
        dispatch(setLoader({status: '', message: ""}))
      }, 1000)
    } else {
      setLoading(false);
    }
  }, [loader]);
  return (
    <PageContainer>
       {isLoading && <Notification status={loader.status} message={loader.message}/>}
      <BackArrow />
      <UserPreferencesHeading heading="Create your Account" />
      <CreateAccountForm />
    </PageContainer>
  )
}
