import React from "react";
import { ClockIcon } from "../../icons/clock-icon/clock-icon";

export const CookTime: React.FC<{ time: string | number }> = ({ time }) => {
  return (
    <div className="flex space-x-[9px] items-center">
      <ClockIcon />
      <p className="text-[12px] leading-[21px] font-medium text-fontGray">Cook Time: {time}</p>
    </div>
  );
};
