import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import planDinnerReducer from "./reducers/planDinnerSlice";
import UIReducer from "./reducers/uiSlice";
import ShoppingListReducer from './reducers/shoppingListSlice';
import UserReducer from "./reducers/userSlice"
import DishTinderReducer from "./reducers/dishTinderSlice"
import MealsReducer from "./reducers/mealsSlice";
const store = configureStore({
  reducer: {
    planDinner: planDinnerReducer,
    ui: UIReducer,
    shoppingList: ShoppingListReducer,
    user: UserReducer,
    tinder: DishTinderReducer,
    meals: MealsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
