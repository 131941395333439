import React from "react";
import { combineClasses } from "utils/combineClasses";
import { InputLabel } from "../input-label/input-label";
import Styles from "./text-area.module.css"
type TextAreaProps = {
  onChangeValue: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder?: string;
  name?: string;
  label?: string;
};

export const TextArea: React.FC<TextAreaProps> = ({ onChangeValue, value, label, name, placeholder, onPressEnter }) => {
  return (
    <div className={`mb-[30px]`}>
      {label && <InputLabel label={label} />}
      <textarea
        className={combineClasses(`text-primary font-medium text-[13px] leading-[15px]  border rounded-[10px] h-[116px] w-full pl-[21px] py-[15px] placeholder-[#6f6f6f] outline-none focus:border-primary`, Styles.removeInnerShadow)}
        placeholder={placeholder}
        onChange={(e) => onChangeValue(e)}
        value={value}
        onKeyPress={(e) => onPressEnter && onPressEnter(e)}
        name={name}
      />
    </div>
  );
};
