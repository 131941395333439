import { ShoppingListMainButton } from "components/atoms/shopping-list/shopping-list-main-button";
import { TextBelowTitle } from "components/atoms/text-below-title/text-below-title";
import { Ellipses } from "components/icons/ellipses/ellipses";
import { BottomAlert } from "components/molecules/bottom-alert/bottom-alert";
import { Notification } from "components/molecules/loader/loader";
import { Alert } from "components/organisms/alert/alert";
import { PageHeader } from "components/organisms/page-header/page-header";
import { ShoppingListPageTemplate } from "components/templates/shopping-list-page-template/shopping-list-page-template";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBoughtIngredients, setExcludedMeals, setIngredientsList, setShoppingMeals } from "reducers/shoppingListSlice";
import { useAppSelector } from "store";
import { getDateOfISOWeek } from "utils/getRangeOfWeeks";

dayjs.extend(weekOfYear);
export const ShoppingList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fullStartDate, setFullStartDate] = useState("");
  const [fullEndDate, setFullEndDate] = useState("");
  const [householdID, setHouseholdID] = useState<number>();
  const loader = useAppSelector((state) => state.ui.loader);
  const [isDataLoading, setDataLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bottomAlert, setBottomAlert] = useState(false);

  useEffect(() => {
      let date = dayjs(new Date()).format("YYYY-MM-DD")
      let safari_date = date.split('-').join('/')
      const week = dayjs(safari_date).isoWeek();
      const range = getDateOfISOWeek(week, dayjs(safari_date).get("year"));
      const startDate = dayjs(range);
      const endDate = dayjs(startDate).add(6, "days");
      console.log("test", date, safari_date, week, range)
      setStartDate(startDate.format("MMM D"));
      setEndDate(endDate.format("MMM D"));
      console.log(startDate.format("YYYY-MM-DD"))
      setFullStartDate(startDate.format("YYYY-MM-DD").toString());
      setFullEndDate(endDate.format("YYYY-MM-DD").toString());
  }, []);

  const closeDialog = () => setBottomAlert(false);
  const clearList = () => {
    setDataLoading(true);
      setDataLoading(false);
      dispatch(setIngredientsList([]));
      dispatch(setBoughtIngredients([]));
      dispatch(setShoppingMeals([]));
      dispatch(setExcludedMeals([]));
      history.push(`/plan-dinner?date=${dayjs(new Date()).format("YYYY-MM-DD")}`)
  }

  useEffect(() => {
    let token: any = localStorage.getItem("household_id");
    setTimeout(() => {
      setHouseholdID(parseInt(token));
    }, 100);
  }, []);

  // const [generateList] = useLazyQuery(GET_SHOPPING_LIST, {
  //   fetchPolicy: "no-cache",
  //   onCompleted: (data) => {
  //     history.push(`/shopping-list`);
  //     setDataLoading(false);
  //   },
  // });

  // const generateShoppingList = () => {
  //   setDataLoading(true);
  //   generateList({
  //     variables: {
  //       household_id: householdID,
  //       endDate: fullEndDate,
  //       startDate: fullStartDate,
  //     },
  //   });
  // };

  return (
    <ShoppingListPageTemplate>
      {isDataLoading ? (
        <>
          <PageHeader pageHeading="Shopping List">
            <div onClick={() => setBottomAlert(true)}>
              <Ellipses />
            </div>
          </PageHeader>
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        </>
      ) : (
        <div>
          {loading && (
            <Notification status={loader.status} message={loader.message} />
          )}
          <PageHeader pageHeading="Shopping List">
            <Ellipses />
          </PageHeader>
          <TextBelowTitle text={"0 items"} />
          <ShoppingListMainButton
            label={`Add This Week’s Plan (${startDate} - ${endDate})`}
            onClick={() =>
              history.push(`/shopping-list?generate=true`)
            }
            className="bottom-[50%] h-[46px]"
          />
          {alert && (
            <Alert
              alertStatus="error"
              message={`You don't have any planned meals for this week.`}
              buttonText="Go Back"
              setAlert={setAlert}
              redirect={`/plan-dinner?date=${dayjs(new Date()).format(
                "YYYY-MM-DD"
              )}`}
            />
          )}
        </div>
      )}
        {/* {bottomAlert && <BottomAlert closeDialog={closeDialog} clearList={clearList} />} */}
    </ShoppingListPageTemplate>
  );
};
