import React from "react";
import { Navigation } from "../../organisms/navigation/navigation";

export const PageTemplate: React.FC = ({ children }) => {
  return (
    <div className="mx-[18px] mt-[23px] pb-[120px]">
      {children}
      <Navigation />
    </div>
  );
};
