import React from 'react'
import { useHistory } from 'react-router-dom'
import SideArrowPNG from "../../../assets/icons/side-arrow.png"
export const SideArrow: React.FC<{redirect: string}> = ({redirect}) => {
  const history = useHistory()
  return (
    <div onClick={() => history.push(`${redirect}`)}>
      <img src={SideArrowPNG} alt="" className="w-[6.5px] h-[13px] my-1 cursor-pointer"/>
    </div>
  )
}
