import React from "react";
import PlacholderMenulyLogo from "../../../assets/images/placeholder-menuly-logo.svg";
import { AddImageCameraIcon } from "../../icons/add-image-camera-icon/add-image-camera-icon";
import Styles from "./placeholder-image.module.css";
export const PlaceholderImage: React.FC<{ customDish?: boolean; halfWidth: boolean }> = ({ customDish, halfWidth }) => {
  return (
    <div
      className={`flex justify-center items-center relative bg-[#EAE9E9] rounded-md ${
        halfWidth ? "h-[185px]" : "h-[230px]"
      }`}
    >
      <img src={PlacholderMenulyLogo} alt="" className="w-full h-[130px] rounded-md" />
      <div className={Styles.cover}></div>
      {customDish == true && <AddImageCameraIcon />}
    </div>
  );
};
