import {createSlice, PayloadAction} from "@reduxjs/toolkit"

type Type_IsRef = {
  url: string;
  isRef: boolean;
  ref: string;
  date:string
}

type Type_InitialState = {
  householdId: number;
  shareObj: Type_IsRef
}

const initialState: Type_InitialState = {
  householdId: -1,
  shareObj: {
    isRef: false,
    url: "",
    ref: "",
    date: ""
  }
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setHouseholdId (state, action: PayloadAction<number>) {
      state.householdId = action.payload
    },
    setUrl (state, {payload}: PayloadAction<Type_IsRef>) {
      state.shareObj = payload
    },
    removeUrl (state)  {
      state.shareObj = initialState.shareObj
    }
  }
})

export const {setHouseholdId, setUrl, removeUrl} = userSlice.actions;
export default userSlice.reducer