import React from "react";
import { useHistory } from "react-router-dom";

type Props = {
  Icon: string; label: string; selected: boolean; to: string;
}

export const NavIcon: React.FC<Props> = ({ Icon, label, selected, to }) => {
  const history = useHistory()
  return (
    <div className="flex flex-col justify-center items-center cursor-pointer" onClick={()=>history.push(to)}>
      <img src={Icon} alt="" className="w-[25px] h-[25px] mb-[1.89px]" />
      <p className={`text-[9px] leading-[18px] text-center font-semibold ${selected ? "text-red" : "text-white"}`}>{label}</p>
    </div>
  );
};
