import React, { useEffect, useRef, useState } from 'react';

/**
 * Make an element scrollable by dragging
 * @param buttons - Buttons user can drag with. See https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
 * @returns {React.MutableRefObject} -  The ref to be applied to to the element to make it draggable
 */
export default function useDraggable(buttons = [1, 4, 5]) {
  const ref = useRef<any>();
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);

  useEffect(() => {
    function handleDown(e: any) {
      if (!ref.current.contains(e.target)) {
        return;
      }
      setStartX(e.pageX - ref.current.offsetLeft);
      setStartY(e.pageY - ref.current.offsetTop);
      setStartScrollLeft(ref.current.scrollLeft);
      setStartScrollTop(ref.current.scrollTop);
    }
    function handleMove(e: any) {
      if (!buttons.includes(e.buttons) || !ref.current.contains(e.target)) {
        return;
      }
      e.preventDefault();
      const mouseX = e.pageX - ref.current.offsetLeft;
      const mouseY = e.pageY - ref.current.offsetTop;
      const walkX = mouseX - startX;
      const walkY = mouseY - startY;
      ref.current.scrollLeft = startScrollLeft - walkX;
      ref.current.scrollTop = startScrollTop - walkY;
    }
    document.addEventListener('mousedown', handleDown);
    document.addEventListener('mousemove', handleMove);
    return () => {
      document.removeEventListener('mousedown', handleDown);
      document.removeEventListener('mousemove', handleMove);
    };
  });

  return ref;
}
