import React from "react";
import { Enum_Unit } from "../../../typescript/enum";
import { RecipeUnitSelector } from "../../atoms/recipe-unit-selector/recipe-unit-selector";
import { ServeSelector } from "../../molecules/serve-selector/serve-selector";

type ServeSelectorWithUnitSelectorProps = {
  serving: number;
  handleUnitChange: (unit: Enum_Unit) => void;
  unitSelected: Enum_Unit;
  handleAddServe: () => void;
  handleRemoveServe: () => void;
};

export const ServeSelectorWithUnitSelector: React.FC<ServeSelectorWithUnitSelectorProps> = ({
  serving,
  handleUnitChange,
  unitSelected,
  handleAddServe,
  handleRemoveServe,
}) => {
  return (
    <div className="flex justify-between items-center">
      <ServeSelector numberOfServes={serving} handleAddServe={handleAddServe} handleRemoveServe={handleRemoveServe} />
      <RecipeUnitSelector unitSelected={unitSelected} handleUnitChange={handleUnitChange} />
    </div>
  );
};
