import React from "react";
import { HelperCanMakeIcon } from "../../atoms/helper-can-make-icon/helper-can-make-icon";

export const HelperCanMakeButton: React.FC = () => {
  return (
    <div className="flex space-x-[9px]">
       <HelperCanMakeIcon />
      <p className="text-[11px] leading-[20px] font-medium text-fontGray">Helper Can Make</p>
    </div>
  );
};
