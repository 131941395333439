import { PlanDinnerDish } from "components/molecules/plan-dinner-dish/plan-dinner-dish";
import useDraggable from "hooks/useDraggableScroll";
import React from "react";
import { Type_AddDish_Dish } from "typescript/types";
import { Droppable } from "react-beautiful-dnd";

type PlanDinnerDishesScrollProps = {
  dishes: Type_AddDish_Dish[];
  removeDishFromCollection: (id: number, isDayOfWeek: boolean) => void;
  id?: string;
  isDayOfWeek?: boolean;
  disableDrag: boolean;
  itemIsBeingDragged: (_isDragging: boolean) => void;
};

export const PlanDinnerDishesScroll: React.FC<PlanDinnerDishesScrollProps> = ({
  dishes,
  removeDishFromCollection,
  id,
  isDayOfWeek,
  disableDrag,
  itemIsBeingDragged,
}) => {
  const ref = useDraggable();
  let filteredDishes
  if(isDayOfWeek) {
     filteredDishes = dishes.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }
  return (
    <>
      {isDayOfWeek ? (
        <div
          ref={ref}
          className={`overflow-x-auto h-[110px] flex mr-[12px] scrollbar-hidden mt-[11px] w-full flex-1 `}
        >
          
          <div className="overflow-x-auto h-full flex space-x-[12px] scrollbar-hidden mt-[11px] flex-1"  >
            {filteredDishes.map((dish: any, index: any) => {
              return (
                <PlanDinnerDish
                  key={dish?.id}
                  dish={dish}
                  removeDishFromCollection={removeDishFromCollection}
                  isDayOfWeek={isDayOfWeek}
                  index={index}
                  disableDrag={disableDrag}
                  itemIsBeingDragged={itemIsBeingDragged}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div ref={ref} className={`overflow-x-auto h-[110px] flex mr-[12px] scrollbar-hidden mt-[11px] w-full flex-1`} >
          <Droppable droppableId={id ? id : "0"} direction="horizontal" isDropDisabled={disableDrag}>
            {(provided) => {
               filteredDishes = dishes.reduce((acc: any, current: any) => {
                const x = acc.find((item: any) => item.id === current.id);
                if (!x) {
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              }, []);
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="overflow-x-auto h-[90px] flex space-x-[12px] scrollbar-hidden mt-[11px] flex-1"
                  // onClick={() => console.log("clicked")}
               >
                  {filteredDishes.map((dish: any, index: any) => {
                    return (
                      <PlanDinnerDish
                        key={dish?.id}
                        dish={dish}
                        removeDishFromCollection={removeDishFromCollection}
                        isDayOfWeek={isDayOfWeek}
                        index={index}
                        disableDrag={disableDrag}
                        itemIsBeingDragged={itemIsBeingDragged}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </div>
      )}
    </>
  );
};


