import React from "react";
import { PageHeading } from "../../atoms/page-heading/page-heading";
import { AddSearchFilterControl } from "../../molecules/add-search-filter-control/add-search-filter-control";

type PageHeaderProps = {
  pageHeading: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ pageHeading, children}) => {
  return (
    <div className="flex justify-between items-center w-full">
      <PageHeading heading={pageHeading} />
      {children}
    </div>
  );
};
