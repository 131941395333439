import { createSlice } from "@reduxjs/toolkit";
import { Enum_Navigation } from "typescript/enum";

type Type_InitialState = {
  shoppingMeals: any;
  ingredientsList: any;
  excludedMeals: any;
  boughtIngredients: any;
};

const initialState: Type_InitialState = {
  shoppingMeals: {},
  ingredientsList: {},
  excludedMeals: [],
  boughtIngredients: []
};

const ShoppingListSlice = createSlice({
  name: "shoppingList",
  initialState,
  reducers: {
    setShoppingMeals(state, { payload }) {
      state.shoppingMeals = payload;
    },
    setIngredientsList(state, { payload }) {
      state.ingredientsList = payload;
    },
    setExcludedMeals(state, { payload }) {
      state.excludedMeals = [...state.excludedMeals, payload];
    },
    removeFromExcludedMeals(state, { payload }) {
      state.excludedMeals = state.excludedMeals.filter((meal: any) => {
        return meal !== payload
      })
    },
    removeFromBoughtIngredients(state, { payload }) {
      state.boughtIngredients = state.boughtIngredients.filter((ingredient: any) => {
        return ingredient !== payload
      })
      console.log(state.boughtIngredients)
    },
    setBoughtIngredients(state, { payload }) {
      state.boughtIngredients = payload;
    },
    addToBoughtIngredients(state, { payload }) {
      state.boughtIngredients = [...state.boughtIngredients, payload];
    },
  },

});

export const { setShoppingMeals, setExcludedMeals, setIngredientsList, removeFromExcludedMeals, removeFromBoughtIngredients, setBoughtIngredients, addToBoughtIngredients } = ShoppingListSlice.actions;
export default ShoppingListSlice.reducer;
