import { Button } from "components/atoms/button/button";
import { CustomDishForm } from "components/organisms/custom-dish-form/custom-dish-form";
import React, { useEffect, useState } from "react";
import { CloseButton } from "../../components/icons/close-button/close-button";
import { CustomDishTitle } from "../../components/atoms/custom-dish-title/custom-dish-title";
import { PageHeader } from "../../components/organisms/page-header/page-header";
import { PageTemplate } from "../../components/templates/page-template/page-template";
import { useHistory } from "react-router-dom";
import { useParamFromRoute } from "hooks/useParamFromRoute";
import { useLazyQuery } from "@apollo/client";
import { GET_MEAL_BY_ID } from "graphql/queries";
import { useAppSelector } from "store";
import { setLoader } from "reducers/uiSlice";
import { useDispatch } from "react-redux";
import { Notification } from "../../components/molecules/loader/loader";
import Loader from "react-loader-spinner";

export const AddCustomDish: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mealId = useParamFromRoute("mealId");
  const [meal, setMeal] = useState([]);
  const onClickClose = () => history.goBack();
  const loader = useAppSelector((state) => state.ui.loader);
  const [isLoading, setLoading] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);

  const [getMealById] = useLazyQuery(GET_MEAL_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.getMeal?.respones?.meal[0]) {
        setMeal(data?.getMeal?.respones?.meal[0]);
      }
      setDataLoading(false);
    },
  });

  useEffect(() => {
    if (mealId) {
      setDataLoading(true)
      getMealById({
        variables: {
          meal_id: parseInt(mealId),
        },
      });
    }
  }, [mealId]);

  useEffect(() => {
    if (loader?.status) {
      setLoading(true);
      setTimeout(() => {
        dispatch(setLoader({ status: "", message: "" }));
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [loader]);
  return (
    <PageTemplate>
      {isLoading && (
        <Notification status={loader.status} message={loader.message} />
      )}
      <PageHeader pageHeading={mealId ? "Edit Custom Dish" : "Add Custom Dish"}>
        {
          <div onClick={onClickClose}>
            <CloseButton />
          </div>
        }
      </PageHeader>
      <>
        {isDataLoading ? (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        ) : (
          <>
            {!mealId && (
              <div className="mt-[21px] mb-[30px]">
                <CustomDishTitle />
              </div>
            )}
            <div className={`${mealId && "pt-[21px]"}`}>
              <CustomDishForm meal={meal} />
            </div>
          </>
        )}
      </>
    </PageTemplate>
  );
};
