import React from "react";
import { PlayVideoIcon } from "../../icons/play-video-icon/play-video-icon";

export const WatchVideo: React.FC = () => {
  return (
    <div className="flex items-center space-x-[9px] cursor-pointer">
      <PlayVideoIcon />
      <p className="text-[12px] leading-[16px] font-semibold text-primary">Watch Video</p>
    </div>
  );
};
