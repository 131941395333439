import styled from "@emotion/styled"

export const PageContainer = styled.div`
  padding: 25px 18px;
  background: radial-gradient(151.95% 77.66% at 49.87% 77.66%, #430D51 0%, #2F0842 100%);
  height: 100vh;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;

`
