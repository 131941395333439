import { TODO, Type_DietryTypes, Type_Row, Type_Servings, Type_Tabs } from "../typescript/types";

export const SERVINGS: Type_Servings[] = [
  {
    id: 1,
    name: "2 Servings",
    description: "For two, or one with leftovers",
  },
  {
    id: 2,
    name: "4 Servings",
    description: "For four, or two/three with leftovers",
  },
  {
    id: 3,
    name: "6 Servings",
    description: "For a family of 5+",
  },
  {
    id: 4,
    name: "8 Servings",
    description: "For a family of 7+",
  },
];

export const TABS: Type_Tabs[] = [
  {
    id: 0,
    label: "Recipe",
  },
  {
    id: 1,
    label: "Instructions",
  },
];

export const OPTIONS = [
  {
    label: "Option 1",
    value: "option1",
  },
  {
    label: "Option 2",
    value: "option2",
  },
  {
    label: "Option 3",
    value: "option3",
  },
];

export const THEME = (theme: TODO) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#3F104D",
  },
});

export const STYLES = {
  control: (styles: TODO, { isFocused, isSelected }: TODO) => ({
    ...styles,
    backgroundColor: "white",
    minHeight: 50,
    borderRadius: 30,
    marginBottom: 30,
    paddingLeft: 11,
    paddingRight: 11,
    boxShadow: "none",
  }),
  option: (styles: TODO, { data, isDisabled, isFocused, isSelected }: TODO) => {
    return {
      ...styles,
      "backgroundColor": isDisabled ? null : isSelected ? "rgb(230,230,230)" : isFocused ? "rgb(247,247,247)" : null,
      "color": isDisabled ? "#ccc" : isSelected && "black",
      "cursor": isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && isSelected && "rgb(247,247,247)",
      },
    };
  },
  multiValue: (styles: TODO) => {
    return {
      ...styles,
      display: "none",
    };
  },
  indicatorSeparator: (styles: TODO) => {
    return {
      ...styles,
      display: "none",
    };
  },
  clearIndicator: (styles: TODO) => {
    return {
      ...styles,
      display: "none",
    };
  },
  input: (styles: TODO) => {
    return {
      ...styles,
    };
  },
  multiValueRemove: (styles: TODO, { data }: TODO) => ({
    ...styles,
    "color": data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (styles: TODO) => ({ ...styles }),
  singleValue: (styles: TODO, { data }: TODO) => ({ ...styles }),
};

export const ACCOUNT_TYPES: Type_DietryTypes[] = [
  {
    id: 0,
    name: "Family",
  },
  {
    id: 1,
    name: "Helper",
  },
];

export const BASE_URL_S3_BUCKET_RESPONSE = "https://menulyuploads.s3.amazonaws.com";

export const ROWS: Type_Row[] = [
  {
    id: "0",
    title: "Collection",
    taskIds: [],
  },
  {
    id: "1",
    title: "Monday",
    taskIds: [],
  },
  {
    id: "2",
    title: "Tuesday",
    taskIds: [],
  },
  {
    id: "3",
    title: "Wednesday",
    taskIds: [],
  },
  {
    id: "4",
    title: "Thursday",
    taskIds: [],
  },
  {
    id: "5",
    title: "Friday",
    taskIds: [],
  },
  {
    id: "6",
    title: "Saturday",
    taskIds: [],
  },
  {
    id: "7",
    title: "Sunday",
    taskIds: [],
  },
];

export const helpers = ["Roger", "William", "Salim", "Ali"];

export const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const nameRegex = /^[a-z\s]{0,255}$/i;

export const TIME_INTERVAL_BETWEEN_PLANDINNER_CALLS = 6000;

export const URL_REF = {
  PlanDinner: "plan-dinner",
  ShoppingList: "shopping-list",
};

export const VALID_IMAGE_TYPES = ["jpeg", "jpg", "png"];


export const PLACEHOLDER_MEAL_ID = 641; // Should be replaces with id if id changes 

export const DEFAULT_ACTIVE_DAY_STATUS=[
  true,
  true,
  true,
  true,
  true,
  true,
  true,
]