import { createContext } from "react";
import { TagCategory } from "../../typescript/types";

type Type_MealIdeasContext = {
  onClickFilterButton: () => void;
  onClickTag: (tagName: string) => void;
  onClickCustomDish: () => void;
};

export const MealIdeasContext = createContext<Type_MealIdeasContext>({
  onClickFilterButton: () => {},
  onClickTag: () => {},
  onClickCustomDish: () => {},
});

export const MealIdeasContextProvider = MealIdeasContext.Provider;
