import { PlanDinnerDish } from "components/molecules/plan-dinner-dish/plan-dinner-dish";
import { PlannedMeal } from "components/molecules/shopping-list/planned-meal";
import useDraggable from "hooks/useDraggableScroll";
import React from "react";

type ShoppingListScrollTypes = {
  dishes: any;
  removeDishFromCollection: (id: number) => void;
  addToShoppingMeals: (id: number) => void;
  id?: string;
};

export const ShoppingListScroll: React.FC<ShoppingListScrollTypes> = ({
  dishes,
  removeDishFromCollection,
  addToShoppingMeals,
  id,
}) => {
  return (
    <div className="overflow-x-auto h-[90px] flex space-x-[12px] scrollbar-hidden mt-[11px] flex-1">
      {dishes.map((dish: any, index: any) => (
        <PlannedMeal
          key={dish?.id}
          dish={dish}
          addToShoppingMeals={addToShoppingMeals}
          removeDishFromCollection={removeDishFromCollection}
          index={index}
        />
      ))}
    </div>
  );
};
