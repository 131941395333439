import React from "react";
import Loader from "react-loader-spinner";
import { combineClasses } from "../../../utils/combineClasses";
import { WhitePlusIcon } from "../../icons/white-plus-icon/white-plus-icon";
import Styles from "./button.module.css";
type ButtonProps = {
  label: string;
  onClick: () => void;
  className?: string;
  isSignInButton?: boolean;
  renderPlusIcon?: boolean;
  loading?: boolean;
  hasPurpleGradient?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  label,
  isSignInButton = false,
  onClick,
  className = `h-[40px] bottom-[27px]`,
  renderPlusIcon = false,
  loading = false,
  hasPurpleGradient = false,
}) => {
  return (
    <div
      className={combineClasses(
        `w-full  flex justify-center   ${isSignInButton ? "" : "fixed left-0 h-[99px]"}`,
        hasPurpleGradient ? Styles.purpleGradient : Styles.whiteGradient,
      )}
    >
      <div
        onClick={onClick}
        className={combineClasses(
          `bg-accent font-semibold text-sm flex justify-center items-center cursor-pointer text-white px-[30px]  traking-[1%] capitalize rounded-full opacity-100 ${
            isSignInButton ? "min-w-[108px]" : "fixed"
          }   z-10`,
          className,
        )}
      >
        {loading && (
          <div className="align-left pr-4">
            <Loader type="TailSpin" color="#FFF" height={20} width={20} />
          </div>
        )}
        {renderPlusIcon && (
          <div className="mr-[11px]">
            {" "}
            <WhitePlusIcon />{" "}
          </div>
        )}
        {label}
      </div>
    </div>
  );
};
