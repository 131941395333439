import React, { useEffect, useState } from "react";
import { BackArrow } from "components/icons/back-arrow-primary/back-arrow-primary";
import { Button } from "../../components/atoms/button/button";
import { SelectListItems } from "../../components/organisms/select-list-items.tsx/select-list-items";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_CUISINE_FAVOURITE, GET_DIETS } from "../../graphql/queries";
import { Type_DietryTypes } from "../../typescript/types";
import { INSERT_CUISINE_FAVOURITE } from "../../graphql/mutation";
import { useHistory } from "react-router-dom";
import { Enum_Diet_Types } from "../../typescript/enum";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setLoader } from "reducers/uiSlice";
import { Notification } from "../../components/molecules/loader/loader";
import Loader from "react-loader-spinner";
import { PageTemplate } from "components/templates/page-template/page-template";
import { UserPreferencesHeadingPrimary } from "components/atoms/user-preferences-heading/user-preferences-heading-primary";

export const MyDiet: React.FC = () => {
  const history = useHistory();
  let userId: any;
  userId = localStorage.getItem("user_Id");
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [diet, setDiet] = useState<Type_DietryTypes[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [getCuisineFavourite] = useLazyQuery(GET_CUISINE_FAVOURITE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let filterIDArray: number[] = [];
      if (data?.cuisine_favourite?.length > 0) {
        data?.cuisine_favourite?.map((item: any) => {
          filterIDArray.push(item?.filter_tag_id);
        });
        console.log("=-=-=-", filterIDArray)
        setSelectedItems(filterIDArray);
      }
      setIsDataLoading(false)
    },
  });

  useEffect(() => {
    userId &&
      getCuisineFavourite({
        variables: {
          where: {
            employer_id: { _eq: parseInt(userId) },
          },
        },
      });
  }, [userId]);

  useEffect(() => {
    if (loader?.status) {
      setLoading(true);
      setTimeout(() => {
        dispatch(setLoader({ status: "", message: "" }));
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [loader]);

  const { loading } = useQuery(GET_DIETS, {
    variables: {
      where: { category_tag: { tag: { _eq: Enum_Diet_Types.diets } } },
    },
    onCompleted: (data) => {
      data && setDiet(data.filter_tags);
    },
  });

  const [addFavouriteDiets, { error }] = useMutation(INSERT_CUISINE_FAVOURITE);

  const handleClickItem = (item: Type_DietryTypes) => {
    if (!selectedItems.includes(item.id))
      setSelectedItems([...selectedItems, item.id]);
    else {
      const _selectedItems = selectedItems;
      const filteredSelectedItems = _selectedItems.filter(
        (selectedItem) => selectedItem !== item.id
      );
      setSelectedItems(filteredSelectedItems);
    }
  };

  const handleContinueClick = () => {
    //TODO: Add employer id or helper id
    setTimeout(() => {
      userId &&
        selectedItems.forEach((item) => {
          addFavouriteDiets({
            variables: {
              objects: {
                employer_id: parseInt(userId),
                filter_tag_id: item,
              },
            },
          });
        });
      history.push(`/preferences`);
    }, 500);
  };

  useEffect(() => {
    error &&
      dispatch(
        setLoader({ status: "error", message: "Something went wrong!" })
      );
    error && setIsDataLoading(false);
  }, [error]);

  return (
    <>
      <PageTemplate>
        {isLoading && (
          <Notification status={loader.status} message={loader.message} />
        )}
        <BackArrow />
        <UserPreferencesHeadingPrimary heading="Select the diet types that match your household" />
        {isDataLoading ? (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        ) : (
          <>
            {" "}
            <SelectListItems
              list={diet}
              handleClickItem={handleClickItem}
              selectedItems={selectedItems}
            />
            <Button
              className="bottom-[88px] h-[46px] left-[50%] ml-[-60.5px]"
              label="continue"
              onClick={handleContinueClick}
              hasPurpleGradient
            />{" "}
          </>
        )}
      </PageTemplate>
      {/* <StyledBoxedShadow /> */}
    </>
  );
};
