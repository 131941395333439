import React, { useContext } from "react";
import { MealIdeasContext } from "../../../pages/meal-ideas/meal-context";
import { Enum_FavouriteControl } from "../../../typescript/enum";
import { MosiacMeal } from "../../../typescript/types";
import { DishImageWithButton } from "../../molecules/dish-image-with-button/dish-image-with-button";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { useAppSelector } from "store";
type MosiacProps = {
  mosaic: MosiacMeal[];
  onClickMeal?: (mealId: number, isCustom: boolean) => void;
  onClickFavourite: (mealId: number, favourite: Enum_FavouriteControl) => void;
  totalMosiacMeals: number;
  pagination: () => void;
  favouriteMeals: number[];
};

export const Mosaic: React.FC<MosiacProps> = ({
  mosaic,
  onClickMeal,
  onClickFavourite,
  pagination,
  totalMosiacMeals,
  favouriteMeals,
}) => {
  let indexPlusOne = 0;
  let lastFull = 0;
  let lastHalf = 0;
  const { selectedStaticFilters, selectedTags, showSearch } = useAppSelector((state) => ({
    selectedStaticFilters: state.meals.selectedStaticFilters,
    selectedTags: state.meals.selectedTags,
    showSearch: state.meals.showSearch,
  }));
  return (
    <div className={`flex flex-col w-full h-full`}>
      {mosaic && mosaic?.length > 0 && (
        <div>
          <InfiniteScroll
            dataLength={mosaic.length}
            hasMore={mosaic.length < totalMosiacMeals}
            loader={<div className="flex justify-center fixed top-[50%] left-[50%] "></div>}
            next={() => {
              !(selectedStaticFilters.length > 0 || selectedTags.length > 0 || showSearch === true) && pagination();
            }}
          >
            {mosaic.map((meal, index) => {
              if (index === 0 || index === lastFull + 3) {
                lastFull = index;
                return (
                  <div className="mb-[9px]" key={meal.id}>
                    <DishImageWithButton
                      mosaic
                      customDish={meal.custom_meal ?? false}
                      dishName={meal.name}
                      image={meal.image_url}
                      id={meal.id}
                      onClickMeal={onClickMeal}
                      onClickFavourite={onClickFavourite}
                      favourite={favouriteMeals.includes(meal.id) ? true : false}
                    />
                  </div>
                );
              } else if (index === 1 || index - 1 !== lastHalf) {
                indexPlusOne = index + 1;
                lastHalf = index;
                return (
                  <div
                    className="flex flex-row space-x-[9px] justify-between w-full mb-[9px] h-full"
                    key={mosaic[index].id}
                  >
                    {mosaic[index].id && (
                      <DishImageWithButton
                        mosaic
                        halfWidth
                        customDish={meal.custom_meal ?? false}
                        dishName={mosaic[index].name}
                        image={mosaic[index]?.image_url}
                        key={mosaic[index].id}
                        id={mosaic[index].id}
                        onClickMeal={onClickMeal}
                        onClickFavourite={onClickFavourite}
                        favourite={favouriteMeals.includes(mosaic[index].id) ? true : false}
                      />
                    )}
                    {mosaic[indexPlusOne]?.id && (
                      <DishImageWithButton
                        mosaic
                        halfWidth
                        customDish={meal.custom_meal ?? false}
                        dishName={mosaic[indexPlusOne]?.name}
                        image={mosaic[indexPlusOne]?.image_url}
                        key={mosaic[indexPlusOne]?.id}
                        id={mosaic[indexPlusOne]?.id}
                        onClickMeal={onClickMeal}
                        onClickFavourite={onClickFavourite}
                        favourite={favouriteMeals.includes(mosaic[indexPlusOne]?.id) ? true : false}
                      />
                    )}
                  </div>
                );
              } else if (index === mosaic.length - 1) {
                indexPlusOne = 0;
                lastFull = 0;
                lastHalf = 0;
              }

              return <React.Fragment key={index}></React.Fragment>;
            })}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
