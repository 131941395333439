import React, { useEffect, useState } from "react";
import { BackArrow } from "components/icons/back-arrow-primary/back-arrow-primary";
import { Button } from "../../components/atoms/button/button";
import { SelectListItems } from "../../components/organisms/select-list-items.tsx/select-list-items";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_ALLERGY_NAMES,
  GET_EMPLOYER_ALLERGIES,
} from "../../graphql/queries";
import { Type_DietryTypes } from "../../typescript/types";
import { INSERT_EMPLOYER_ALLERGIES } from "../../graphql/mutation";
import { useHistory } from "react-router-dom";
import { Notification } from "../../components/molecules/loader/loader";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setLoader } from "reducers/uiSlice";
import Loader from "react-loader-spinner";
import { PageTemplate } from "components/templates/page-template/page-template";
import { UserPreferencesHeadingPrimary } from "components/atoms/user-preferences-heading/user-preferences-heading-primary";

export const MyFoodAllergies: React.FC = () => {
  const history = useHistory();
  const [allergies, setAllergies] = useState<Type_DietryTypes[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [insertAllergies, { error }] = useMutation(INSERT_EMPLOYER_ALLERGIES);
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  let userId: any;
  userId = localStorage.getItem("user_Id");
  const [getMyAllergies] = useLazyQuery(GET_EMPLOYER_ALLERGIES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log("-=-=-=", data?.employer_allergies[0]?.allergy);
      let selected_arr: number[];
      data?.employer_allergies?.length > 0 &&
        data?.employer_allergies?.map((item: any) => {
          console.log("=-=-==-", item);
          setSelectedItems((selectedItems) => [
            ...selectedItems,
            item?.allergy?.id,
          ]);
        });
      setIsDataLoading(false);
    },
  });

  useEffect(() => {
    userId &&
      getMyAllergies({
        variables: {
          where: {
            employer_id: { _eq: parseInt(userId) },
          },
        },
      });
  }, [userId]);

  useEffect(() => {
    if (loader?.status) {
      setLoading(true);
      setTimeout(() => {
        dispatch(setLoader({ status: "", message: "" }));
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [loader]);

  useQuery<{ allergies: Type_DietryTypes[] }>(GET_ALLERGY_NAMES, {
    onCompleted: (data) => {
      data && console.log({ data: data.allergies });
      data && setAllergies(data.allergies);
    },
  });

  const handleClickItem = (item: Type_DietryTypes) => {
    if (!selectedItems.includes(item.id))
      setSelectedItems([...selectedItems, item.id]);
    else {
      const _selectedItems = selectedItems;
      const filteredSelectedItems = _selectedItems.filter(
        (selectedItem) => selectedItem !== item.id
      );
      setSelectedItems(filteredSelectedItems);
    }
  };

  const handleContinueClick = () => {
    setTimeout(() => {
      selectedItems.forEach((selectedItem) => {
        userId &&
          insertAllergies({
            variables: {
              objects: {
                allergies_id: selectedItem,
                employer_id: parseInt(userId),
              },
            },
          });
      });
      history.push(`/preferences`);
    }, 500);
  };

  useEffect(() => {
    error &&
      dispatch(
        setLoader({ status: "error", message: "Something went wrong!" })
      );
    error && setIsDataLoading(false);
  }, [error]);

  return (
    <>
      <PageTemplate>
        {isLoading && (
          <Notification status={loader.status} message={loader.message} />
        )}
        <BackArrow />
        <UserPreferencesHeadingPrimary heading="Does your household have any food allergies?" />
        {isDataLoading ? (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        ) : (
          <>
            <SelectListItems
              list={allergies}
              handleClickItem={handleClickItem}
              selectedItems={selectedItems}
            />
            <Button
              className="bottom-[88px] h-[46px] left-[50%] ml-[-60.5px]"
              label="continue"
              onClick={handleContinueClick}
              hasPurpleGradient
            />
          </>
        )}
      </PageTemplate>
      {/* <StyledBoxedShadow /> */}
    </>
  );
};
