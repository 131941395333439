import { gql } from "@apollo/client";

export const GET_DIETS = gql`
  query filter_tags($where: filter_tags_bool_exp) {
    filter_tags(where: $where) {
      name: tags
      id
    }
  }
`;

export const GET_ALLERGY_NAMES = gql`
  query allergies {
    allergies {
      name
      id
    }
  }
`;

export const GET_MEAL_BY_ID = gql`
  query getMeal($meal_id: Int, $servings: Int, $convert: String) {
    getMeal(meal_id: $meal_id, servings: $servings, convert: $convert) {
      respones
    }
  }
`;

export const GET_MOSIAC_MEALS = gql`
  query meal($limit: Int, $offset: Int) {
    meal(limit: $limit, offset: $offset) {
      id
      image_url
      name
      custom_meal
      household_id
      employer_favourites {
        id
        meal_id
        employer {
          id
        }
      }
      helper_can_makes {
        id
        meal_id
        helper {
          id
        }
      }
    }
  }
`;

export const GET_MOSIAC_MEALS_BY_USERID = gql`
  query meal($limit: Int, $offset: Int, $where: meal_bool_exp) {
    meal(limit: $limit, offset: $offset, where: $where) {
      id
      image_url
      name
      custom_meal
      household_id
      employer_favourites {
        id
        meal_id
        employer {
          id
        }
      }
      helper_can_makes {
        id
        meal_id
        helper {
          id
        }
      }
    }
  }
`;

export const GET_TOTAL_MOSIAC_MEALS_BY_USER_ID = gql`
  query {
    meal_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FILTERS = gql`
  query filter_tags {
    filter_tags(distinct_on: [category_id]) {
      category_tag {
        id
        tag
        filter_tags {
          tags
        }
      }
    }
  }
`;

export const GET_MOSIAC_MEALS_THROUGH_FILTERS = gql`
  query meal_filters($where: meal_filters_bool_exp) {
    meal_filters(where: $where, distinct_on: [meal_id]) {
      meal {
        id
        image_url
        name
        employer_favourites {
          id
          meal_id
        }
        helper_can_makes {
          id
          meal_id
        }
      }
    }
  }
`;

export const GET_SEARCH_MEALS = gql`
  query meal($where: meal_bool_exp) {
    meal(where: $where) {
      id
      custom_meal
      household_id
      image_url
      name
      cuisine
      employer_favourites {
        id
        meal_id
      }
      helper_can_makes {
        id
        meal_id
      }
    }
  }
`;

export const GET_TAGS = gql`
  query filter_tags($distinct_on: [filter_tags_select_column!]) {
    filter_tags(distinct_on: $distinct_on) {
      id
      tags
    }
  }
`;

export const SEND_USER_INVITATION = gql`
  query sendUserInvitation($Object: SampleInput) {
    sendUserInvitation(Object: $Object) {
      flag
    }
  }
`;

export const GET_PLANED_MEALS = gql`
  query plannedMeals($where: planned_meals_bool_exp) {
    planned_meals(where: $where) {
      created_at
      date
      household_id
      id
      meal_id
      servings
      updated_at
      meal {
        thubmnail_url
        description
        id
      }
    }
  }
`;

// export const GET_SHOPPING_LIST = gql`
//   query shoppingList(
//     $endDate: timestamptz
//     $household_id: Int!
//     $startDate: timestamptz
//   ) {
//     ShoppingList(
//       endDate: $endDate
//       household_id: $household_id
//       startDate: $startDate
//     ) {
//       respones
//     }
//   }
// `;

export const GET_HOUSEHOLD = gql`
  query getHoouseHold($where: household_employer_helper_bool_exp) {
    household_employer_helper(where: $where) {
      household_id
    }
  }
`;

export const GET_MEALS_BETWEEN_RANGES = gql`
  query getMealsBetweenRanges($where: planned_meals_bool_exp) {
    planned_meals(where: $where) {
      meal {
        image_url
        name
        id
      }
      date
      id
      active
    }
  }
`;

export const GET_HELPERS = gql`
  query household_employer_helper($where: household_employer_helper_bool_exp) {
    household_employer_helper(where: $where) {
      user_id
      user_type
    }
  }
`;

export const GET_HELPER = gql`
  query helpers($where: helpers_bool_exp) {
    helpers(where: $where) {
      email
      name
      password
    }
  }
`;

export const GET_EMPLOYER = gql`
  query employers($where: employers_bool_exp) {
    employers(where: $where) {
      email
      name
      password
      default_servings
    }
  }
`;

export const GET_INSTRUCTIONS = gql`
  query instructions($where: instructions_bool_exp) {
    instructions(where: $where) {
      created_at
      description
      id
      meal_id
      name
    }
  }
`;

export const GET_MEAL_FILTERS = gql`
  query meal_filters($where: meal_filters_bool_exp) {
    meal_filters(where: $where) {
      filter_tag {
        id
        tags
      }
      filter_tag_id
      id
      meal_id
    }
  }
`;

export const GET_SAME_HOUSEHOLD = gql`
  query household_employer_helper($household_id: Int, $user_type: String) {
    household_employer_helper(
      where: {
        household_id: { _eq: $household_id }
        user_type: { _eq: $user_type }
      }
    ) {
      household_id
      id
      user_id
      user_type
    }
  }
`;

export const GET_EMPLOYERS_FAVOURITES = gql`
  query employer_favourite($where: employer_favourite_bool_exp) {
    employer_favourite(where: $where) {
      meal {
        id
        image_url
        name
        cuisine
        custom_meal
        employer_favourites {
          id
          meal_id
        }
        helper_can_makes {
          id
          meal_id
        }
      }
    }
  }
`;

export const GET_HELPER_CAN_MAKE = gql`
  query helper_can_make($where: helper_can_make_bool_exp) {
    helper_can_make(where: $where) {
      helper_id
      meal {
        id
        image_url
        name
        cuisine
        custom_meal
        employer_favourites {
          id
          meal_id
        }
        helper_can_makes {
          id
          meal_id
        }
      }
    }
  }
`;

export const GET_SHOPPING_MEALS = gql`
  query shopping_meals($where: shopping_meals_bool_exp) {
    shopping_meals(where: $where) {
      created_at
      excluded
      household_id
      id
      meal_id
      meal {
        image_url
        id
        name
      }
    }
  }
`;

export const GET_SHOPPING_INGREDIENTS_LIST = gql`
  query getShoppingIngredientsList($ids: [Int!]) {
    getShoppingIngredientsList(ids: $ids) {
      id
      bought
      name
      quantity
      unit
    }
  }
`;

export const GET_SHOPPING_LIST = gql`
  query ShoppingList(
    $endDate: timestamptz
    $household_id: Int!
    $startDate: timestamptz
  ) {
    ShoppingList(
      endDate: $endDate
      household_id: $household_id
      startDate: $startDate
    ) {
      respones
    }
  }
`;

export const GET_EMPLOYER_ALLERGIES = gql`
  query employer_allergies($where: employer_allergies_bool_exp) {
    employer_allergies(where: $where) {
      allergy {
        id
        name
      }
    }
  }
`;

export const GET_CUISINE_FAVOURITE = gql`
  query cuisine_favourite($where: cuisine_favourite_bool_exp) {
    cuisine_favourite(where: $where) {
      filter_tag_id
    }
  }
`;
