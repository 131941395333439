import React from "react";
import { SingleTag } from "../../molecules/single-tag/single-tag";

type Props = {
  onClickTag: (tagName: string) => void;
  selectedTags: string[];
};

export const Tags: React.FC<Props> = ({ selectedTags, onClickTag }) => {
  return (
    <div className="flex gap-y-[7px] flex-wrap">
      {selectedTags.map((tag) => (
        <SingleTag tagName={tag} handleClickRemoveTag={onClickTag} />
      ))}
    </div>
  );
};
