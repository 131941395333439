import { useLazyQuery, useMutation } from "@apollo/client";
import { EmployerFavourite } from "components/molecules/emplopyer-favourite/employer-favourite";
import { HelperCanMakeButton } from "components/molecules/helper-can-make-button/helper-can-make-button";
import { Alert } from "components/organisms/alert/alert";
import {
  ADD_FAVOURITE_DISH_BY_EMPLOYER_ID,
  DELETE_EMPLOYER_FAVOURITE,
  DELETE_HELPER_CAN_MAKE,
  INSERT_HELPER_CAN_MAKE,
} from "graphql/mutation";
import {
  GET_EMPLOYERS_FAVOURITES,
  GET_HELPER_CAN_MAKE,
  GET_MEAL_BY_ID,
  GET_SAME_HOUSEHOLD,
} from "graphql/queries";
import { useParamFromRoute } from "hooks/useParamFromRoute";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { pickDish, setSingleDish } from "reducers/planDinnerSlice";
import { Enum_FavouriteControl } from "typescript/enum";
import { Meal, TODO, Type_AddDish_Dish } from "typescript/types";
import { CustomDishLabelWithEdit } from "../../components/atoms/custom-dish-label-with-edit/custom-dish-label-with-edit";
import { DishDescription } from "../../components/atoms/dish-description/dish-description";
import { VisitWebsite } from "../../components/atoms/visit-website/visit-website";
import { DishImageWithButton } from "../../components/molecules/dish-image-with-button/dish-image-with-button";
import { DishDetailTemplate } from "../../components/templates/dish-detail-template/dish-detail-template";

export const CustomDishDetail: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mealID = useParamFromRoute("mealId");
  const [meal, setMeal] = useState<Meal | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [addToFavouriteDish] = useMutation(ADD_FAVOURITE_DISH_BY_EMPLOYER_ID);
  const [insertHelperCanMake] = useMutation(INSERT_HELPER_CAN_MAKE);
  const [alert, setAlert] = useState(false);
  const [householdIDs, setHouseholdIDs] = useState<any>();
  const [userType, setUserType] = useState("");
  const [displayTag, setDisplayTag] = useState("");
  const [favouriteMeal, setFavouriteMeal] = useState(false);
  const [deleteEmployerFavourite] = useMutation(DELETE_EMPLOYER_FAVOURITE);
  const [deleteHelperCanMake] = useMutation(DELETE_HELPER_CAN_MAKE);
  const [getHelperCanMake] = useLazyQuery(GET_HELPER_CAN_MAKE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log(data, "helper can make");
      if (data?.helper_can_make?.length > 0) {
        setDisplayTag("HELPER_CAN_MAKE");
      }
    },
  });
  const [getEmployersFavourites] = useLazyQuery(GET_EMPLOYERS_FAVOURITES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log(data, "emp fav");
      if (data?.employer_favourite?.length > 0) {
        setDisplayTag("EMPLOYER_FAVOURITE");
      }
    },
  });
  const [getMealById, { error }] = useLazyQuery(GET_MEAL_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.getMeal?.respones?.meal[0]) {
        setMeal(data?.getMeal?.respones?.meal[0]);
        dispatch(
          setSingleDish({
            name: data?.getMeal?.respones?.meal[0]?.name,
            image_url: data?.getMeal?.respones?.meal[0]?.image_url,
            id: data?.getMeal?.respones?.meal[0]?.id,
          })
        );
        setTimeout(function () {
          let userId: any = localStorage.getItem("user_Id");
          let userType: any = localStorage.getItem("user_type");

          if (userType == "EMPLOYER") {
            if (
              data?.getMeal?.respones?.meal[0]?.employer_favourites?.length > 0
            ) {
              data?.getMeal?.respones?.meal[0]?.employer_favourites?.map(
                (item: { employer?: any; id: number; meal_id: number }) => {
                  if (item?.employer?.id == parseInt(userId)) {
                    setFavouriteMeal(true);
                  }
                }
              );
            }
          } else if (userType == "HELPER") {
            if (
              data?.getMeal?.respones?.meal[0]?.helper_can_makes?.length > 0
            ) {
              data?.getMeal?.respones?.meal[0]?.helper_can_makes?.map(
                (item: { helper?: any; id: number; meal_id: number }) => {
                  if (item?.helper?.id == parseInt(userId)) {
                    setFavouriteMeal(true);
                  }
                }
              );
            }
          }
        }, 500);
      } else {
        setAlert(true);
      }
      setLoading(false);
    },
  });
  const [getSameHousehold] = useLazyQuery(GET_SAME_HOUSEHOLD, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let id_arr: any = [];
      data?.household_employer_helper?.length > 0 &&
        data?.household_employer_helper?.map((obj: any) => {
          id_arr.push(obj?.user_id);
        });
      setHouseholdIDs(id_arr);
    },
  });

  useEffect(() => {
    if (householdIDs?.length > 0) {
      //TODO: Filtering null value for time being
      let arr: any = [];
      arr = householdIDs;
      arr = arr.filter((item: any) => {
        return item !== null;
      });
      console.log(arr);
      console.log("house hold IDs", householdIDs);
      if (userType == "HELPER") {
        getEmployersFavourites({
          variables: {
            where: {
              employer_id: { _in: arr },
              meal_id: { _eq: parseInt(mealID) },
            },
          },
        });
      } else {
        getHelperCanMake({
          variables: {
            where: {
              helper_id: { _in: arr },
              meal_id: { _eq: parseInt(mealID) },
            },
          },
        });
      }
    }
  }, [householdIDs]);

  useEffect(() => {
    let userType: any;
    let householdId: any;
    let searchUserType: any;
    setTimeout(function () {
      userType = localStorage.getItem("user_type");
      householdId = localStorage.getItem("household_id");
      setUserType(userType);
      userType == "EMPLOYER"
        ? (searchUserType = "HELPER")
        : (searchUserType = "EMPLOYER");
      getSameHousehold({
        variables: {
          household_id: parseInt(householdId),
          user_type: searchUserType,
        },
      });
    }, 200);
  }, []);

  useEffect(() => {
    getMealById({
      variables: {
        meal_id: parseInt(mealID),
      },
    });
  }, [mealID]);

  useEffect(() => {
    console.log("error", error);
    if (error) {
      setLoading(false);
      setAlert(true);
    }
  }, [error]);

  const onClickClose: () => void = () => {
    history.push(`/meal-ideas`);
  };

  const onClickFavourite: (
    mealId: number,
    favourite: Enum_FavouriteControl
  ) => void = (mealId, control) => {
    // maintain local state with ids of favourite meals
    // to remove lag when user favourites or unfavourites meal
    // TODO: Add variable employer id
    if (control === Enum_FavouriteControl.Add) {
      _addToFavouriteDish(mealId);
    } else if (control === Enum_FavouriteControl.Remove) {
      _removeFromFavouriteDish(mealId);
    }
  };

  const _addToFavouriteDish: (mealId: number) => void = (mealId) => {
    let userId: any;
    setFavouriteMeal(true);
    setTimeout(() => {
      userId = localStorage.getItem("user_Id");
      if (localStorage.getItem("user_type") == "EMPLOYER") {
        addToFavouriteDish({
          variables: {
            objects: {
              employer_id: parseInt(userId),
              meal_id: mealId,
            },
          },
        }).then(() => {});
      } else if (localStorage.getItem("user_type") == "HELPER") {
        insertHelperCanMake({
          variables: {
            objects: {
              helper_id: parseInt(userId),
              meal_id: mealId,
            },
          },
        })
          .then(() => {})
          .catch(() => {});
      }
    }, 400);
  };
  const _removeFromFavouriteDish = (mealId: number) => {
    setFavouriteMeal(false);
    if (localStorage.getItem("user_type") == "EMPLOYER") {
      deleteEmployerFavourite({
        variables: {
          where: {
            meal_id: { _eq: mealId },
          },
        },
      })
        .then(() => {})
        .catch(() => {
          setAlert(true);
        });
    } else if (localStorage.getItem("user_type") == "HELPER") {
      deleteHelperCanMake({
        variables: {
          where: {
            meal_id: { _eq: mealId },
          },
        },
      }).then(() => {});
    }
  };

  const handleOnAddDish: (dishToAdd: Type_AddDish_Dish) => void = (
    valueToAdd
  ) => {
    console.log("add", valueToAdd);
    dispatch(pickDish(valueToAdd));
  };

  return (
    <DishDetailTemplate
      {...{ handleOnAddDish }}
      heading={meal?.name ?? ""}
      onClickClose={onClickClose}
    >
      {isLoading ? (
        <div className="flex justify-center fixed top-[50%] left-[50%] ">
          <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
        </div>
      ) : (
        <>
          <CustomDishLabelWithEdit mealId={meal?.id ?? 0} />
          <DishImageWithButton
            customDish={meal?.custom_meal ?? false}
            dishName={meal?.name}
            image={meal?.image_url ?? ""}
            id={meal?.id}
            onClickFavourite={onClickFavourite}
            favourite={favouriteMeal ? true : false}
          />
          <div>
            {userType == "EMPLOYER" && displayTag == "HELPER_CAN_MAKE" && (
              <div className="mt-2">
                <HelperCanMakeButton />
              </div>
            )}
            {userType == "HELPER" && displayTag == "EMPLOYER_FAVOURITE" && (
              <div className="mt-2">
                <EmployerFavourite />
              </div>
            )}
            <div className="mt-[13px] mb-[22px]">
              <DishDescription description={meal?.description ?? ""} />
            </div>
          </div>
          <div className="mt-[21px] cursor-pointer"></div>
          <a href={meal?.website_url ?? ""} target="_blank">
            <VisitWebsite />
          </a>
        </>
      )}
      {alert && (
        <Alert
          message="Something went wrong!"
          buttonText="OK"
          setAlert={setAlert}
        />
      )}
    </DishDetailTemplate>
  );
};
