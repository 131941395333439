type UploadButtonProps = {
   buttonLabel: string;
   handleOnChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void)
}

export const UploadButton: React.FC<UploadButtonProps> = ({ buttonLabel,handleOnChange }) => {
  return (
    <div
      className="px-[20px] py-[12px] text-primary text-[12px] leading-[16px] text-center rounded-[30px] border-[2px] border-primary mb-[30px] cursor-pointer"
      style={{ width: "fit-content" }}
    >
      <label
        htmlFor={`upload-ingredient-image`}
        className="text-primary font-semibold cursor-pointer"
      >
        {buttonLabel}
      </label>
      <input
        id={`upload-ingredient-image`}
        accept="image/*"
        onChange={handleOnChange}
        type="file"
        className="hidden"
      />
    </div>
  );
};