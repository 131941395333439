import { useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "components/atoms/button/button";
import { Divider } from "components/atoms/divider/divider";
import { Ellipses } from "components/icons/ellipses/ellipses";
import { Notification } from "components/molecules/loader/loader";
import { WeekSelector } from "components/molecules/week-selector/week-selector";
import { PageHeader } from "components/organisms/page-header/page-header";
import { PlanDinnerBottomAlert } from "components/organisms/plan-dinner-bottom-alert/plan-dinner-bottom-alert";
import { PlanDinnerDishesScroll } from "components/organisms/plan-dinner-dishes-scroll/plan-dinner-dishes-scroll";
import { PlanDinnerWeek } from "components/organisms/plan-dinner-week/plan-dinner-week";
import { PageTemplate } from "components/templates/page-template/page-template";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import {
  DELETE_PLANNED_MEAL,
  INSERT_PLANNED_MEALS,
  UPDATE_PLANNED_MEALS,
} from "graphql/mutation";
import { GET_MEALS_BETWEEN_RANGES } from "graphql/queries";
import React, { useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import {
  changeWeek,
  removeDish,
  resetDragDropCollectionToInitialValues,
  setDragDishesIntoDays,
  setDragDishesIntoDaysHasBeenShown,
  setDragDropCollection,
  setSelectedWeekRange,
  updateDragDropCollection,
} from "reducers/planDinnerSlice";
import { setLoading } from "reducers/uiSlice";
import { removeUrl, setHouseholdId } from "reducers/userSlice";
import { useAppDispatch, useAppSelector } from "store";
import {
  Type_DragDropCollection,
  Type_IdsToInsert,
  Type_Row,
} from "typescript/types";
import {
  DAYS,
  DEFAULT_ACTIVE_DAY_STATUS,
  PLACEHOLDER_MEAL_ID,
  TIME_INTERVAL_BETWEEN_PLANDINNER_CALLS,
  URL_REF,
} from "utils/constants";
import { getDateOfISOWeek } from "utils/getRangeOfWeeks";
import AddDishesToDays from "../../assets/images/add-dishes-into-days.png";
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export const PlanDinner: React.FC = () => {
  const [paramsExist, setParamsExist] = useState(true);
  const [onDragging, setOnDragging] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [collectionDishes, setCollectionDishes] = useState<any[]>([]);
  const [isPartOfHousehold, setIsPartOfHousehold] = useState<
    boolean | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [dayActiveStatus, setDayActiveStatus] = useState<boolean[]>(DEFAULT_ACTIVE_DAY_STATUS);
  const [timer, setTimer] = useState(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [loader, setLoader] = useState<{
    message: string;
    status: "success" | "error" | "";
  }>({
    message: "",
    status: "success",
  });

  const selectedWeekRange = useAppSelector(
    (state) => state.planDinner.selectedWeekRange
  );
  const showDragDishesIntoDays = useAppSelector(
    (state) => state.planDinner.showDragDishesIntoDays
  );
  const householdId = useAppSelector((state) => state.user.householdId);
  const loading = useAppSelector((state) => state.ui.loading);
  const DragDropCollection = useAppSelector(
    (state) => state.planDinner.DragDropCollection
  );
  const DragDishesIntoDaysHasBeenShown = useAppSelector(
    (state) => state.planDinner.DragDishesIntoDaysHasBeenShown
  );
  const shareObj = useAppSelector((state) => state.user.shareObj);

  const [insertPlannedMeals, { loading: insertMealLoading }] =
    useMutation(INSERT_PLANNED_MEALS);
  const [updatePlannedMeals] = useMutation(UPDATE_PLANNED_MEALS);
  const [deletePlannedMeals] = useMutation(DELETE_PLANNED_MEAL);

  const [getMealsBetweenDateRange, { loading: getMealsLoading }] = useLazyQuery(
    GET_MEALS_BETWEEN_RANGES,
    {
      fetchPolicy: "no-cache",
      onError: (error) => console.log(error),
      onCompleted: (data) => {
        if (data) {
          let mealsSeperatedInDays: any = {
            "1": [],
            "2": [],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
            "7": [],
          };
          let totalMeals: any[] = [];
          let _rows: Type_Row[] = [];

          data?.planned_meals?.forEach((meal: any) => {
            // dayjs returns day as (Sunday as 0, Saturday as 6)
            // the way I have programmed, (Monday is 0, Sunday as 6)
            // the two checks are conversions
            // TODO: Refactor
            let index = dayjs(meal?.date).get("day");
            if (index === 0) index = 6;
            else if (index > 0 && index <= 6) index = index - 1;

            // to set it according to hash keys of [mealsSeperatedInDays]
            index = index + 1;
            mealsSeperatedInDays[index.toString()]?.push(
              `${meal?.id}-${meal?.meal?.id}-${meal?.date}-${meal?.active}`
            );
            if (!totalMeals.some((t_meal) => meal.meal.id === t_meal.id))
              totalMeals.push({
                ...meal?.meal,
                id: `${meal?.id}-${meal?.meal?.id}-${meal?.date}-${meal?.active}`,
              });
          });

          let _dayActiveStatus: boolean[] = [];

          // get only the status of the dishes
          for (const day in mealsSeperatedInDays) {
            let status: string[] = [];
            mealsSeperatedInDays[day].forEach((mealId: string) => {
              const splitMealId = mealId.split("-");
              const _status = splitMealId[splitMealId.length - 1];
              status.push(_status);
            });

            const areAllValuesEqual = status.every(
              (_status: string) => _status === status[0]
            );

            let statusToSet;
            if (areAllValuesEqual) {
              statusToSet = status[0] === "false" ? false : true;
            } else {
              statusToSet = true;
            }
            _dayActiveStatus.push(statusToSet);
          }

          setDayActiveStatus(_dayActiveStatus);

          _rows.unshift({
            id: "0",
            title: "Collection",
            taskIds: [...DragDropCollection.rows[0].taskIds],
          });

          for (const key in mealsSeperatedInDays) {
            _rows[parseInt(key)] = {
              ..._rows[parseInt(key)],
              id: key,
              title: DAYS[parseInt(key) - 1],
              taskIds: mealsSeperatedInDays[key],
            };
          }

          // check if the meals that are to be dispatched are not already in the redux state
          // union of array of objects
          const filteredMeals = [
            ...DragDropCollection.dishes,
            ...totalMeals,
          ].filter(
            (
              (
                set // store the set and return the actual callback
              ) =>
              (o: any) =>
                set.has(o.id) ? false : set.add(o.id)
            )(new Set()) // use an IIFE to create a Set and store it set
          );

          // [filteredMeals] still contains duplicate data in the form of
          //  [
          //    a) id = 52, => type => number
          //    b) id = 53, => type => number
          //    c) id= 54, => type => number
          //    d) id = "256-52-date-false", => type => string
          //  ]
          // a) and d) are equal

          // seperate into number and string ids
          let numberIds: any[] = [];
          let stringIds: any[] = [];
          filteredMeals.forEach((dish) => {
            if (typeof dish.id === "string") stringIds.push(dish);
            else if (typeof dish.id === "number") numberIds.push(dish);
          });

          let filteredMealsWithoutDuplicates: any[] = [];
          // remove duplicate id of type number
          stringIds.forEach((s) => {
            numberIds.forEach((n, index) => {
              if (n.id == parseInt((s.id as string).split("-")[1])) {
                numberIds.splice(index, 1);
              }
            });
          });

          // combine string id and unique number id
          filteredMealsWithoutDuplicates = [...stringIds, ...numberIds];

          let newDragDropCollection: Type_DragDropCollection = {
            // for case 1 when meals are selected to planning week and there are no string ids, show number ids
            // after 1st item has been dropped into a day, string ids exist. Use [filteredMealsWithoutDuplicates]
            dishes:
              filteredMealsWithoutDuplicates.length > 0
                ? filteredMealsWithoutDuplicates
                : numberIds,
            rows: _rows,
          };

          dispatch(setDragDropCollection(newDragDropCollection));
          dispatch(setLoading(false));
        }
      },
    }
  );

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const paramHouseholdId = params.get("household_id");
      const localHouseholdId =
        localStorage.getItem("household_id") &&
        localStorage.getItem("household_id");
      if (localHouseholdId && paramHouseholdId) {
        if (parseInt(paramHouseholdId) === parseInt(localHouseholdId)) {
          setIsPartOfHousehold(true);
        } else {
          setIsPartOfHousehold(false);
        }
      }
    }

    if (
      DragDropCollection.rows[0].taskIds.length > 0 &&
      !DragDishesIntoDaysHasBeenShown
    ) {
      dispatch(setDragDishesIntoDays(true));
      dispatch(setDragDishesIntoDaysHasBeenShown(true));
      setTimeout(() => {
        dispatch(setDragDishesIntoDays(false));
      }, 3000);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (isPartOfHousehold === undefined) {
      console.log("is undefined");
    } else if (isPartOfHousehold) {
      const _householdId = params.get("household_id");
      _householdId && dispatch(setHouseholdId(parseInt(_householdId)));

      dispatch(
        setSelectedWeekRange({
          date: shareObj.date,
        })
      );
    } else if (isPartOfHousehold === false) {
      setLoader({
        status: "error",
        message: "You are not part of the household!",
      });
    }

    const _householdId =
      localStorage.getItem("household_id") &&
      localStorage.getItem("household_id");
    isPartOfHousehold !== undefined &&
      history.push(`/plan-dinner?date=${shareObj.date}`);
    _householdId && dispatch(setHouseholdId(parseInt(_householdId)));
    dispatch(removeUrl());
  }, [isPartOfHousehold]);

  // START -----------------> To remove lag when dishes are dragged and dropped

  const checkIdsAgainstDash = (id: string) => {
    if (!id.includes("-")) return true;
    else return false;
  };

  const mealsToInsertAfterInterval = () => {
    let idsToInsert: Type_IdsToInsert[] = [];
    DragDropCollection.rows.forEach((day, index) => {
      if (index === 0) return;
      let ids: string[] = [];
      day.taskIds.forEach((dishIds) => {
        if (checkIdsAgainstDash(dishIds)) ids.push(dishIds);
      });
      idsToInsert.push({ day: day.id, ids: ids });
    });

    return idsToInsert;
  };

  const insertDishes = async (idsToInsert: Type_IdsToInsert[]) => {
    const date = selectedWeekRange.from;

    for (const idsInDay of idsToInsert) {
      for (const id of idsInDay.ids) {
        await insertPlannedMeals({
          variables: {
            objects: {
              date: dayjs(date)
                .add(parseInt(idsInDay.day) - 1, "day")
                .format("YYYY-MM-DD"),
              meal_id: parseInt(id),
              household_id: householdId,
              active: true,
            },
          },
        });
      }
    }
  };

  const insertAndGetDishes = async (idsToInsert: Type_IdsToInsert[]) => {
    await insertDishes(idsToInsert);
    await getMealsBetweenDateRange({
      variables: {
        where: {
          date: {
            _gte: selectedWeekRange.from,
            _lte: selectedWeekRange.to,
          },
          household_id: {
            _eq: householdId,
          },
        },
      },
    });
  };

  useEffect(() => {
    // START -----------> to run function after specified time
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, TIME_INTERVAL_BETWEEN_PLANDINNER_CALLS);
    // END -------------> to run function after specified time
    if (!isDragging) {
      const idsToInsert = mealsToInsertAfterInterval();
      insertAndGetDishes(idsToInsert);
    }

    return () => {
      clearInterval(interval);
    };
  }, [setTimer, timer]);

  // End -----------------> To remove lag when dishes are dragged and dropped

  const removeDishFromCollection = async (id: number, isDayOfWeek: boolean) => {
    if (isDayOfWeek) {
      // id can sometimes be in the form of
      // ${plan id}-${id}-${some date}-${activeStatus}
      // e.g "256-197-2021-07-03-false"
      const _id = id.toString().split("-");

      // delete by plan id
      deletePlannedMeals({
        variables: {
          where: {
            id: {
              _eq: parseInt(_id[0]),
            },
          },
        },
      });
    }
    dispatch(removeDish({ id: id, isWeekDish: isDayOfWeek }));
  };

  const onDragEnd = async (result: DropResult) => {
    if (insertMealLoading || getMealsLoading) return;
    //draggableId is the id of the item that is being dragged
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = DragDropCollection.rows[parseInt(source.droppableId)];
    const finish = DragDropCollection.rows[parseInt(destination.droppableId)];

    const startIds = Array.from(start.taskIds);
    startIds.splice(source.index, 1);
    const newStartRow = {
      ...start,
      taskIds: startIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinishRow = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const startIndex = DragDropCollection.rows.findIndex(
      (row) => row.id === newStartRow.id
    );
    const finishIndex = DragDropCollection.rows.findIndex(
      (row) => row.id === newFinishRow.id
    );

    const _rows = Array.from(DragDropCollection.rows);
    _rows[startIndex] = newStartRow;
    _rows[finishIndex] = newFinishRow;

    let newDragDropColection: any = {
      ...DragDropCollection,
      rows: _rows,
    };

    // START -----------------------------> Adding, Deleting, Updating as meal changes days

    const week = dayjs(selectedWeekRange.date).isoWeek(); //get week number
    const firstDayOfWeek = getDateOfISOWeek(
      week,
      dayjs(selectedWeekRange.date).get("year")
    ); //get first day of the week

    // if drag started from Collections List
    if (source.droppableId === "0") {
      // date is equal to the id of the row in which it is moved to  - 1
      // -1 is because the ids are starting from 1 as can be seen in constants.js
      const date = dayjs(firstDayOfWeek)
        .add(parseInt(destination.droppableId) - 1, "days")
        .format("YYYY-MM-DD");

      let mealId = "";

      if (draggableId.split("-")[1]) {
        mealId = draggableId.split("-")[1];
      } else {
        mealId = draggableId;
      }

      // await insertPlannedMeals({
      //   variables: {
      //     objects: {
      //       date: date,
      //       meal_id: parseInt(mealId),
      //       household_id: householdId,
      //       active: true,
      //     },
      //   },
      // });
      // await getMealsBetweenDateRange({
      //   variables: {
      //     where: {
      //       date: {
      //         _gte: selectedWeekRange.from,
      //         _lte: selectedWeekRange.to,
      //       },
      //       household_id: {
      //         _eq: householdId,
      //       },
      //     },
      //   },
      // });
    }

    // shifting a meal between different week days
    if (
      source.droppableId !== "0" &&
      parseInt(source.droppableId) >= 1 &&
      parseInt(source.droppableId) <= 7
    ) {
      const date = dayjs(firstDayOfWeek)
        .add(parseInt(destination.droppableId) - 1, "days")
        .format("YYYY-MM-DD");
      updatePlannedMeals({
        variables: {
          where: {
            id: {
              _eq: parseInt(draggableId),
            },
            household_id: {
              _eq: householdId,
            },
          },
          _set: {
            date: date,
          },
        },
      });
    }

    // meal shifted from one of the week days into collection
    if (
      parseInt(source.droppableId) >= 1 &&
      parseInt(source.droppableId) <= 7 &&
      destination.droppableId === "0"
    ) {
      deletePlannedMeals({
        variables: {
          where: {
            id: {
              _eq: parseInt(draggableId),
            },
            household_id: {
              _eq: householdId,
            },
          },
        },
      });
    }

    // END -----------------------------> Adding, Deleting, Updating as meal changes days

    dispatch(updateDragDropCollection(newDragDropColection));
    setOnDragging(false);
  };

  useEffect(() => {
    let _dish: any[] = [];
    DragDropCollection.rows[0].taskIds.map((id) => {
      DragDropCollection.dishes.forEach((dish) => {
        if (dish.id == parseInt(id))
          _dish.push({ ...dish, id: dish.id.toString() });
      });
    });
    setCollectionDishes(_dish);
  }, [DragDropCollection]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const date = params.get("date");
    const week = dayjs(date ?? dayjs(new Date())).isoWeek();
    const range = getDateOfISOWeek(week, dayjs(date ?? new Date()).get("year"));
    const startDate = selectedWeekRange.date;
    const endDate = dayjs(startDate).add(6, "days");
    getMealsBetweenDateRange({
      variables: {
        where: {
          date: {
            _gte: dayjs(range).format("YYYY-MM-DD"),
            _lte: endDate.format("YYYY-MM-DD"),
          },
          household_id: {
            _eq: householdId,
          },
        },
      },
    });
  }, [selectedWeekRange.date, location.search]);

  useEffect(() => {
    if (location.search && paramsExist) {
      const params = new URLSearchParams(location.search);
      const date = params.get("date");
      const week = dayjs(date ?? dayjs(new Date())).isoWeek();
      const range = getDateOfISOWeek(
        week,
        dayjs(date ?? new Date()).get("year")
      );
      const startDate = dayjs(range);
      const endDate = dayjs(startDate).add(6, "days");

      getMealsBetweenDateRange({
        variables: {
          where: {
            date: {
              _gte: dayjs(range).format("YYYY-MM-DD"),
              _lte: endDate.format("YYYY-MM-DD"),
            },
            household_id: {
              _eq: householdId,
            },
          },
        },
      });
      dispatch(
        setSelectedWeekRange({
          to: endDate.format("YYYY-MM-DD"),
          from: startDate.format("YYYY-MM-DD"),
          date,
        })
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedWeekRange.weekNumber === -1) {
      const today = new Date();
      const week = dayjs(today).isoWeek();
      dispatch(setSelectedWeekRange({ weekNumber: week }));
    }
    if (selectedWeekRange.weekNumber !== -1 && !paramsExist) {
      const today = new Date();
      const week = selectedWeekRange.weekNumber;
      const range = getDateOfISOWeek(week, dayjs(today).get("year"));
      const from = dayjs(range);
      const to = dayjs(from).add(6, "days");
      dispatch(
        setSelectedWeekRange({
          to: to.format("YYYY-MM-DD"),
          from: from.format("YYYY-MM-DD"),
          date: from.format("YYYY-MM-DD"),
        })
      );

      history.push(
        `/plan-dinner?date=${dayjs(selectedWeekRange.from).format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }, [selectedWeekRange.weekNumber]);

  useEffect(() => {
    if (selectedWeekRange.from && selectedWeekRange.to && !paramsExist)
      history.replace(`/plan-dinner?date=${selectedWeekRange.date}`);
  }, [selectedWeekRange.from, selectedWeekRange.to]);

  const weekChange = (type: "back" | "forward") => {
    const idsToInsert = mealsToInsertAfterInterval();
    insertDishes(idsToInsert);
    setParamsExist(false);
    setDayActiveStatus(DEFAULT_ACTIVE_DAY_STATUS);
    // wait for the active status change to take effect in UI
    setTimeout(() => {
      dispatch(
        setSelectedWeekRange({
          weekNumber:
            type === "back"
              ? selectedWeekRange.weekNumber - 1
              : selectedWeekRange.weekNumber + 1,
        })
      );
      dispatch(changeWeek());
    }, 10)
  };

  const goBackAWeek = () => {
    weekChange("back");
  };
  const goAWeekForward = () => {
    weekChange("forward");
  };

  const closeDialog = () => setShowDialog(false);

  useEffect(() => {
    if (loader?.message) {
      setIsLoading(true);

      setTimeout(() => {
        setLoader({ status: "", message: "" });
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [loader]);

  const clearList = async () => {
    closeDialog();
    let ids: any[] = [];
    DragDropCollection.rows.forEach((row) => {
      row.taskIds.forEach((id) => {
        if (id) ids.push(parseInt(id));
      });
    });

    if (ids.length <= 0) {
      setLoader({ status: "error", message: "List Empty!" });
      return;
    }
    try {
      dispatch(setLoading(true));
      await deletePlannedMeals({
        variables: {
          where: {
            id: {
              _in: ids,
            },
            household_id: {
              _eq: householdId,
            },
          },
        },
      });
      dispatch(setLoading(false));

      setLoader({ status: "success", message: "List Cleared!" });

      dispatch(resetDragDropCollectionToInitialValues());
    } catch (error) {
      console.error(error);
      setLoader({ status: "error", message: "An error occured!" });
    } finally {
      // dispatch(setLoader({ status: "", message: "" }));
    }
  };

  const handleToggle = async (id: number) => {
    let _dayActiveStatus = Array.from(dayActiveStatus);
    const _status = _dayActiveStatus[id];
    _dayActiveStatus.splice(id, 1, !_status);
    setDayActiveStatus(_dayActiveStatus);
    const idsToUpdate = DragDropCollection.rows[id + 1].taskIds.map((ids) =>
      parseInt(ids)
    );
    const params = new URLSearchParams(location.search);
    const date = params.get("date");
    const week = dayjs(date ?? dayjs(new Date())).isoWeek();
    const range = getDateOfISOWeek(week, dayjs(date ?? new Date()).get("year"));
    const startDate = dayjs(range);
    const endDate = dayjs(startDate).add(6, "days");
    const toggleDate = startDate.add(id, "day").format("YYYY-MM-DD");
    const [mealIds] = DragDropCollection.rows[id + 1].taskIds.map(
      (ids) => ids.split("-")[1]
    );
    /*
      if idsToUpdate is empty insert placeholder meal in there and update the planned meals 
      on toggle on for place holder meal delete the placeholder meal entry 
    */
    if (mealIds && parseInt(mealIds) === PLACEHOLDER_MEAL_ID) {
      deletePlannedMeals({
        variables: {
          where: {
            id: {
              _in: idsToUpdate,
            },
            household_id: {
              _eq: householdId,
            },
          },
        },
      });
      getMealsBetweenDateRange({
        variables: {
          where: {
            date: {
              _gte: dayjs(range).format("YYYY-MM-DD"),
              _lte: endDate.format("YYYY-MM-DD"),
            },
            household_id: {
              _eq: householdId,
            },
          },
        },
      });
      return;
    }
    if (idsToUpdate.length === 0) {
      await insertPlannedMeals({
        variables: {
          objects: {
            date: toggleDate,
            meal_id: PLACEHOLDER_MEAL_ID,
            household_id: householdId,
            active: false,
          },
        },
      });
    } else {
      updatePlannedMeals({
        variables: {
          where: {
            id: {
              _in: idsToUpdate,
            },
            household_id: {
              _eq: householdId,
            },
          },
          _set: {
            active: !_status,
          },
        },
      });
    }
  };

  const itemIsBeingDragged: (_isDragging: boolean) => void = (_isDragging) =>
    setIsDragging(_isDragging);

  const handleShare = async () => {
    try {
      if (navigator.share && householdId !== -1) {
        await navigator.share({
          title: "Menully",
          text: "Menully | Planned Meals",
          url: `plan-dinner?date=${selectedWeekRange.date}&ref=${
            URL_REF.PlanDinner
          }&household_id=${householdId && householdId}`,
          // url: `plan-dinner?date=${selectedWeekRange.date}&ref=shared&household_id=74`,
        });
      }
      closeDialog();
    } catch (error) {
      console.log(error);
      setLoader({ status: "error", message: "Unable to share!" });
    }
  };

  return (
    <div>
      {isLoading && (
        <Notification status={loader.status} message={loader.message} />
      )}

      <div
        onClick={() => dispatch(setDragDishesIntoDays(false))}
        className={`${showDragDishesIntoDays ? "cursor-pointer" : ""}`}
      >
        <PageTemplate>
          <PageHeader pageHeading="Plan Dinner">
            <div className="flex items-center space-x-[25px]">
              {(insertMealLoading || getMealsLoading) && !loading && (
                <div>
                  <Loader
                    type="TailSpin"
                    height={20}
                    width={20}
                    color="rgb(63,16,77)"
                  />
                </div>
              )}
              <div onClick={() => setShowDialog((prev) => !prev)}>
                <Ellipses />
              </div>
            </div>
          </PageHeader>

          {loading ? (
            <div className="flex justify-center fixed top-[50%] left-[50%] ">
              <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
            </div>
          ) : (
            <>
              <div className="mt-[20px] mb-[23px]">
                <WeekSelector
                  {...{ selectedWeekRange, goBackAWeek, goAWeekForward }}
                  disableButtons={insertMealLoading || getMealsLoading}
                />
              </div>

              <DragDropContext onDragEnd={onDragEnd}>
                {DragDropCollection.rows[0].taskIds.length > 0 && (
                  <PlanDinnerDishesScroll
                    {...{ removeDishFromCollection }}
                    dishes={collectionDishes}
                    isDayOfWeek={false}
                    disableDrag={insertMealLoading || getMealsLoading}
                    itemIsBeingDragged={itemIsBeingDragged}
                  />
                )}
                <div
                  className={`${!showDragDishesIntoDays ? "" : "opacity-20"}`}
                >
                  <Divider className="mt-[21px]" />
                  <PlanDinnerWeek
                    {...{
                      removeDishFromCollection,
                      onDragging,
                      dayActiveStatus,
                      handleToggle,
                      itemIsBeingDragged,
                    }}
                    disableDrag={insertMealLoading || getMealsLoading}
                  />
                  <Divider className="" />
                </div>
              </DragDropContext>
            </>
          )}

          {showDragDishesIntoDays && (
            <div>
              <div className="h-screen w-screen fixed inset-0 opacity-25 bg-gray-100"></div>

              <img
                onClick={() => dispatch(setDragDishesIntoDays(false))}
                src={AddDishesToDays}
                alt=""
                className="fixed bottom-[45%] left-[50%] ml-[-97px] cursor-pointer opacity-100"
              />
            </div>
          )}

          {!showDragDishesIntoDays && !showDialog && (
            <div
              className="bottom-[88px] h-[46px] left-[50%] "
              style={{ marginLeft: "-83.5px" }}
            >
              <Button
                label="Add Dishes"
                onClick={() => history.push(`/add-dishes`)}
                renderPlusIcon
                className="bottom-[88px] h-[46px] left-[50%] ml-[-83.5px]"
              />
            </div>
          )}
          {showDialog && (
            <PlanDinnerBottomAlert
              {...{ closeDialog, clearList, handleShare }}
            />
          )}
        </PageTemplate>
      </div>
    </div>
  );
};
