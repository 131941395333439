import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { resetState } from "reducers/mealsSlice";
import { setNavigation } from "reducers/uiSlice";
import { useAppDispatch, useAppSelector } from "store";
import { Enum_Navigation, Enum_Paths } from "typescript/enum";
import AccountRed from "../../../assets/icons/nav-account-red.svg";
import AccountWhite from "../../../assets/icons/nav-account-white.svg";
import CalendarRed from "../../../assets/icons/nav-calendar-red.svg";
import CalendarWhite from "../../../assets/icons/nav-calendar-white.svg";
import GroceriesRed from "../../../assets/icons/nav-groceries-red.svg";
import GroceriesWhite from "../../../assets/icons/nav-groceries-white.svg";
import UtensilsRed from "../../../assets/icons/nav-utensils-red.svg";
import UtensilsWhite from "../../../assets/icons/nav-utensils-white.svg";
import { NavIcon } from "../../icons/nav-icon/nav-icon";
dayjs.extend(weekOfYear);

export const Navigation: React.FC = () => {
  const navigation = useAppSelector((state) => state.ui.navigation);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const _location = location.pathname.split("/")[1];
    checkLocation(_location);
  }, [location]);

  const checkLocation = (_location: string) => {
    switch (_location) {
      case Enum_Paths.MealIdeas:
        dispatch(setNavigation(Enum_Navigation.MealIdeas));
        break;
      case Enum_Paths.PlanDinner:
      case Enum_Paths.AddDishes:
        dispatch(setNavigation(Enum_Navigation.PlanDinner));
        dispatch(resetState());
        break;
      case Enum_Paths.Profile:
        dispatch(setNavigation(Enum_Navigation.Profile));
        dispatch(resetState());
        break;
      case Enum_Paths.ShoppingList:
        dispatch(setNavigation(Enum_Navigation.ShoppingList));
        dispatch(resetState());
        break;
    }
  };

  return (
    <div className="flex bg-navigation fixed bottom-0 left-0 right-0 justify-between px-[30px] pt-[11px] pb-[6px] text-white text-[9px] leading-[18px] font-semibold">
      <NavIcon
        Icon={navigation === Enum_Navigation.MealIdeas ? UtensilsRed : UtensilsWhite}
        label="Meal Ideas"
        selected={navigation === Enum_Navigation.MealIdeas ? true : false}
        to="/meal-ideas"
      />
      <NavIcon
        Icon={navigation === Enum_Navigation.PlanDinner ? CalendarRed : CalendarWhite}
        label="Plan Dinner"
        selected={navigation === Enum_Navigation.PlanDinner ? true : false}
        to={`/plan-dinner?date=${dayjs(new Date()).format("YYYY-MM-DD")}`}
      />
      <NavIcon
        Icon={navigation === Enum_Navigation.ShoppingList ? GroceriesRed : GroceriesWhite}
        label="Shopping List"
        selected={navigation === Enum_Navigation.ShoppingList ? true : false}
        to="/shopping-list"
      />
      <NavIcon
        Icon={navigation === Enum_Navigation.Profile ? AccountRed : AccountWhite}
        label="Profile"
        selected={navigation === Enum_Navigation.Profile ? true : false}
        to="/profile"
      />
    </div>
  );
};
