import React, { useContext } from "react";
import { MealIdeasContext } from "../../../pages/meal-ideas/meal-context";
import { CloseButtonFilled } from "../../icons/close-button-filled/close-button-filled";

type SingleTagProps = {
  tagName: string;
  handleClickRemoveTag: (tagName: string) => void;
};

export const SingleTag: React.FC<SingleTagProps> = ({ tagName, handleClickRemoveTag }) => {
  return (
    <div
      className="flex border-primary border-[1px] rounded-full pl-[11px] pr-[6px] py-[6px] mr-[10px]"
      onClick={() => handleClickRemoveTag(tagName)}
    >
      <p className="text-primary text-[10px] leading-[16px] font-semibold pr-[4px]">{tagName}</p>
      <CloseButtonFilled />
    </div>
  );
};
