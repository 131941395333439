import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BackArrow } from "components/icons/back-arrow-primary/back-arrow-primary";
import { Button } from "../../components/atoms/button/button";
import { UserPreferencesHeading } from "../../components/atoms/user-preferences-heading/user-preferences-heading";
import { SelectListItems } from "../../components/organisms/select-list-items.tsx/select-list-items";
import { ServingsPerMealItem } from "../../components/organisms/servings-per-meal/servings-per-meal-item";
import { StyledBoxedShadow } from "../../components/templates/box-shadow-bottom/box-shadow-bottom";
import { PageContainer } from "../../components/templates/user-preference-template/user-preference-template";
import { INSERT_SERVING_SIZE } from "../../graphql/mutation";
import { Type_Servings } from "../../typescript/types";
import { SERVINGS } from "../../utils/constants";
import { setLoader } from "reducers/uiSlice";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { Notification } from "../../components/molecules/loader/loader";
import { UserPreferencesHeadingPrimary } from "components/atoms/user-preferences-heading/user-preferences-heading-primary";
import { PageTemplate } from "components/templates/page-template/page-template";
import { GET_EMPLOYER } from "graphql/queries";
import Loader from "react-loader-spinner";

export const MyServingsPerMeal: React.FC = () => {
  const history = useHistory();
  let userId: any;
  userId = localStorage.getItem("user_Id");
  const loader = useAppSelector((state) => state.ui.loader);
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number>(-1);
  const [insertServingSize, { error }] = useMutation(INSERT_SERVING_SIZE);
  const [getEmployerServings] = useLazyQuery(GET_EMPLOYER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setSelectedItem(data?.employers[0]?.default_servings);
      setIsDataLoading(false);
    },
  });
  const handleClickItem = (item: Type_Servings) => {
    if (selectedItem !== item.id) setSelectedItem(item.id);
  };

  useEffect(() => {
    getEmployerServings({
      variables: {
        where: {
          id: { _eq: parseInt(userId) },
        },
      },
    });
  }, [userId]);

  useEffect(() => {
    if (loader?.status) {
      setLoading(true);
      setTimeout(() => {
        dispatch(setLoader({ status: "", message: "" }));
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [loader]);

  const handleContinueClick = () => {
    //TODO: Refactor to add user id dynamically
    if (selectedItem === -1) {
      dispatch(
        setLoader({ status: "error", message: "Please select a serving size!" })
      );
      return;
    }
    setTimeout(() => {
      if (selectedItem) {
        userId &&
          insertServingSize({
            variables: {
              _set: {
                default_servings: selectedItem,
              },
              where: {
                id: {
                  _eq: parseInt(userId),
                },
              },
            },
          }).then(() => history.push(`/preferences`));
      }
    }, 500);
  };

  useEffect(() => {
    error &&
      dispatch(
        setLoader({ status: "error", message: "Something went wrong!" })
      );
  }, [error]);

  return (
    <>
      <PageTemplate>
        <BackArrow />
        {isLoading && (
          <Notification status={loader.status} message={loader.message} />
        )}
        <UserPreferencesHeadingPrimary heading="Select your default servings per meal" />
        {isDataLoading ? (
          <div className="flex justify-center fixed top-[50%] left-[50%] ">
            <Loader type="TailSpin" color="#2F0842" height={20} width={20} />
          </div>
        ) : (
          <>
            <ServingsPerMealItem
              list={SERVINGS}
              handleClickItem={handleClickItem}
              selectedItems={selectedItem}
            />
            <Button
              className="bottom-[88px] h-[46px] left-[50%] ml-[-60.5px]"
              label="continue"
              onClick={handleContinueClick}
              hasPurpleGradient
            />
          </>
        )}
      </PageTemplate>
    </>
  );
};
