import React from "react";
import { CheckActiveOutline } from "../../icons/check-active-outline/check-active-outline";
import { CheckInactive } from "../../icons/check-inactive/check-inactive";

export const SelectListItem: React.FC<{ label: string; selected?: boolean; subHeading?: string; isServing?: boolean }> =
  ({ label, selected = false, subHeading, isServing = false }) => {
    return (
      <div
        className={`flex border border-primary justify-between items-center pl-[25px]  pr-[17px] rounded-[32px]  mb-[13px] cursor-pointer ${
          selected ? "text-primary bg-white" : "bg-secondary text-white"
        } ${isServing ? "pt-[14px] pb-[12px] h-[64px]" : "min-h-[46px] "}`}
      >
        <div className="flex flex-col">
          <p className={`text-sm font-semibold capitalize`}>{label}</p>
          {subHeading && <p className="text-xs mt-[5px]">{subHeading}</p>}
        </div>
        {selected ? <CheckActiveOutline /> : <CheckInactive />}
      </div>
    );
  };
