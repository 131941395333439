import React from "react";
import { CheckActiveFilled } from "../../icons/check-active-filled/check-active-filled";
import { CheckInactive } from "../../icons/check-inactive/check-inactive";
import CheckInactiveFilter from "assets/icons/check-inactive-filter.svg";
type SingleFilterProps = {
  tagName: string;
  isChecked?: boolean;
};

export const SingleTag: React.FC<SingleFilterProps> = ({ tagName, isChecked = false }) => {
  return (
    <div className="flex justify-between items-center mt-[28px]">
      <p className="text-primary text-[14px] leading-[18px] font-medium cursor-pointer">{tagName}</p>
      {isChecked ? (
        <CheckActiveFilled />
      ) : (
        <img src={CheckInactiveFilter} alt="" className="w-[25px] h-[25px] cursor-pointer" />
      )}
    </div>
  );
};
