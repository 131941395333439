import React from "react";
import styles from "./dish-image.module.css";
import { PlaceholderImage } from "../placeholder-image/placeholder-image";
import { combineClasses } from "utils/combineClasses";

type DishImageProps = {
  image: string;
  customDish?: boolean;
  halfWidth?: boolean;
};

export const DishImage: React.FC<DishImageProps> = ({ image, customDish, halfWidth = false }) => {
  return (
    <div>
      {image ? (
        <>
          <img
            src={image}
            alt=""
            className={combineClasses(
              `${
                !halfWidth ? "h-[230px]" : "h-[185px]"
              } w-full rounded-md  object-cover cursor-pointer`,
            )}
          />
          <div className={styles.cover}></div>
        </>
      ) : (
        <PlaceholderImage customDish={customDish} halfWidth={halfWidth} />
      )}
    </div>
  );
};
