import React from "react";
import BulletSVG from "assets/icons/bullet.svg";

type InstructionRowProps = {
  instruction: string;
};

export const InstructionRow: React.FC<InstructionRowProps> = ({ instruction }) => {
  return (
    <>
      {instruction && (
        <div className="flex">
          <img src={BulletSVG} alt="" className="self-start mt-[6px] mr-[6px]" />
          <li className="w-full text-fontGray text-[12px] leading-[17px] capitalize mb-[7px]">
            {instruction}
          </li>
        </div>
      )}
    </>
  );
};
