import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Meal, Type_AddDish_Dish, Type_DragDropCollection, Type_Row, Type_WeekRange } from "typescript/types";
import { ROWS } from "utils/constants";

type Type_InitialState = {
  showDragDishesIntoDays: boolean;
  DragDishesIntoDaysHasBeenShown: boolean;
  DragDropCollection: Type_DragDropCollection;
  selectedWeekRange: Type_WeekRange & { weekNumber: number; date: string };
  singleDish: Type_AddDish_Dish
};

const initialState: Type_InitialState = {
  showDragDishesIntoDays: false,
  DragDishesIntoDaysHasBeenShown: false,
  DragDropCollection: {
    dishes: [],
    rows: ROWS,
  },
  selectedWeekRange: {
    from: "",
    to: "",
    weekNumber: -1,
    date: "",
  },
  singleDish: {
    custom_meal: false,
    name: "",
    image_url: "",
    id: 0
  }
};

const planDinnerSlice = createSlice({
  name: "plan-dinner",
  initialState,
  reducers: {
    setDragDishesIntoDays(state, action: PayloadAction<boolean>) {
      state.showDragDishesIntoDays = action.payload;
    },
    setDragDishesIntoDaysHasBeenShown (state, action: PayloadAction<boolean>) {
      state.DragDishesIntoDaysHasBeenShown = action.payload
    },
    pickDish(state, { payload }) {
      const newRows = state.DragDropCollection.rows;
      newRows.splice(0, 0);
      newRows[0].taskIds = [...newRows[0].taskIds, payload.id.toString()];

      state.DragDropCollection = {
        rows: newRows,
        dishes: [...state.DragDropCollection.dishes, payload],
      };
    },
    dropDish(state, { payload }) {
      state.DragDropCollection.rows[0].taskIds.forEach((id, index) => {
        if (id == payload) {
          state.DragDropCollection.rows[0].taskIds.splice(index, 1);
        }
      });
      state.DragDropCollection = {
        ...state.DragDropCollection,
        dishes: state.DragDropCollection.dishes.filter((dish) => {
          const dishId: number = typeof dish.id === "string" ? parseInt((dish.id as string).split("-")[1]) : dish.id;
          if (dishId != payload) {
            return dish;
          }
        }),
      };
    },
    removeDish(state, { payload }) {
      // if dish is deleted from week day,
      if (payload.isWeekDish) {
        // find dish in rows and remove it if it exists
        state.DragDropCollection.rows.forEach((row) => {
          row.taskIds.forEach((mealId, index) => {
            if (mealId == payload.id) {
              console.log({ mealId, payloadId: payload.id });
              row.taskIds.splice(index, 1);
            }
          });
        });
        // push dish into dish collection
        console.log(payload.id, "payload id");
        if (typeof payload.id === "number") {
          state.DragDropCollection.rows[0].taskIds.push(payload.id.toString());
        } else {
          state.DragDropCollection.rows[0].taskIds.push(payload.id.toString().split("-")[1]);
        }

        // get index of dish from dishes
        const index = state.DragDropCollection.dishes.findIndex((dish) => dish.id == payload.id);
        let dish = state.DragDropCollection.dishes[index];
        dish = { ...dish, id: typeof payload.id === "string" ? payload.id.toString().split("-")[1] : payload.id };
        // update the id and change it from form "${planId}-${mealId}-${date}-${status}"
        // to "${mealId}" so that it can be inserted again when the insert query is automatically run
        state.DragDropCollection.dishes.splice(index, 1, dish);
      } else {
        //else remove from collection and dishes
        state.DragDropCollection.rows[0].taskIds.forEach((id, index) => {
          if (id == payload.id) {
            state.DragDropCollection.rows[0].taskIds.splice(index, 1);
          }
        });

        state.DragDropCollection = {
          ...state.DragDropCollection,
          dishes: state.DragDropCollection.dishes.filter((dish) => dish.id !== payload.id),
        };
      }
    },
    updateDragDropCollection(state, { payload }) {
      state.DragDropCollection = payload;
    },
    setSelectedWeekRange(state, { payload }) {
      state.selectedWeekRange = {
        ...state.selectedWeekRange,
        ...payload,
      };
    },
    setDragDropCollection(state, { payload }) {
      state.DragDropCollection = payload;
    },
    resetDragDropCollectionToInitialValues(state) {
      state.DragDropCollection = initialState.DragDropCollection;
    },
    changeWeek(state) {
      let rows: Type_Row[] = [];
      let dishes: Type_AddDish_Dish[] = [];
      state.DragDropCollection.rows.forEach((row: Type_Row, index: number) => {
        if (index === 0) rows.push(row);
        else rows.push({ ...row, taskIds: [] });
      });

      state.DragDropCollection.dishes.filter((dish: Type_AddDish_Dish) => {
        let dishId: any;
        
        if(typeof dish.id === "number") {
          dishId = dish.id
        } else {
          const hasDashes = (dish.id as string).includes("-")
          dishId = hasDashes ? parseInt((dish.id as string).split("-")[1]) : parseInt(dish.id as string)
        }

        state.DragDropCollection.rows[0].taskIds.forEach((meal) => {
          let mealId;
          console.log({ meal, dishId }, "slice");
          // meal id is either
          // a) "65"
          // b) ${planMealId}-${mealId}-${date}-${activeStatus} e.g "250-65-2021-05-22-false"
          // The if condition checks if there are any dashes. If so, then
          // the id is of type b). If there are no dashes, then the id
          // is of type a)

          if (typeof meal === "number") mealId = meal;
          else {
            const checkForDash = parseInt((meal as string)?.split("-")[1]);

            if (checkForDash) {
              mealId = checkForDash;
            } else {
              mealId = parseInt(meal);
            }
          }

          if (dishId == mealId) dishes.push(dish);
        });
      });
      state.DragDropCollection = {
        dishes,
        rows,
      };
    },
    setSingleDish(state, { payload }) {
      state.singleDish = payload
    }
  },
});

export const {
  setDragDishesIntoDays,
  pickDish,
  removeDish,
  updateDragDropCollection,
  setSelectedWeekRange,
  setDragDropCollection,
  resetDragDropCollectionToInitialValues,
  changeWeek,
  dropDish,
  setSingleDish,
  setDragDishesIntoDaysHasBeenShown
} = planDinnerSlice.actions;
export default planDinnerSlice.reducer;
