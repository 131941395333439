import { CloseIconWhiteFilled } from "components/icons/close-icon-white-filled/close-icon-white-filled";
import React from "react";
import { Type_AddDish_Dish } from "typescript/types";
import { Draggable, SnapDragActions } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";

type PlanDinnerDishProps = {
  dish: Type_AddDish_Dish;
  removeDishFromCollection: (id: number, isDayOfWeek: boolean) => void;
  index: number;
  isDayOfWeek?: boolean;
  disableDrag: boolean;
  itemIsBeingDragged: (_isDragging: boolean) => void;
};

export const PlanDinnerDish: React.FC<PlanDinnerDishProps> = ({
  dish,
  removeDishFromCollection,
  index,
  isDayOfWeek,
  disableDrag,
  itemIsBeingDragged,
}) => {
  const history = useHistory();
  const onClickMeal: (mealId: number, isCustom: boolean) => void = (
    mealId,
    isCustom = false
  ) => {
    const realMealId = mealId.toString().split('-').length > 1 ? mealId.toString().split('-')[1] : mealId.toString()
    if (isCustom) {
      history.push(`/customDishDetail?mealId=${realMealId}`);
    } else {
      history.push(`/dishDetail?mealId=${realMealId}`);
    }
  };
  return (
    <Draggable
      draggableId={dish?.id.toString()}
      index={index}
      isDragDisabled={disableDrag}
    >
      {(provided, snapshot) => {
        itemIsBeingDragged(snapshot.isDragging);
        return (
          <div
            className="w-[92px] h-[72px]"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {dish?.image_url && (
              <>
                <div
                  className={`w-[92px] h-[72px] cursor-pointer  relative overflow-hidden rounded-[6px] ${
                    disableDrag ? "opacity-70" : ""
                  } ${
                    snapshot.isDragging ? "border-[2px] border-primary" : ""
                  }`}
                >
                  {dish?.image_url && (
                    <img
                      src={dish?.image_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                      className={` rounded-[6px] w-[92px] h-[72px]`}
                      onClick={() => onClickMeal(dish.id, dish?.custom_meal)}
                    />
                  )}
                  {!snapshot.isDragging && (
                    <div
                      className="absolute top-[4px] right-[4px]"
                      onClick={() => {
                        !disableDrag &&
                          removeDishFromCollection(
                            dish?.id,
                            isDayOfWeek ? isDayOfWeek : false
                          );
                      }}
                    >
                      <CloseIconWhiteFilled />
                    </div>
                  )}
                </div>
                {!snapshot.isDragging && (
                  <p
                    className="text-fontGray text-[10px] leading-[13px] font-medium mt-[6px] truncate"
                    onClick={() => onClickMeal(dish.id, dish?.custom_meal)}
                  >
                    {dish?.name}
                  </p>
                )}
              </>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};
