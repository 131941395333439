import { Button } from "components/atoms/button/button";
import { Divider } from "components/atoms/divider/divider";
import { UserPreferencesHeading } from "components/atoms/update-account/user-preferences-heading";
import { BackArrow } from "components/icons/back-arrow-primary/back-arrow-primary";
import { HelperList } from "components/molecules/helper/helper-list";
import { PageTemplate } from "components/templates/page-template/page-template";
import { ProfilePageContainer } from "components/templates/page-template/page-template-for-profile";
import { useHistory } from "react-router-dom";
export const HelperListing: React.FC = () => {
  const history = useHistory();
  return (
    <PageTemplate>
      <div className="flex">
        <BackArrow />
        <UserPreferencesHeading heading="Helpers" />
      </div>
      <div>
      {/* <Divider className="mt-[21px]" /> */}
        <HelperList />
        {/* <Divider className="" /> */}
      </div>
      <Button
          label={`Add Helper`}
          onClick={() => history.push('/addHelper')}
          className="bottom-[88px] h-[46px] left-[50%] ml-[-76.5px]"
        />
    </PageTemplate>
  );
};

